compliance-config-redflag-section {
  .compliance-config-redflag-section__column {
    display: flex;
    flex-direction: column;
    width: 600px;
  }

  .compliance-config-redflag-section__padding {
    padding: 0 0 0 20px;
  }
}
