credit-scoring-modal-customer-details {
  width: 100%;
}
credit-scoring-modal-customer-details avatar {
  display: flex;
  justify-content: center;
  padding-bottom: var(--small-gap);
}
credit-scoring-modal-customer-details avatar-icon {
  display: block;
  position: relative;
}
credit-scoring-modal-customer-details avatar-icon svg {
  height: calc(3 * var(--box-padding));
  width: calc(3 * var(--box-padding));
}
credit-scoring-modal-customer-details avatar-icon svg circle {
  fill: var(--primary-color);
}
credit-scoring-modal-customer-details avatar-initials {
  color: var(--white);
  font-size: 2rem;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
}
credit-scoring-modal-customer-details .text {
  font-size: 2rem;
}
