paid-amortizations {

.table {

    .initial-row > * {
      opacity: 0.7;
    }

    .penalties-column {
      padding-right: 10px;
    }

    .status-col {
      text-align: center;
      width: 80px;
    }

    td.transparent, tr:hover td.transparent {
      background: transparent;
    }
  }
}