dynamic-report-mapping-edit {

  .actions-header {
    margin-bottom: 4rem;
    margin-top: 4rem;
  }

  .sheet {
    width: 300px;
  }


  .cell-value {
    width: 200px;
  }

  .actions {
    width: 310px;
  }

  tr.ng-invalid {
    border: 2px solid var(--danger-color);
  }

  .formula textarea {
    box-sizing: border-box;
    width: 100%;
  }

}
