.condition {
  background: #ededef;
  padding: 5px;
  margin: 5px;
  border: 1px solid lightgray;
  border-radius: 10px;
}
.condition .close {
  padding-left: 8px;
  align-self: center;
  font-size: 16px;
}
.condition .close:after {
  content: 'X';
}
