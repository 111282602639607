number-condition {
  display: block;
  white-space: nowrap;
}
number-condition .condition {
  display: flex;
  align-items: center;
  width: fit-content;
}
number-condition .condition glue-container {
  margin-left: 10px;
}
number-condition .condition glue-container input {
  max-width: 100px;
}
number-condition .condition .inputs {
  display: flex;
  align-items: flex-end;
  margin: 9px 0;
  height: 30px;
}
