new-access-rule table td:first-child {
  width: 30%;
}
new-access-rule table td:last-child {
  width: 70%;
}
new-access-rule table .selectize-control {
  width: 300px;
}
new-access-rule table .selectize-control .selectize-input {
  display: block;
}
new-access-rule .actions button {
  min-width: 100px;
  margin: 5px;
}
