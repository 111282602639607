transactions-details {
  .buttons {
    float: right;
    margin: 0 25px 20px;
  }

  form {
    margin: 0 20px 20px;
  }

  input[type=checkbox] {
    margin-right: 6px;
  }
}