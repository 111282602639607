customer-profile-related-non-customers .table input,
customer-profile-related-non-customers .table select,
customer-profile-related-non-customers .table date {
  min-width: var(--input-width-s);
  width: auto;
}
customer-profile-related-non-customers .table .label {
  min-width: 8rem;
}
customer-profile-related-non-customers .table {
  display: block;
  overflow-x: auto;
}
