ledger-units {
  .ledger-units-table {
    table-layout: fixed;

    td, th {
      &:first-child {
        width: 30px;
      }

      &:nth-child(2) {
        width: 55%;
      }

      &:last-child {
        width: 100px;
      }
    }

    glue-container {
      .add-on {
        box-sizing: border-box;
        height: 30px;
      }
    }

    .account-options {
      width: 98%;
    }

    .selectize-control.single {
      max-width: none;
    }

    .error {
      padding-top: 5px;
      color: var(--danger-color);
      float: right;
    }

    .error-cell {
      color: var(--danger-color);
    }
  }
}