customer-credit-line-edit table select,
customer-credit-line-edit table input {
  margin-bottom: 0;
}
customer-credit-line-edit table td.values {
  display: flex;
}
customer-credit-line-edit .buttons {
  float: right;
  margin-right: 40px;
}
