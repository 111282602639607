loan-product-letter {
  table {
    th:last-child {
      text-align: right;
      padding-right: 35px;
    }
  }

  .letter-list__ {
    &add-new-column {
      text-align: right !important;
    }
  }

  button {
    margin-right: 5px;
  }
}
