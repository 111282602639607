loan-automatic-transfer {
  table {
    margin-bottom: 0;

    select, input, textarea {
      margin-bottom: 0;
    }

    td {

      &.label {
        width: 280px;
      }

      &.values {
        display: flex;
        align-items: center;
        max-width: var(--input-max-width);
      }
    }

    .selectize-control.single {
      max-width: none;
      min-width: 300px;
    }
  }
}
