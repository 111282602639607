role-write-access-list table thead tr,
role-write-access-list table thead td {
  font-weight: var(--font-weight-semibold);
}
role-write-access-list table .group {
  font-weight: var(--font-weight-semibold);
  width: 95%;
}
role-write-access-list table .action {
  width: 5%;
}
