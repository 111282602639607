gl-transactions-mapping {
  .gl-mappings__table {

    .no-column-header {
      width: 15px;
    }

    input[type='checkbox'] {
      width: 16px;
      height: 16px;
    }

    .centered-cell {
      text-align: center;
      vertical-align: middle;
    }

    .col-name {
      width: 30%
    }

    .col-delegate {
      width: 70px;
    }

    .col-account {
      max-width: 350px;
    }

    .col-delegate-tag {
      width: 350px
    }

    .disabled-select {
      width: 350px;
      margin: 0;
    }

    .col-button {
      max-width: 10%;
    }
  }
}