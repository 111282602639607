customer-profile-related-non-customers {

  .table {
    input,
    select,
    date {
      min-width: var(--input-width-s);
      width: auto;
    }
  }

  .table .label {
    min-width: 8rem;
  }

  .table {
    display: block;
    overflow-x: auto;
  }
}