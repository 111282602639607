customer-loan-co-makers table th {
  border-top: none!important;
}
customer-loan-co-makers table td.label {
  width: 280px;
}
customer-loan-co-makers table td.values {
  display: flex;
  align-items: center;
}
