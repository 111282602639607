amortization-custom-fees-inline-panel inline-panel .icon-plus {
  padding: 0 0 0 5px;
  cursor: pointer;
}
amortization-custom-fees-inline-panel inline-panel .icon-remove {
  padding: 0 0 0 5px;
  cursor: pointer;
}
amortization-custom-fees-inline-panel .custom-fee-input {
  width: 200px;
}
amortization-custom-fees-inline-panel .custom-fee-button {
  align-self: center;
}
