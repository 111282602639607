funds-movement {

  .checks-summary {

    &__table {
      td {
        &:nth-child(3) {
          white-space: nowrap;
        }
      }
    }

    &__total-amount-label {
      font-weight: var(--font-weight-semibold);
      text-align: right;
      vertical-align: middle;
    }

    &__empty-list td:first-child {
      text-align: center;
    }
  }
}
