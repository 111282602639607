batch-clearing {
  .btn {
    min-width: 100px;
  }

  .validation-result {
    margin: 20px 0;

    &__table-header {
      margin-bottom: 0;
    }
  }
}