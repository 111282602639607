@import 'src/style/mixins';

.gritter-item-wrapper {
  @include box-shadow();
  background: var(--notification-background-color);
  color: var(--text-color);
  border-radius: var(--default-border-radius) !important;
}

.gritter-item {
  color: var(--text-color);
  font-family: var(--font-family) !important;
  font-size: var(--font-size-xs) !important;
  font-weight: var(--font-weight-semibold);
  padding-bottom: var(--small-gap);
  padding-left: var(--box-padding);
  padding-right: var(--box-padding);
  padding-top: var(--small-gap);
}

.gritter-title {
  color: var(--text-color);
  text-shadow: none;
}

.gritter-top,
.gritter-item,
.gritter-bottom {
  background: none;
}
