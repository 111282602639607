.print-config-row {
  align-items: center;
  display: flex;

  .btn-group {
    margin-bottom: 0;
    margin-right: 1rem;
  }
}

.table {
  tbody tr td {
    &.status-REVERTED,
    &.status-REJECTED,
    &.status-ERROR {
      color: var(--danger-color);
    }

    &.status-PROCESSED {
      color: var(--success-color);
    }

    &.status-PENDING {
      color: orange;
    }
  }
}