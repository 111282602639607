.leaf {
  background: unset !important;
}

.folder {
  background: unset !important;

  & :global(.title-wrap) {
    background: unset !important;
  }
}