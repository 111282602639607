agent-activation {
  .profile-body hr {
    margin: 0;
    border: none;
    border-top: solid 1px lightgray;
  }

  .profile-body form {
    margin-bottom: 0;
    padding-top: 0;
  }

  .user-details {
    .buttons {
      padding-right: 20px;
    }
  }
}
