.center-header {
  display: flex;
  align-items: flex-start;
  font-size: var(--font-size-s);
  margin-top: 2rem;

  &__section {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    font-weight: var(--font-weight-semibold);
    padding-left: var(--element-side-gap);
    padding-right: var(--element-side-gap);

    &--bigger {
      flex: 2;
    }

    &--smaller {
      flex: 1;
    }

    .title {
      color: var(--text-color-light);
      font-size: var(--font-size-s);
      margin-bottom: 0.4rem;
    }

    .value {
      &.trim {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
}
