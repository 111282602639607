note-history table input {
  margin-right: 1rem;
}
note-history table .note-history__index {
  width: 1%;
}
note-history table .note-history__date,
note-history table .note-history__user,
note-history table .note-history__username,
note-history table .note-history__action {
  width: 10%;
}
note-history table .note-history__content {
  width: 60%;
  max-width: 500px;
  overflow-wrap: break-word;
}
note-history .modal {
  width: 560px;
}
note-history .modal modal-body .note-history__input_desc {
  width: 100%;
  resize: none;
  overflow-y: auto;
}
