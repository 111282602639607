product-list .product-list__table td {
  vertical-align: middle;
}
product-list .product-list__row {
  background-color: white;
  border-top: 1px solid var(--light-line-color);
}
product-list .product-list__column-edit {
  box-sizing: border-box;
  width: 100px;
}
