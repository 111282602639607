customer-loan-create-parameters {
  .policy-number-length-error {
    padding-top: 8px;
  }

  .entry-form {
    display: flex;
    flex-wrap: wrap;
  }

  table {
    margin-bottom: 0;

    select, input, textarea {
      margin-bottom: 0;
    }

    td {

      &.label {
        width: 280px;
      }

      &.values {
        display: flex;
        align-items: center;
        max-width: var(--input-max-width);
      }
    }

  }
}
