#dashboard_lookup_customer_div {
  .extended {
    width: 180px;
  }

  #dlc_spinner {
    margin-top: 100px;
    opacity: 0;
  }

  #mask-checkbox {
    float: left;
    width: 17px;
    margin-left: 5px;
  }

  @mask-color: #003399;

  #mask-product-input {
    color: @mask-color
  }

  #normal-icon {
    transform: rotate(90deg);
  }

  #mask-icon {
    transform: rotate(90deg);
    color: @mask-color
  }

}

#dashboard_lookup_customer_div.group-lookup {
  ul.members {
    .key {
      font-weight: var(--font-weight-semibold);
    }
  }


    .member-details {

      display: flex;
      padding: 10px;

      .column {

        &:nth-child(1) {
          padding-right: 20px;
          border-right: solid 1px lightgray;
        }

        &:nth-child(2) {
          padding-left: 20px;
        }

        .header {
          font-weight: var(--font-weight-semibold);
        }
      }

    button {
      margin-top: 10px;
    }
  }
}