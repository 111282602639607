/*! Angular Moment Picker - v0.10.2 - http://indrimuska.github.io/angular-moment-picker - (c) 2015 Indri Muska - MIT */
.moment-picker-input {
  cursor: pointer;
}
.moment-picker {
  position: absolute;
  z-index: 1060;
}
.moment-picker .moment-picker-container {
  color: #404040;
  min-width: 15em;
  background: #fff;
  padding: 4px;
  border: 1px solid #f0f3f4;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  position: absolute;
  margin-top: 4px;
  margin-left: -0.5em;
  -webkit-box-shadow: 0 2px 4px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: 0 2px 4px rgba(0, 0, 0, 0.075);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.075);
}
.moment-picker .moment-picker-container:before,
.moment-picker .moment-picker-container:after {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border: 8px solid transparent;
  border-top: none;
  position: absolute;
  top: -9px;
  left: 15px;
}
.moment-picker .moment-picker-container:before {
  border-bottom-color: #f0f3f4;
  border-width: 9px;
}
.moment-picker .moment-picker-container:after {
  border-bottom-color: #fff;
  margin-top: 1px;
  margin-left: 1px;
}
.moment-picker.inline {
  display: block;
  position: relative;
}
.moment-picker.inline .moment-picker-container {
  position: relative;
  margin: 0;
}
.moment-picker.inline .moment-picker-container:before,
.moment-picker.inline .moment-picker-container:after {
  content: none;
}
.moment-picker.top .moment-picker-container {
  bottom: 100%;
  margin-top: auto;
  margin-bottom: 4px;
}
.moment-picker.top .moment-picker-container:before,
.moment-picker.top .moment-picker-container:after {
  border: 8px solid transparent;
  border-bottom: none;
  top: auto;
  bottom: -9px;
}
.moment-picker.top .moment-picker-container:before {
  border-top-color: #f0f3f4;
  border-width: 9px;
}
.moment-picker.top .moment-picker-container:after {
  border-top-color: #fff;
  margin-top: auto;
  margin-bottom: 1px;
}
.moment-picker.right .moment-picker-container {
  right: 0;
  margin-left: auto;
  margin-right: -0.5em;
}
.moment-picker.right .moment-picker-container:before,
.moment-picker.right .moment-picker-container:after {
  left: auto;
  right: 15px;
}
.moment-picker.right .moment-picker-container:after {
  margin-left: auto;
  margin-right: 1px;
}
.moment-picker table {
  border-collapse: collapse;
  border-spacing: 0;
  min-width: 100%;
  table-layout: fixed;
}
.moment-picker th {
  font-weight: bold;
}
.moment-picker th:first-child,
.moment-picker th:last-child {
  width: 2em;
}
.moment-picker th,
.moment-picker td {
  padding: 0;
  text-align: center;
  min-width: 2em;
  height: 2em;
  text-shadow: 0 1px 0 rgba(255, 255, 255, 0.9);
  cursor: pointer;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}
.moment-picker th:hover,
.moment-picker td:hover {
  background-color: #fafbfb;
  background-image: -webkit-gradient(linear, left top, left bottom, from(#f0f3f4), to(#fafbfb));
  background-image: -webkit-linear-gradient(#f0f3f4, #fafbfb);
  background-image: -moz-linear-gradient(#f0f3f4, #fafbfb);
  background-image: -o-linear-gradient(#f0f3f4, #fafbfb);
  background-image: linear-gradient(#f0f3f4, #fafbfb);
}
.moment-picker th.disabled,
.moment-picker td.disabled,
.moment-picker th.disabled:hover,
.moment-picker td.disabled:hover {
  color: #abbbc7;
  background: none;
  cursor: default;
}
.moment-picker td.today {
  background: #e4eef5;
  color: #404040;
  text-shadow: 0 1px 0 rgba(255, 255, 255, 0.9);
}
.moment-picker td.selected {
  color: #fff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.3);
  border-color: #3ca0dd;
  background-color: #45b1e8;
  background-image: -webkit-gradient(linear, left top, left bottom, from(#45b1e8), to(#3097de));
  background-image: -webkit-linear-gradient(#45b1e8, #3097de);
  background-image: -moz-linear-gradient(#45b1e8, #3097de);
  background-image: -o-linear-gradient(#45b1e8, #3097de);
  background-image: linear-gradient(#45b1e8, #3097de);
}
.moment-picker td.highlighted {
  background-image: -webkit-radial-gradient(transparent, rgba(0, 0, 0, 0.15));
  background-image: -moz-radial-gradient(transparent, rgba(0, 0, 0, 0.15));
  background-image: -o-radial-gradient(transparent, rgba(0, 0, 0, 0.15));
  background-image: radial-gradient(transparent, rgba(0, 0, 0, 0.15));
}
.moment-picker .decade-view td,
.moment-picker .year-view td {
  height: 3.4em;
}
.moment-picker .month-view .moment-picker-specific-views th {
  background: none;
  cursor: default;
}
.moment-picker .month-view td {
  width: 1.42857143em;
}
.moment-picker .day-view td,
.moment-picker .hour-view td {
  height: 2.33333333em;
}
.moment-picker .minute-view td {
  height: 1.8em;
}
