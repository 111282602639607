.compliance-config-menu {
  @light-gray: #fafafa;
  @border-color: lightgray;

  margin: 0;
  border-bottom: 1px solid @border-color;
  background: @light-gray;

  &__item {
    @compliance-config-menu-height: 40px;

    box-sizing: border-box;
    font-size: var(--font-size-m);
    text-transform: uppercase;
    list-style: none;
    display: inline-block;
    width: 25%;
    height: @compliance-config-menu-height;
    line-height: @compliance-config-menu-height;
    text-align: center;
    cursor: pointer;

    &.inactive {
      color: @border-color;
      cursor: not-allowed;
    }

    &.active {
      border-bottom: 5px solid var(--primary-color);
    }

    i {
      color: var(--danger-color);
    }
  }
}

.compliance-profile-config__control-buttons {
  padding-right: 40px;
  text-align: right;
}

.compliance-profile-config-section form {
  margin: 0;
}

.compliance-profile-config-section__column {
  box-sizing: border-box;
  width: 600px;
  float: left;
  padding: 0 0 0 20px;
}

.compliance-profile-config-section__glue >  div {
  width: 200px;
  padding: 0 0 0 10px;
}
