.mfa-configuration__toggle-link {
  border: none;
  padding: 0;
}

.mfa-configuration__secret-code {
  // use of em as an exception to assure relativity to text around the box
  font-size: var(--font-size-l);
}

.mfa-configuration__content {
  display: flex;
  flex-direction: column
}
