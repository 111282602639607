gl-category-input {
  counter-reset: gl-category-input__account;
}
gl-category-input .gl-category-input__tbody {
  background: white;
}
gl-category-input .gl-category-input__row > *:first-child:after {
  content: counter(gl-category-input__account) ".";
  counter-increment: gl-category-input__account;
}
