gl-account-list {
  .table {
    th, td {;
      vertical-align: middle;
    }
  }

  .tbody {
    background-color: #ffffff;
  }

  .open-link-container {
    text-align: right;
  }
}