role-write-access-details {
  input, select {
    margin-bottom: 0;
  }

  .action-config td {
    &:first-child {
      width: 20%;
    }

    &:last-child {
      width: 80%;
    }
  }

  .predicates, .predicates-group {
    display: flex;
  }

  .predicates-row {
    transition: all 0.3s ease-in-out;
  }

}