role-write-access-list {

  table {

    thead tr, thead td {
      font-weight: var(--font-weight-semibold);
    }

    .group {
      font-weight: var(--font-weight-semibold);
      width: 95%;
    }

    .action {
      width: 5%;
    }

  }
}