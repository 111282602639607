dynamic-report-mapping-edit .actions-header {
  margin-bottom: 4rem;
  margin-top: 4rem;
}
dynamic-report-mapping-edit .sheet {
  width: 300px;
}
dynamic-report-mapping-edit .cell-value {
  width: 200px;
}
dynamic-report-mapping-edit .actions {
  width: 310px;
}
dynamic-report-mapping-edit tr.ng-invalid {
  border: 2px solid var(--danger-color);
}
dynamic-report-mapping-edit .formula textarea {
  box-sizing: border-box;
  width: 100%;
}
