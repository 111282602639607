#customer-deposit-accounts-create {
  table {

    td.number {
      width: 20px;
    }

    td.buttons {
      text-align: right;
    }
  }
}
