customer-merge-customers {
  .inactive-row td {
    color: #b9b9b9;
  }

  .customer-merge__control-buttons {
    float: right;

    &.check-control-buttons {
      margin-bottom: 15px;
    }
  }

  #merged-customers-switch {
    float: left;
  }

  #customers-merge-table {
    input {
      margin: 0;
    }
  }
}