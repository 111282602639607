.local-override {
  position: fixed;
  background: rgba(0, 0, 0, 0.8);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: var(--z-index-always-on-top);
  outline: none;
}

.local-override__content {
  background: var(--white);
  box-sizing: border-box;
  text-align: center;
  position: relative;
  top: 10%;
  padding-top: 40px;
  padding-left: var(--box-padding);
  padding-right: var(--box-padding);
  padding-bottom: 40px;
  margin-left: auto;
  margin-right: auto;
  width: 45%;

  .labelled-field {
    margin-right: 0;
    margin-top: 2rem;
  }

  .pin-input-field {
    height: 4rem;
    width: 4rem;
    background: var(--input-background-color);
    border-radius: var(--default-border-radius);
    box-sizing: border-box;
    text-align: center;
    outline: none;
    box-shadow: none;
    font-size: var(--font-size-m);
    color: var(--text-color);
    margin-right: 15px;
    transition: var(--default-transition);

    &:last-child {
      margin-right: 0;
    }

    &:disabled {
      border-color: var(--light-line-color);
    }
  }

  .approval-user-select-title {
    font-size: var(--font-size-m);
    font-weight: var(--font-weight-semibold);
    margin-bottom: 2rem;
  }

  #approval-user-select {
    width: 360px;
    margin-bottom: 20px;
  }
}

.local-override__title {
  color: var(--text-color-light);
  font-size: var(--font-size-s);
  font-weight: var(--font-weight-semibold);
  margin-bottom: 2rem;
  margin-top: 2rem;
}

#local-override__submit {
  margin-top: 3rem;

  &:disabled {
    cursor: not-allowed;
  }
}
