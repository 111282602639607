watchlist-view .watchlist__table {
  box-sizing: border-box;
  margin: 20px;
  width: calc(100% - 40px);
}
watchlist-view .watchlist__table td:first-child,
watchlist-view .watchlist__table th:first-child {
  width: 30px;
}
watchlist-view .watchlist__table td:last-child,
watchlist-view .watchlist__table th:last-child {
  width: 80px;
}
watchlist-view .watchlist__open-button {
  width: 80px;
}
