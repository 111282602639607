@import "~quill/dist/quill.core.css";
@import "~quill/dist/quill.snow.css";
wysiwyg-editor {
  display: block;
}
wysiwyg-editor .editor-container {
  background: white;
}
wysiwyg-editor .editor {
  height: 600px;
  overflow: auto;
}
wysiwyg-editor .ql-snow .ql-picker.ql-size .ql-picker-item::before,
wysiwyg-editor .ql-snow .ql-picker.ql-size .ql-picker-label::before {
  content: attr(data-value);
}
wysiwyg-editor .ql-snow .ql-picker .ql-picker-options {
  max-height: 300px;
  overflow-y: auto;
}
wysiwyg-editor .ql-showTemplate:after {
  content: "[source]";
}
wysiwyg-editor .editor-showTemplate {
  background: var(--text-color);
  box-sizing: border-box;
  border: none;
  bottom: 0;
  color: var(--text-color-light);
  margin: 0;
  outline: none;
  padding: 10px;
  position: absolute;
  width: 100%;
  top: 0;
}
