.administration-branches {
  .active {
    color: var(--success-color);
  }

  .pending {
    color: orange;
  }

  .closed, .error {
    color: var(--danger-color);
  }

  .neutral {
    color: darkgray;
  }
}
