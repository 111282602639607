.main-view credit-scoring-modal-legend {
  flex: 1 0 24rem;
  line-height: normal;
  border: none;
}
.main-view credit-scoring-modal-legend .cs-row {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: var(--xsmall-gap) 0;
  margin-left: 0;
  border-bottom: 0.03rem solid rgba(0, 0, 0, 0.1);
}
.main-view credit-scoring-modal-legend .cs-row .cs-label {
  white-space: nowrap;
  padding-right: var(--small-gap);
  color: var(--text-color-light);
  font-size: var(--font-size-xs);
  font-weight: 600;
  text-transform: uppercase;
}
.main-view credit-scoring-modal-legend .cs-row .cs-label *[class*='icon-'] {
  display: inline-block;
  padding-right: var(--small-gap);
  text-align: center;
  width: 1rem;
}
.main-view credit-scoring-modal-legend .cs-row .cs-value {
  border-radius: var(--default-border-radius);
  flex: 1 0 8rem;
  font-size: var(--font-size-xs);
  font-weight: bold;
  letter-spacing: 0.05em;
  padding: var(--xsmall-gap);
  text-transform: uppercase;
  text-align: center;
  max-width: 10rem;
}
.main-view credit-scoring-modal-legend .cs-row .cs-value.color-poor {
  color: var(--poor-color) !important;
  background: var(--poor-background-color) !important;
}
.main-view credit-scoring-modal-legend .cs-row .cs-value.color-bad {
  color: var(--bad-color) !important;
  background: var(--bad-background-color) !important;
}
.main-view credit-scoring-modal-legend .cs-row .cs-value.color-average {
  color: var(--average-color) !important;
  background: var(--average-background-color) !important;
}
.main-view credit-scoring-modal-legend .cs-row .cs-value.color-good {
  color: var(--good-color) !important;
  background: var(--good-background-color) !important;
}
.main-view credit-scoring-modal-legend .cs-row .cs-value.color-excellent {
  color: var(--excellent-color) !important;
  background: var(--excellent-background-color) !important;
}
.main-view credit-scoring-modal-legend .cs-row .cs-value.color-outstanding {
  color: var(--outstanding-color) !important;
  background: var(--outstanding-background-color) !important;
}
