@import 'src/style/mixins';

.accordion-header {
  border-bottom: 1px solid var(--light-line-color);
  box-sizing: border-box;
  font-size: var(--font-size-s);
  font-weight: var(--font-weight-semibold);
  padding-bottom: 2rem;
  padding-right: 4rem;
  margin-bottom: 3rem;
  margin-top: 4rem;
  position: relative;
  align-items: center;
  display: flex;

  &::before {
    background: url(icons/icon-chevron-down.svg) no-repeat left;
    content: "";
    height: 3rem;
    position: absolute;
    right: 0;
    top: 0;
    transition: var(--default-transition);
    width: 3rem;
  }

  &-buttons {
    display: flex;
    flex-direction: column;
    margin-left: 2rem;

    .btn + .btn {
      margin-left: 0;
      margin-top: 2rem;
    }
  }

  &.collapsed {
    &::before {
      transform: rotate(180deg);
    }
  }

  .label {
    margin-right: 0.5rem;
  }

  &-label {
    font-size: var(--font-size-l);
    margin-right: auto;
  }

  &-form-field {
    color: var(--text-color-light);
    display: flex;
    font-size: var(--font-size-s);
    margin-left: 2rem;
    margin-right: 2rem;

    &:first-of-type {
      margin-bottom: 2rem;
    }

    td {
      align-items: center;
      display: flex;
    }
  }

  input {
    max-width: 16rem !important;
    font-size: var(--font-size-xs) !important;
  }
}

.accordion-body {
  box-sizing: border-box;

  .table {
    margin: 0;
    width: 100%;

    td {
      &:first-child {
        &::before {
          display: none !important;
        }
      }
    }
  }

  .product-attribute {
    margin-bottom: 1px;
  }

  .product-info-section {
    margin-top: 1rem;
    margin-bottom: 2rem;
    margin-left: var(--small-gap);
  }

  table {
    button {
      height: var(--input-height);
    }
  }
}
