deposit-manual-rollover {

  .range-input {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    .min-max {
      flex-grow: 1;
      flex-shrink: 0;
      flex-basis: 150px;
    }

    .value {
      flex-basis: 150px;
      flex-shrink: 0;
      flex-grow: 2;
      margin: 0 5px;
    }
  }

  .input-prepend.input-append .add-on:last-child {
    margin-left: 0.4rem;
  }

  .misc-transaction__table td:last-child,
  .misc-transaction__table th:last-child {
    width: 25%;
    padding-right: 2%;
  }

  .help-container {
    align-items: center;
    display: flex;
  }
}
