@import 'src/style/mixins';

.btn {
  align-items: center;
  background: var(--white);
  border: solid 2px var(--primary-color);
  border-radius: var(--default-border-radius);
  box-sizing: border-box;
  color: var(--primary-color);
  cursor: pointer;
  display: inline-flex;
  font-size: var(--font-size-s);
  font-weight: var(--font-weight-bold);
  line-height: var(--font-size-l);
  justify-content: center;
  min-height: var(--big-button-height);
  min-width: 8rem;
  padding-left: var(--element-side-gap);
  padding-right: var(--element-side-gap);
  transition: var(--default-transition);

  &:not(:disabled) {
    &:hover {
      opacity: 0.7;
    }
  }

  i {
    margin-right: 0.6rem;

    &.icon-centered {
      margin-right: 0;
    }
  }

  & + .btn {
    margin-left: var(--element-side-gap);
  }

  &-small {
    font-size: var(--font-size-xs);
    min-height: var(--element-side-gap);
  }

  &.active,
  &-primary {
    background: var(--primary-color);
    border-color: transparent;
    color: var(--white);
  }

  &-danger {
    background: var(--danger-color);
    border-color: transparent;
    color: var(--white);
  }

  &-warning {
    background: var(--warning-color);
    border-color: transparent;
    color: var(--white);
  }

  &-success {
    background: var(--success-color);
    border-color: transparent;
    color: var(--white);
  }

  &:disabled {
    background: var(--disabled-button-background-color);
    border-color: transparent;
    color: var(--white);
    cursor: default;
  }

  &--align-with-form-field {
    margin-left: 30%;
    width: var(--input-width-l);
  }

  &-file {
    position: relative;

    input {
      cursor: pointer;
      opacity: 0;
      position: absolute;
      width: 100%;
    }
  }
}

.summary-actions,
.buttons {
  align-items: center;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 2rem;
  margin-top: 2rem;

  .btn, signature-card {
    & + .btn,
    & + signature-card {
      margin-left: var(--element-side-gap);
      margin-top: 0 !important;
    }
  }

  & + .buttons {
    margin-left: var(--element-side-gap);
  }
}

.button-container {
  margin-left: auto;

  .btn {
    margin-top: 0;
  }
}

.dropdown-toggle {
  display: flex;
  justify-content: space-between;

  .caret {
    margin-top: 0.2rem !important;
    margin-left: 0.4rem !important;
  }
}
