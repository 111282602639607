red-flag-disposition {
  display: block;

  .table {
    th {
      border-top: none;
      text-align: left;
    }
  }

  .report-table-wrapper {
    border: 1px solid var(--light-line-color);
    border-radius: var(--default-border-radius);
    width: 100%;
    max-width: 100%;
    margin-top: 2rem;
    margin-bottom: 3rem;
    overflow: auto;

    .table {
      margin-bottom: 0;
      margin-left: -1px;
      margin-right: -1px;
      width: 100%;
    }
  }

}
