boolean-condition {
  display: block;
  white-space: nowrap;

  .condition {
    display: flex;
    align-items: center;
    width: fit-content;
  }

  .condition-container {
    display: flex;
    flex-direction: column;
  }
}