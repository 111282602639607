@import 'src/style/mixins';

.full-width {
  max-width: none;
  width: 100%;
}

.margin-bottom {
  margin-bottom: 1rem;
}

.margin-left {
  margin-left: 1rem !important;
}

.margin-right {
  margin-right: 1rem !important;
}
