paid-amortizations .table .initial-row > * {
  opacity: 0.7;
}
paid-amortizations .table .penalties-column {
  padding-right: 10px;
}
paid-amortizations .table .status-col {
  text-align: center;
  width: 80px;
}
paid-amortizations .table td.transparent,
paid-amortizations .table tr:hover td.transparent {
  background: transparent;
}
