#customer-deposit-accounts-check-deposit, #customer-deposit-accounts-check-withdrawal{
 .buttons {
    float: right;
    margin-top: 20px;
  }

  #recalculate-tax-btn {
    height: 30px;
    width: auto;
  }

  .check-no {
    height: 30px;
    width: 100%;
  }

  td {
    &:nth-child(2) {
      width: 25%;
    }
  }

  .check-deposit-table {
    td, th {
      &:first-child {
        width: 30px;
      }

      &:nth-child(3) {
        width: 10%;
      }

      &:nth-child(4) {
        width: 35%;
      }

      &:last-child {
        width: 100px;
      }
    }

    &__total-label {
      font-weight: var(--font-weight-semibold);
      text-align: right;
      vertical-align: middle;
    }

    &__no-checks-message {
      text-align: center;
    }
  }
}