#reportModal {
  left: 0;
  right: 0;
  width: 80%;
  height: 80%;
  margin: 0 auto;

  .modal-body {
    max-height: 80%;
  }

  @media print {
    background-color: white !important;
    border: 0;
    height: auto;
    margin: 0;
    top: 0;
    width: 100%;
    width: -moz-fit-content; 
    width: fit-content; 

    .modal-body {
      overflow: hidden;
    }

    .modal-header, .modal-footer {
      display: none;
    }
  }
}