.center-header {
  display: flex;
  align-items: flex-start;
  font-size: var(--font-size-s);
  margin-top: 2rem;
}
.center-header__section {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  font-weight: var(--font-weight-semibold);
  padding-left: var(--element-side-gap);
  padding-right: var(--element-side-gap);
}
.center-header__section--bigger {
  flex: 2;
}
.center-header__section--smaller {
  flex: 1;
}
.center-header__section .title {
  color: var(--text-color-light);
  font-size: var(--font-size-s);
  margin-bottom: 0.4rem;
}
.center-header__section .value.trim {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
