.nx-selectize + .selectize-control.single {
  .account {
    &-option {
      /* hack
        selectize input resizes by 1px on focus -
        position changes from absolute -> relative
        a way to make it invisible for final user is
        to add 1px to immediately preceding element...
      */

      height: 21px;
    }

    &-group {
      margin-right: 5px;
    }

    &-name {
      margin-right: 5px;
    }
  }

  .selectize-input {
    overflow: inherit;
    padding: 4px 24px 4px 8px;
    vertical-align: top;

    &:after {
      right: 12px;
    }
  }

  .selectize-dropdown-content {
    .account-group .highlight {
      background: transparent;
    }
  }

}

selectize.ng-invalid {
  outline: none;

  & + .selectize-control {
    border-color: transparent;
    outline: none;

    &:focus {
      box-shadow: none;
    }

    .selectize-input {
      border-color: transparent !important;
      border: 2px solid var(--danger-color) !important;
      outline: none;

      &.input-active {
        border-color: #e9322d !important;
        border: 2px solid var(--danger-color) !important;
      }
    }
  }
}