#loan-check-release {
    table {
      margin-top: 10px;

      input {
        margin: 0;
      }
    }

    .remarks {
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      max-width: 450px;

      .caption {
        font-weight: var(--font-weight-bold);;
        margin-right: 20px;
      }
    }
}