note-history {
  table {
    input {
      margin-right: 1rem;
    }

    .note-history__index {
      width: 1%;
    }

    .note-history__date, .note-history__user, .note-history__username, .note-history__action {
      width: 10%;
    }

    .note-history__content {
      width: 60%;
      max-width: 500px;
      overflow-wrap: break-word;
    }
  }

  .modal {
    width: 560px;

    modal-body {
      .note-history__input_desc {
        width: 100%;
        resize: none;
        overflow-y: auto;
      }
    }
  }
}