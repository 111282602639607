transactions-category-list .category-list__header {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 15px 0;
}
transactions-category-list .category-list__header .input-prepend {
  margin: 0;
}
transactions-category-list .category-list__action {
  width: 100px;
}
