.sub-header {
  margin-top: 1rem;
  margin-bottom: 4rem;
  min-height: 3.6rem;

  &--small {
    .sub-header__title {
      font-size: 2.4rem;
    }
  }

  &__column-right {
    align-items: center;
    display: flex;
    font-size: var(--font-size-l);
    justify-content: flex-end;
  }

  .right-header-panel {
    display: flex;
    font-size: var(--font-size-s);
  }
}