working-day-check {
  .working-day-overlay {
    z-index: var(--z-index-always-on-top);
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: rgba(51, 51, 51, 0.7);
  }

  .message-container-wrapper {
     align-items: center;
     display: flex;
     height: 100%;
     justify-content: center;
     position: static;
     width: 100%;
   }

  .message-container {
    align-items: center;
    background: var(--primary-color);
    color: var(--white);
    min-height: 18rem;
    display: flex;
    justify-content: center;
    font-size: var(--font-size-l);
    font-weight: var(--font-weight-semibold);
    text-align: center;
    width: 100%;

    a {
      color: var(--white);
      margin-top: 20px;
    }

    i {
      cursor: pointer;
      font-size: var(--font-size-l);
      margin-left: 0.5rem;
      padding: 2rem;
    }
  }
}
