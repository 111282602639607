credit-scoring-modal-features {
  width: 100%;

  @media print {
    canvas {
      height: auto !important;
      max-height: 100%;
      max-width: 100%;
      min-height: 100%;
      width: auto !important;
    }
  }
}
