logged-in-users .nav-tabs .disabled {
  color: darkgray;
}
logged-in-users .table-content {
  overflow: visible;
}
logged-in-users .logged-in-icon {
  color: var(--success-color);
}
logged-in-users .logged-out-icon {
  color: var(--danger-color);
}
