collections-filter {
  td:nth-child(1) {
    width: 10%;
  }
  td:nth-child(2) {
    width: 45%;
  }
  td:nth-child(3) {
    width: 45%;
  }
}
