
.print-only {
  display: none;
}

@media print {
  html {
    font-size: 10px;
  }

  .no-print {
    display: none;
  }

  .print-only {
    display: block;
  }

  .gritter-item-wrapper {
    display: none;
  }

  .sidenav,
  .pagination,
  .breadcrumbs,
  sub-header,
  customer-profile-header,
  .filters,
  .nav-tabs,
  .alert {
    display: none !important;
    height: 0 !important;
    padding: 0 !important;
    margin: 0 !important;
  }

  .top-bar {
    position: relative;

    * {
      display: none !important;
    }

    &__logo {
      display: none !important;
    }
  }

  .table,
  .box,
  .form-column {
    padding: 0 !important;
    margin: 0 !important;
  }

  .table {
    max-width: 100% !important;
  }

  tr {
    page-break-inside: auto;
  }

  button, button.btn {
    display: none;
  }

  select {
    // disable the arrow on select boxes
    -webkit-appearance: none;
  }
}
