help {
  .help-icon {
    cursor: pointer;
    font-size: var(--font-size-m);
    margin-left: 0.8rem;

    &:hover {
      @import '~@nextbank/bootstrap/less/variables';
      transition: color 0.2s ease;
      color: @blue;
    }
  }
}
