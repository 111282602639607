@media print {
  gl-transaction-interbranch-create breadcrumb,
  gl-transaction-interbranch-create gl-header,
  gl-transaction-interbranch-create form {
    display: none;
  }
}
gl-transaction-interbranch-create .transaction-create-table input,
gl-transaction-interbranch-create .transaction-create-table select {
  margin: 0;
}
gl-transaction-interbranch-create .transaction-create-table .no-column-header {
  min-width: 10px;
}
gl-transaction-interbranch-create .transaction-create-table .home-column-header {
  width: 15px;
}
gl-transaction-interbranch-create .transaction-create-table .branch-column-header {
  width: 25%;
}
gl-transaction-interbranch-create .transaction-create-table .account-column-header {
  width: 25%;
}
gl-transaction-interbranch-create .transaction-create-table .smaller-column-header {
  width: 15%;
}
