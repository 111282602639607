#dashboard_action_list_div {
  .action-tab-content {
    overflow: visible;
  }
}


.dashboard_action_view_profile_div {
  .dashboard_action_view_profile_edit-button {
    margin-right: 10px;
  }
}