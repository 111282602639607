credit-scoring-modal-model-confusion-matrix {
  width: 100%;
}
credit-scoring-modal-model-confusion-matrix .model-confusion-container {
  display: grid;
  grid-template-areas: ". top top" "ac tp fn" "ac fp tn";
  grid-template-columns: 1fr 2fr 2fr;
  grid-template-rows: 1fr 2fr 2fr;
  grid-column-gap: var(--box-padding);
  grid-row-gap: var(--box-padding);
  text-align: center;
}
credit-scoring-modal-model-confusion-matrix .model-confusion-container > div {
  border-radius: var(--default-border-radius);
  display: block;
  padding: var(--box-padding);
}
credit-scoring-modal-model-confusion-matrix .center {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
}
credit-scoring-modal-model-confusion-matrix .prediction-value {
  font-size: 2.5rem;
}
credit-scoring-modal-model-confusion-matrix .tile-description-top {
  grid-area: top;
}
credit-scoring-modal-model-confusion-matrix .tile-description-left {
  grid-area: ac;
}
credit-scoring-modal-model-confusion-matrix .tile-top-left {
  grid-area: tp;
}
credit-scoring-modal-model-confusion-matrix .tile-top-right {
  grid-area: fn;
}
credit-scoring-modal-model-confusion-matrix .tile-bottom-left {
  grid-area: fp;
}
credit-scoring-modal-model-confusion-matrix .tile-bottom-right {
  grid-area: tn;
}
credit-scoring-modal-model-confusion-matrix .background-positive {
  background: var(--good-background-color) !important;
}
credit-scoring-modal-model-confusion-matrix .background-negative {
  background: var(--bloody-background-color) !important;
}
credit-scoring-modal-model-confusion-matrix .background-description {
  background: var(--body-color) !important;
  text-align: center;
}
