@import '~@nextbank/ui-components/dist/mixins';
@import '~@nextbank/ui-components/dist/variables';

.items {
  @include default-transition;

  border-radius: $borderRadius;
}

.isDraggingOver {
  background: var(--notification-background-color);
}