@import 'src/style/mixins';

.filters {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 2rem;
  margin-top: 3rem;
  width: 100%;

  & > * {
    margin-bottom: 2rem;
  }

  .input-prepend {
    align-items: center;
    display: flex;
    margin: 0;

    .add-on {
      font-size: var(--font-size-s);
      font-weight: var(--font-weight-semibold);
      margin: 0;
      margin-right: 0.6rem;
    }
  }

  form {
    display: flex;
  }

  .btn,
  .mask-switch {
    margin-left: 0;
    margin-right: 2rem;
  }
}

.actions {
  &__label {
    font-size: var(--font-size-s);
    font-weight: var(--font-weight-normal);
  }
}

tree-filter {
  background: var(--input-background-color);
  border-radius: var(--default-border-radius);
  display: inline-block;
}

ent-horde {
  background: var(--input-background-color);
  border-radius: var(--default-border-radius);
  display: block;
  margin: 0;

  .ent-horde__tree-element.inspire-tree a {
    cursor: pointer;
    text-decoration: none !important;
  }

  .ent-horde__chosen-option {
    align-items: center;
    background: var(--input-background-color);
    border: 0;
    border-radius: var(--default-border-radius);
    box-sizing: border-box;
    color: var(--text-color);
    display: flex;
    font-size: var(--font-size-s);
    font-weight: var(--font-weight-semibold);
    height: var(--input-height);
    padding-left: var(--element-side-gap);
    padding-right: var(--element-side-gap);

    &:after {
      content: "\25BC";
      margin-left: auto;
      padding: 0;
      color: var(--text-color-light);
    }

    &.expanded {
      &:after {
        content: "\25B2";
        padding: 0;
      }
    }
  }

  .icon-collapse,
  .icon-expand {
    left: -0.3rem !important;
    position: absolute;
    top: 0 !important;
    z-index: var(--z-index-prioritized);

    &:before {
      content: "" !important;

    }
  }

  .ent-horde__tree-wrapper {
    position: relative;
  }

  .ent-horde__tree {
    &-element.inspire-tree {
      @include box-shadow;
      background: var(--input-background-color);
      box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
      left: 0;
      position: absolute;
      top: 0;
      z-index: var(--z-index-dropdowns);

      ol {
        margin: 0 !important;
      }

      & > ol {
        max-height: 600px;
        margin: 0;
        overflow: auto;
        padding: 5px 10px;
      }

      .title-wrap {
        display: flex;
        min-height: none !important;
      }

      a {
        color: unset;
        cursor: pointer;
        font-size: var(--font-size-xs);
        text-decoration: none !important;

        &.title.load-more {
          color: var(--text-color);
          font-family: inherit;
          font-weight: var(--font-weight-bold);
        }
      }

      .wholerow {
        background-color: transparent !important;
        border: none !important;
      }

      ol {
        transform: translateZ(0);
      }
    }
  }
}
