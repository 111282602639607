@import 'src/style/mixins';

.table {
  background: var(--white);
  font-size: var(--font-size-s);
  font-weight: var(--font-weight-semibold);
  margin-left: calc(-1 * var(--box-padding));
  margin-right: calc(-1 * var(--box-padding));
  max-width: unset;
  width: calc(100% + 2 * var(--box-padding));

  &--last-column-to-right {
    & > thead,
    & > tr {
      th {
        &:last-of-type {
          text-align: right;
        }
      }
    }
  }

  &--generate-row-ids {
    counter-reset: misc-transaction-table;

    tbody tr:not(.table--skip-generate-row-id) td:first-child::after {
      background: none !important;
      counter-increment: misc-transaction-table;
      content: counter(misc-transaction-table) "." !important;
      border-top: 1px solid var(--light-line-color);
      height: 100% !important;
      display: flex;
      align-items: center;
      opacity: 1 !important;
      padding-bottom: 2px;
    }
  }

  &--wide {
    font-size: var(--font-size-xs);

    textarea {
      font-size: var(--font-size-xs);
    }

    td,
    th {
      padding-bottom: 0.8rem;
      padding-left: 0.8rem !important;
      padding-right: 0.8rem !important;
      padding-top: 0.8rem;

      @media print {
        padding: 0.4rem !important;
      }

      &:first-child {
        padding-left: var(--box-padding) !important;


        @media print {
          padding-left: 0.4rem !important;
        }
      }

      &:last-child {
        padding-right: var(--box-padding) !important;

        @media print {
          padding-right: 0.4rem !important;
        }
      }
    }
  }

  &--report-description {
    .label {
      width: 30%;

      @media print {
        font-weight: var(--font-weight-normal);
      }
    }
  }

  &--tiny-inputs {
    select,
    input {
      font-size: var(--font-size-xs);
      padding-left: 0.6rem !important;
      padding-right: 0.6rem !important;
      max-width: 12rem;
    }

    .input-append,
    .input-prepend {
      .transclude {
        max-width: 12rem;
      }

      .add-on {
        font-size: var(--font-size-xs);
      }
    }

    select {
      min-width: 9rem;
    }
  }

  & + .table {
    margin-top: 4rem;
  }

  tbody {
    tr {
      transition: var(--default-transition);

      &:not(.table__row--not-hoverable) {
        position: relative;

        // TODO solution for show line in table that doesn't need transform
        // transform: scale(1);

        &:first-of-type {
          td {
            &::after {
              border: none;
              opacity: 0;
            }
          }
        }

        td {
          position: relative;

          &::after {
            background: var(--light-line-color);
            content: '';
            height: 1px;
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
          }


          &:first-child {
            &::after {
              left: var(--box-padding);
              width: calc(100% - var(--box-padding));
            }
          }

          &:last-child {
            &::after {
              width: calc(100% - var(--box-padding));
            }
          }
        }

        &.selected,
        &:hover {
          td {
            @include hovered-row-styles;
          }

          background: var(--hover-background-color);
        }

        td {
          @include hoverable-row;
          transition: var(--default-transition);

          &::before {
            display: none;
          }

          &:first-child {
            &::before {
              display: block;
            }
          }
        }
      }
    }
  }

  &__empty-message {
    td {
      height: 10rem;
      color: var(--text-color-light);
      font-size: var(--font-size-s);
      font-weight: var(--font-weight-semibold);
      text-align: center;
    }
  }

  th {
    background: none !important;
    color: var(--text-color-light);
    font-weight: var(--font-weight-regular);
    text-align: left;

    &.table__column-to-right {
      text-align: right;
    }
  }

  td,
  th {
    padding-bottom: 0.8rem;
    padding-left: calc(var(--box-padding) / 2);
    padding-right: calc(var(--box-padding) / 2);
    padding-top: 0.8rem;

    &:first-child {
      padding-left: var(--box-padding);
    }

    &:last-child {
      padding-right: var(--box-padding);
    }
  }

  .th--index {
    width: 2rem;
  }

  .ent {
    background: var(--white);
  }

  &__row {
    &--not-hoverable {
      td {
        padding-bottom: 0;
        padding-top: 0;
      }
    }

    &--clickable {
      cursor: pointer;
    }
  }

  .action-button {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    min-width: 8.5rem;
  }

  &__actions {
    align-items: center;
    display: flex;
    justify-content: flex-end;

    .btn,
    approval-action {
      & + .btn,
      & + approval-action {
        margin-left: var(--element-side-gap)
      }
    }

    &--input-height {
      height: var(--input-height);
    }
  }

  .btn {
    font-size: var(--font-size-s);
    min-height: var(--small-button-height);
  }

  .selectize-input {
    font-size: var(--font-size-s) !important;
  }

  .summary-actions {
    margin-bottom: 1rem;
    margin-top: 1rem;

    &--row {
      div + div {
        margin-left: 1rem;
      }
    }
  }

  thead {
    td {
      color: var(--text-color-light);
    }
  }
}

.toggleable-panel-wrapper {
  overflow: hidden;
  transition: 0.5s ease;
  max-height: 0;
  margin-left: calc(-1 * var(--box-padding));
  margin-right: calc(-1 * var(--box-padding));

  &.toggleable-panel-wrapper--toggled {
    @include box-shadow;
    max-height: 5000px;
  }
}

.pdc-table {
  table-layout: fixed;

  input,
  select {
    margin-bottom: 0;
    width: 100%;
    min-width: 6rem !important;
  }
}

.check-deposit-table {
  .cash {
    min-width: 25rem;
  }
}

.well--table {
  display: block;
  margin-left: calc(-1 * var(--box-padding));
  margin-right: calc(-1 * var(--box-padding));
  overflow-y: auto;
  padding: var(--box-padding);
}
