center-picker input.lookup-input {
  margin-bottom: 5px;
  width: 100%;
  max-width: 200px;
}
center-picker .search-btn {
  margin-left: 5px;
}
center-picker .content {
  border: 1px solid #999;
  padding: 10px;
  max-height: 305px;
  min-width: 700px;
  overflow-y: auto;
  overflow-x: hidden;
}
center-picker .content table a.btn {
  margin-left: 20px;
}
center-picker .no-results {
  min-width: 400px;
  color: gray;
  text-shadow: 1px 1px 0 #999;
  font-size: 15px;
  margin: 10px 15px;
}
