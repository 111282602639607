approval-hierarchy {
  .handler {
    cursor: grab;

    &-icon {
      margin-right: 10px;
    }
  }
}

.approval-hierarchy-row {
  td:first-child {
    width: 40px;
  }

  td:nth-child(2) {
    width: 400px;
    padding-right: 100px;
  }

  td:nth-child(3) {
    width: 400px;
    padding-right: 100px;
  }

  multiselect {
    width: 100%;
  }

  .selectize-control {
    .selectize-input [data-value] {
      .user__username {
        opacity: 0.6;
      }
      .user__full-name + .user__username {
        margin-left: 5px;
      }
    }

    .selectize-dropdown .user__username {
      font-size: var(--font-size-s);
      display: block;
      opacity: 0.6;
    }
  }
}

