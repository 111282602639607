#customer-deposit-accounts-cash-deposit {
  .cash-amount-picker {
    margin-top: 20px;
  }

  .buttons {
    float: right;
    margin-top: 40px;
    margin-right: 40px;
  }

}