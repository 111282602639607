.report-filters .filter-value:not(.ng-invalid) {
  box-shadow: 0 0 6px rgba(0, 0, 0, 0);
  transition: box-shadow 0.5s ease-in-out;
}
.report-filters .autofilled-with-default {
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.5);
}
.report-filters .labelled-field.filter {
  white-space: nowrap;
}
.report-filters .labelled-field.filter.day {
  min-width: fit-content;
}
