loan-edit-pdc table td:first-child {
  white-space: nowrap;
}
loan-edit-pdc table td:nth-child(2) {
  display: flex;
  align-items: center;
  width: 400px;
  white-space: nowrap;
}
loan-edit-pdc table td:last-child {
  width: 100%;
}
loan-edit-pdc td > input,
loan-edit-pdc select,
loan-edit-pdc .selectize-control {
  width: 100%;
}
