.form-section-field {
  clear: both;
  display: flex;
  align-items: center;

    .horizontal {
      display: flex;
      align-items: center;
    }

    .form-section-auto-width-title {
      width: auto;
    }

    .form-section-fixed-width-title {
      width: 30%;
    }

    .form-section-field-input {
      display: flex;
      width: 70%;

      &__fit-content {
        display: flex;
        align-items: center;
      }
    }

    // this moves some elements which have a too small height down so that they are centered.
    // TODO: generify this so that it works on all elements, not only the ones listed here

    input, select, .file-upload-container, account-select .selectize-control{

      &.small-input {
        width: 49%;
      }

      &.pull-left {
        float: left;
      }

      &.pull-right {
        float: right;
      }
    }

    multiselect {
      width: 100%;
      margin: 9px 0;
    }

    account-select input {
      height: unset;
    }

    input[type="checkbox"] {
      width: auto;
    }

    input[type="file"] {
      background-color: initial;
    }
  }
