#waive {
  .pay-all-block {
    margin: 4px 2px 0 0;
    .pay-all-line {
      margin:0 2px 0 0;
    }
  }

  .well {
    margin-top: 20px;
  }

  .bold {
    font-weight: var(--font-weight-semibold);
  }

  .buttons {
    float: right;
  }
}