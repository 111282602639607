funds-movement .checks-summary__table td:nth-child(3) {
  white-space: nowrap;
}
funds-movement .checks-summary__total-amount-label {
  font-weight: var(--font-weight-semibold);
  text-align: right;
  vertical-align: middle;
}
funds-movement .checks-summary__empty-list td:first-child {
  text-align: center;
}
