checkbook-details .checkbook-bar-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
checkbook-details .checkbook-bar {
  padding-top: 10px;
  padding-bottom: 10px;
}
checkbook-details .checkbook-print {
  min-width: 100px;
}
