#customer-loans {
  .label.late-check {
    margin-left: 5px;
  }

  .remove-padding {
    padding: 0 !important;
  }

  .collapse-borders {
    border-collapse: collapse;
  }

  .table table.table {
    margin-bottom: 0;
  }
}