new-access-rule {
  table {

    td:first-child {
      width: 30%;
    }

    td:last-child {
      width: 70%;
    }

    .selectize-control {
      width: 300px;

      .selectize-input {
        display: block;
      }
    }

  }

  .actions button {
    min-width: 100px;
    margin: 5px;
  }

}