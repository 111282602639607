@import 'src/style/mixins';

.labelled-field {
  border-radius: var(--default-border-radius);
  display: inline-block;
  margin-right: 2rem;
  min-width: var(--input-width-m);
  position: relative;

  &--short {
    width: var(--input-width-s);
  }

  &--long {
    width: var(--input-width-l);
  }

  & > label {
    color: var(--text-color-light);
    font-size: var(--font-size-xs);
    font-weight: var(--font-weight-semibold);
    position: absolute;
    left: var(--element-side-gap);
    pointer-events: none;
    top: 0.3rem;
    z-index: var(--z-index-prioritized);
  }

  & + .btn {
    margin-left: 0 !important;
  }

  .ent-horde__chosen-option,
  .multiselect__chosen-option,
  .selectize-input,
  select,
  input,
  input[type='time'],
  input[type='date'] {
    padding-bottom: 0 !important;
    padding-top: 1.4rem !important;
    width: 100%;
    z-index: var(--z-index-standard);
  }

  .selectize-input {
    input {
      padding-top: 0 !important;
    }
  }

  multiselect,
  ent-horde,
  tree-filter,
  branch-filter,
  loan-category-filter {
    width: 100%;
  }

  .inspire-tree {
    min-width: 100%;
  }

  select {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

select,
input,
textarea,
.uneditable-input {
  background: var(--input-background-color);
  border: 2px solid transparent;
  border-radius: var(--default-border-radius);
  box-sizing: border-box;
  color: var(--text-color);
  font-size: var(--font-size-s);
  font-weight: var(--font-weight-semibold);
  height: var(--input-height);
  padding-left: var(--element-side-gap);
  padding-right: var(--element-side-gap);
  transition: var(--default-transition);
  width: var(--input-width-m);

  &:placeholder {
    font-size: var(--font-size-s);
  }

  &:focus {
    outline: none !important;
    border-color: transparent !important;
    box-shadow: none !important;
  }

  &[type='time'],
  &[type='date'] {
    padding-bottom: 0.8rem;
    padding-top: 0.8rem;
  }

  &[type='checkbox'] {
    height: auto;
    width: auto !important;
  }

  &.disabled,
  &:disabled {
    border: 2px solid var(--input-background-color);
    background: var(--white);
    color: var(--disabled-input-text-color);
    opacity: 1 !important;

    &::placeholder {
      opacity: 0;
    }

    &.hidden-element {
      border: 0 !important;
    }
  }
}

textarea {
  height: auto;
}

.input-prepend {
  height: var(--input-height);
}

label,
.label {
  background: none;
  color: var(--text-color-light);
  font-weight: var(--font-weight-semibold);
  margin-right: 0.8rem;
}

.label {
  font-size: var(--font-size-s);
}

.ent-horde__chosen-option,
.multiselect__chosen-option,
.selectize-input {
  align-items: center;
  background: var(--input-background-color) !important;
  border: 2px solid transparent;
  box-sizing: border-box;
  color: var(--text-color);
  display: flex;
  font-size: var(--font-size-s);
  font-weight: var(--font-weight-semibold);
  min-height: var(--input-height);
  padding-bottom: 0 !important;
  padding-left: var(--element-side-gap) !important;
  padding-right: var(--element-side-gap);
  position: relative;
  transition: var(--default-transition);

  &.disabled,
  &:disabled {
    border: 2px solid var(--input-background-color) !important;
    background: var(--white) !important;
    color: var(--disabled-input-text-color) !important;
    opacity: 1 !important;
  }
}

select,
textarea {
  &.disabled,
  &:disabled {
    border: 2px solid var(--input-background-color);
    background: var(--white) !important;
    color: var(--disabled-input-text-color) !important;
    opacity: 1 !important;
  }
}

select {
  option {
    padding: 0;
  }
}

.multiselect {
  background: var(--input-background-color);
  position: relative;

  label {
    align-items: flex-start;
    display: flex;
    width: 100%;
  }

  &__item {
    padding-bottom: 0.4rem;
    padding-left: var(--element-side-gap);
    padding-right: var(--element-side-gap);
    padding-top:  0.4rem;
  }

  &__select-all {
    border-bottom: 1px solid var(--disabled-text-color);
    margin-bottom: 0.5rem;
    padding-bottom: 0.5rem;
  }

  &__options {
    font-size: var(--font-size-s);
    font-weight: var(--font-weight-semibold);
    min-width: 100%;
  }

  &__checkbox {
    margin-right: 0.8rem !important;
  }
}

#modal-container .selectize-dropdown {
  min-width: auto;
}

.multiselect__options-wrapper,
.selectize-dropdown {
  @include box-shadow;
  border: none;
  background: var(--input-background-color);
  font-size: var(--font-size-s) !important;
  font-weight: var(--font-weight-semibold) !important;
  min-width: 100%;
  padding-bottom: 0.4rem;
  padding-right: 0.4rem;
  padding-top: 0.4rem;
}

.selectize-control {
  min-width: 100%;
}

.form-section-field-title {
  box-sizing: border-box;
  color: var(--text-color-light);
  font-size: var(--font-size-s);
  text-align: right;
  padding-right: 1.2rem;
}

.section-header {
  box-sizing: border-box;
  margin: 0 !important;
  margin-bottom: 2rem !important;

  &--upper-margin {
    margin-top: 4rem !important;
  }

  &--danger {
    color: var(--danger-color);
  }
}

.dropdown-menu {
  background: var(--input-background-color);
  margin: 0 !important;
  padding: 0 !important;
  z-index: var(--z-index-dropdowns);

  li {
    padding: 0;

    & > a {
      display: block;
      color: var(--text-color);
      clear: both;
      font-size: var(--font-size-s);
      font-weight: var(--font-weight-semibold);
      padding-bottom: 0.5rem;
      padding-left: var(--small-gap);
      padding-right: var(--small-gap);
      padding-top: 0.5rem;
      white-space: nowrap;

      &:hover {
        background: var(--hover-background-color);
        color: var(--text-color);
      }
    }
  }
}

.table,
.form-section,
.form-section-field {
  margin-bottom: 4rem;

  textarea {
    width: var(--input-width-m);
    padding-top: 1rem;
    resize: none;
  }

  .horizontal,
  .transclude,
  input,
  account-select,
  select,
  password,
  cash,
  glue-container,
  gl-category-select,
  user-select,
  charge,
  field,
  telephone,
  multiselect,
  loan-industry-purpose,
  loan-mis-group,
  ent,
  .mis-tree,
  custom-select,
  textarea,
  .selectize-control,
  .selectize-input,
  .selectize-dropdown,
  .multiselect__options,
  date {
    box-sizing: border-box;
    max-width: var(--input-max-width);
    min-width: auto;
    width: 100%;
  }

  .alert {
    box-sizing: border-box;
    margin-bottom: 2rem !important;
    margin-top: 2rem !important;
    max-width: var(--input-max-width);

    &--no-margin {
      margin-bottom: 0 !important;
      margin-top: 0 !important;
    }
  }
}

.form-inline {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  font-size: var(--font-size-s);
  font-weight: var(--font-weight-semibold);
  justify-content: flex-start;

  button {
    margin-left: var(--element-side-gap);
  }
}

// Override external styles
.selectize-input {
  align-items: center;
  background: var(--input-background-color) !important;
  border: 0 !important;
  border-radius: var(--default-border-radius) !important;
  box-shadow: none !important;
  display: flex !important;
  flex-wrap: wrap;
  font-size: var(--font-size-s);
  min-height: var(--input-height);
  min-width: var(--input-width-s);
  padding-bottom: 0.8rem !important;
  padding-left: var(--element-side-gap) !important;
  padding-right: 3.6rem !important;
  padding-top: 0.8rem  !important;

  input {
    border: none !important;
    height: auto;
    left: 0;
    font-size: var(--font-size-s);
    min-width: 100% !important;
    padding-left: var(--element-side-gap) !important;
    position: absolute !important;
  }

  &.focus {
    outline: none !important;
    border-color: transparent !important;
    box-shadow: none !important;
  }
}

.selectize-control.multi {
  .selectize-input input {
    position: relative !important;
    padding-left: 0 !important;
  }
}

.selectize-control {
  .option {
    font-size: var(--font-size-s);
    font-weight: var(--font-weight-semibold) !important;
    padding-bottom: 0.4rem;
    padding-left: var(--element-side-gap);
    padding-right: var(--element-side-gap);
    padding-top: 0.4rem;
  }
}

.selectize-control.multi .selectize-input [data-value] {
  background: var(--success-color) !important;
  border: 0 !important;
  box-shadow: none !important;
  font-size: var(--font-size-xs);
}

.form-column {
  margin-right: 2rem;
  padding-bottom: 2rem;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 1rem;
}

.form-section {
  font-weight: var(--font-weight-semibold);

  &--header {
    margin-bottom: 6rem;
  }

  .tree {
    .root {
      margin: 0;
      list-style-type: none;

      & > li {
        padding-bottom: 2rem;
        font-size: var(--font-size-m);

        ul {
          font-size: var(--font-size-s);
        }
      }
    }

    .list-row {
      display: block;
      margin-bottom: 1rem;
    }

    .btn {
      margin-left: 1rem;
    }
  }
}

.telephone,
.mask-switch,
.input-append {
  align-items: center;
  display: flex;
}

.horizontal {
  glue-container {
    margin-right: 1rem;
  }

  glue-container:last-child {
    margin-right: 0;
  }
}

cash + help {
  display: block;
  margin-top: 1rem;

  .help-icon {
    margin-left: 0;
  }
}

.cash {
  position: relative;

  &__input {
    padding-right: 4rem;
  }

  &__label {
    align-items: center;
    display: flex;
    right: 0.3rem;
    color: var(--text-color-light);
    font-size: var(--font-size-s);
    font-weight: var(--font-weight-semibold);
    height: var(--input-height);
    position: absolute;
    top: 0;
  }
}
