areas .tree {
  background: white;
}
areas ul {
  list-style-type: circle;
}
areas ul.root {
  margin: 0;
}
areas ul .list-row {
  margin-bottom: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
areas ul .list-row.area {
  font-weight: var(--font-weight-semibold);
  text-transform: uppercase;
}
areas .unassigned li {
  font-size: var(--font-size-s);
  margin-bottom: 1rem;
}
