
selectable-list {
  @import "~@nextbank/bootstrap/less/variables";

  .selectable-list__header {
    color: var(--text-color-light);
    display: flex;
    font-weight: var(--font-weight-semibold);
    justify-content: space-between;

    input {
      margin: 0;
      margin-right: auto;
    }
  }

  table.table {
    tr.checked > td {
      background-color: @successBackground;
    }

    td {
      &.right {
        text-align: right;
      }

      &.center {
        text-align: center;
      }

      &.left {
        text-align: left;
      }

      &.text-center {
        text-align: center;
      }
    }
  }

  div[ng-table-pagination] {
    text-align: right;
  }
}