.top-bar {
  @include box-shadow;
  align-items: center;
  background: var(--white);
  box-sizing: border-box;
  display: flex;
  font-weight: var(--font-weight-semibold);
  height: var(--top-bar-height);
  position: fixed;
  width: 100%;
  z-index: var(--z-index-top-bar);
  top: 0;
  left: 0;

  &__logo {
    margin-right: auto;
    padding-left: var(--menu-side-padding);
  }

  &__refresh-icon {
    background: url(icons/icon-refresh.svg) no-repeat left;
    height: 3rem;
    width: 3rem;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 3rem 3rem;
  }

  &__user-image {
    background: url(icons/icon-profile.svg) no-repeat left;
    height: 3rem;
    width: 3rem;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 3rem 3rem;
    margin-left: 2rem;
  }

  &__info-details {
    color: var(--text-color-light);
  }

  &__info-section {
    align-items: center;
    display: flex;
  }

  &__info-container {
    display: flex;
    flex-direction: column;
    height: 3rem;
    padding-left: 1rem;
    padding-right: 1rem;

    a {
      margin-right: 0.4rem;
    }
  }

  i {
    color: var(--text-color-light);
    font-size: var(--font-size-l);
  }
}

