#internet-check {
  z-index: var(--z-index-always-on-top);
  //  position: fixed;
  text-align: center;
  width: 100%;

  a {
    color: var(--danger-color);
    cursor: pointer;
    font-weight: var(--font-weight-semibold);
  }
}
