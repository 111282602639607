approval-hierarchy .handler {
  cursor: grab;
}
approval-hierarchy .handler-icon {
  margin-right: 10px;
}
.approval-hierarchy-row td:first-child {
  width: 40px;
}
.approval-hierarchy-row td:nth-child(2) {
  width: 400px;
  padding-right: 100px;
}
.approval-hierarchy-row td:nth-child(3) {
  width: 400px;
  padding-right: 100px;
}
.approval-hierarchy-row multiselect {
  width: 100%;
}
.approval-hierarchy-row .selectize-control .selectize-input [data-value] .user__username {
  opacity: 0.6;
}
.approval-hierarchy-row .selectize-control .selectize-input [data-value] .user__full-name + .user__username {
  margin-left: 5px;
}
.approval-hierarchy-row .selectize-control .selectize-dropdown .user__username {
  font-size: var(--font-size-s);
  display: block;
  opacity: 0.6;
}
