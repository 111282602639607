pledge-deposit-deviation-interval-fee-board {
  /*aligh glue-container's input in center of td*/
}
pledge-deposit-deviation-interval-fee-board .table th,
pledge-deposit-deviation-interval-fee-board .table td {
  text-align: center;
}
pledge-deposit-deviation-interval-fee-board .table .fee {
  display: flex;
  justify-content: center;
}
pledge-deposit-deviation-interval-fee-board input,
pledge-deposit-deviation-interval-fee-board select {
  width: auto;
}
pledge-deposit-deviation-interval-fee-board div.input-append {
  justify-content: center;
}
pledge-deposit-deviation-interval-fee-board p.title {
  text-transform: uppercase;
  font-weight: var(--font-weight-bold);
  border-bottom: 1px solid lightgray;
}
pledge-deposit-deviation-interval-fee-board .form-section-field-title {
  width: auto;
}
pledge-deposit-deviation-interval-fee-board .custom-fee-board__default-rate {
  display: block;
  width: 300px;
}
