customer-merge-customers .inactive-row td {
  color: #b9b9b9;
}
customer-merge-customers .customer-merge__control-buttons {
  float: right;
}
customer-merge-customers .customer-merge__control-buttons.check-control-buttons {
  margin-bottom: 15px;
}
customer-merge-customers #merged-customers-switch {
  float: left;
}
customer-merge-customers #customers-merge-table input {
  margin: 0;
}
