customer-loan-release table {
  margin-top: 10px;
}
customer-loan-release table input {
  margin: 0;
}
customer-loan-release table .micr {
  width: 25%;
}
customer-loan-release table .selectize-control.single {
  max-width: 25%;
}
customer-loan-release .remarks {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  max-width: 450px;
}
customer-loan-release .remarks .caption {
  font-weight: var(--font-weight-bold);
  margin-right: 20px;
}
customer-loan-release .remarks input {
  width: 100%;
  margin: 0;
}
customer-loan-release .restructured-loans input {
  width: 100%;
  display: block;
  margin-bottom: 0.5rem;
}
