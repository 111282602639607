customer-credit-line {
  .remove-padding {
    padding: 0 !important;
  }

  .key {
    font-weight: var(--font-weight-semibold);
  }

  .right-details {
    flex-basis: 220px;
  }
}