agent-activation .profile-body hr {
  margin: 0;
  border: none;
  border-top: solid 1px lightgray;
}
agent-activation .profile-body form {
  margin-bottom: 0;
  padding-top: 0;
}
agent-activation .user-details .buttons {
  padding-right: 20px;
}
