gl-transaction-list .transaction-table-container {
  .transaction-list-table {
    margin-bottom: 0;

    > th, > td {
      vertical-align: middle;
    }

    tbody {
      background-color: white;
    }

    .transaction-item {
      height: 50px; // to prevent table from scrolling when changing pages
      &:hover {
        color: #555;
        text-decoration: none;
        background-color: #f8f8fa;
      }

      &.active-transaction {
        background-color: #f2f2f2;
      }

      td.details {
        padding: 0;
      }

      td[status=REJECTED] {
        color: var(--danger-color);
      }

      td[status=PENDING] {
        color: orange;
      }

      td[status=PROCESSED] {
        color: var(--success-color);
      }

      td[transaction-type=MANUAL] {
        color: orange;
      }

      td[transaction-type=OPERATION] {
        color: var(--success-color);
      }

      td[transaction-type=LEDGER_ACTION] {
        color: var(--success-color);
      }

      td.posting-date {
        text-align: left;
      }
    }
  }
}
