.transaction-create-form {
  align-items: stretch;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: space-between;
  margin: 0;
  padding-bottom: 30px;

  &-table {
    table-layout: fixed;
    tbody {
      td {
        &:first-child {
          box-sizing: border-box;
          width: 10%;
        }
        &:nth-child(2) {
          width: 10%;
        }
        &:last-child {
          align-self: flex-start;
          width: 80%
        }
      }
    }

    tfoot {
      td {
        &:last-child {
          display: flex;
          align-items: stretch;
          justify-content: flex-end;

          > * {
            &:extend(.summary-actions-child);
          }
        }
        button {
          font-size: var(--font-size-s);
          margin-left: var(--element-side-gap);
          min-height: var(--big-button-height) !important;
        }
      }
    }
  }
}
