dynamic-list {
  @import '~@nextbank/bootstrap/less/variables';

  .dynamic-list {
    &__table {

      td {
        vertical-align: middle;
      }
    }

    &__table-error thead {
      background-color: @errorBackground;
      border: 1px solid @errorBorder;
    }

    &__row {
      background-color: white;
    }

    &__column-edit {
      box-sizing: border-box;
      width: 100px;
    }
  }
}