menu {
  margin: 0;
}

a {
  transition: color .2s ease;
}

button, button.btn {
  // override the blue outline on button focus:
  // http://stackoverflow.com/questions/13644262/bootstrap-2-2-1-modal-bug-blue-border-around-calling-href
  outline: none !important;
}

.bottom-bar {
  height: 40px;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  border-top: solid 1px lightgray;
  padding: 10px 0 10px 20px;
  background-color: #f1f1f1;
  z-index: var(--z-index-always-on-top);

  display: none;
}

.container-fluid {
  padding-left: 0;
  padding-right: 0;
  height: 100%;
}

// pointer cursor on nav bars
.tab-menu ul.nav > li > a {
  cursor: pointer;
}

.tab-content {
  padding: 0;
  overflow: inherit;
}

.sub-menu ul.nav-tabs {
  border: none;
  margin-bottom: 0;
}

/* stretch menu to 100% width */
.sub-menu ul.nav-tabs {
  display: table;
  width: 100%;
  table-layout: fixed;
}

.sub-menu ul.nav-tabs li {
  width: 33.333333%; /* 100 / number of elements */
  margin-bottom:0;
}

/* /stretch */
.sub-menu ul.nav-tabs a {
  text-align: center;
  color: var(--primary-color);
  background: #f7f7f8;
  padding-top: 20px;
  padding-bottom: 20px;
  margin-right: 0px;
  border-radius: 0;
  outline: none;
  font-size: 22px;
  border-left: none;
  border-right: none;
  border-top: solid 1px lightgray;
  border-bottom: solid 1px lightgray;
}

.sub-menu ul.nav-tabs a:hover, .sub-menu ul.nav-tabs a:focus {
  background: #fbfbfc;
  border-left: none;
  border-right: none;
  border-top: solid 1px lightgray;
  border-bottom: solid 1px lightgray;
}

.sub-menu ul.nav-tabs li.active a {
  color: #f5f5f5;
  background: var(--primary-color);
  margin-bottom:0;
}

/** TABLE **/
.modal-inner {
  margin-top: 50px;
  padding: 50px 50px 20px 50px;
  border: solid 1px darkgray;
  background: white;
  display: inline-block;
  max-height: 80%;
  overflow-y: auto;
}

.modal-maximized {
  padding:0;
  margin: 20px;
  height: 90%;
  overflow-y: auto;
}

.btn-selected {
  color: #ffffff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
  background-color: var(--primary-color); /*var(--primary-color)*/
  *background-color: #23976b;
  background-image: -moz-linear-gradient(top, var(--primary-color), #23976b);
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(var(--primary-color)), to(#23976b));
  background-image: -webkit-linear-gradient(top, var(--primary-color), #23976b);
  background-image: -o-linear-gradient(top, var(--primary-color), #23976b);
  background-image: linear-gradient(to bottom, var(--primary-color), #23976b);
  background-repeat: repeat-x;
  border-color: #23976b #23976b #23895e;
  border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
}

.btn-selected:hover,
.btn-selected:focus,
.btn-selected:active,
.btn-selected.active,
.btn-selected.disabled,
.btn-selected[disabled] {
  color: #ffffff;
  background-color: #23976b;
  *background-color: #23976b;
}

.btn-selected:active,
.btn-selected.active {
  background-color: #23895e                   \9;
}

.btn-group {
  a {
    cursor: default;
  }
}

.core-modal {
  .bottom-buttons {
    margin-top: 10px;
  }
}

.hidden-element {
  border:0 !important;
  width:0;
  height:0;
  margin:0;
  padding:0;
  outline: none;
  opacity: 0 !important;
}

h2.white-header {
  background: white;
  padding-left: 20px;
  padding-bottom: 10px;
  padding-top: 10px;
  margin: 0;
  border-top: none;
  border-bottom: 1px solid lightgray;
}

/*
 Div with "Operations:" label and action buttons
*/
.operations {
  background: white;
  border: 1px solid lightgray;
  margin-top: 20px;
  padding: 10px;

  span {
    padding-right: 5px;
    font-weight: var(--font-weight-semibold);
  }
}

.green {
  color: var(--success-color);
}

.red {
  color: var(--danger-color);
}

.blue {
  color: var(--primary-color);
}

.orange {
  color: orange;
}

.grey {
  color: grey;
}

input.ng-invalid, select.ng-invalid, textarea.ng-invalid, cash.ng-invalid input,
date.ng-invalid input, user-select.ng-invalid select, gl-category-select.ng-invalid select,
input.nx-invalid, select.nx-invalid, textarea.nx-invalid, cash.nx-invalid input,
date.nx-invalid input, user-select.nx-invalid select, gl-category-select.nx-invalid select {
  border-color: transparent;
  border: 2px solid var(--danger-color);
  border-radius: var(--default-border-radius);
}

form.ng-submitted, [ng-form].ng-submitted {
  input.ng-invalid, select.ng-invalid, textarea.ng-invalid, cash.ng-invalid input,
  date.ng-invalid input, user-select.ng-invalid select, gl-category-select.ng-invalid select,
  input.nx-invalid, select.nx-invalid, textarea.nx-invalid, cash.nx-invalid input,
  date.nx-invalid input, user-select.nx-invalid select, gl-category-select.nx-invalid select {
    &:extend(input.ng-invalid);
  }
}

file-upload.ng-invalid.ng-invalid-required #file-upload,
file-id-upload.ng-invalid #file-upload {
  .file-upload-new__drop-zone,
  .file-upload__image {
    &:extend(.bootstrap-legacy-styles input.ng-invalid);
  }
}

// spinner animation
.spinner {
  font-size: 20px;
  animation-name: spin;
  animation-duration: 4000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.bold {
  font-weight: var(--font-weight-semibold);
}

menu button[disabled] {
  cursor: not-allowed;
  opacity: 0.5;
}

.CodeMirror, .CodeMirror-hints {
  font-size: var(--font-size-m);
}

/*
  Primarily used for buttons at the bottom of the page.
 */
.flex-end {
  display: flex;
  justify-content: flex-end;
}

.flex-start {
  display: flex;
  justify-content: flex-start;
}

.flex-container {
  display: flex;
}

.hr-thin-margin {
  margin-top: 12px;
  margin-bottom: 12px;
}

.summary-actions-child {
  margin: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.summary-actions {
  display: flex;
  align-items: stretch;
  justify-content: flex-end;

  > * {
    &:extend(.summary-actions-child);
  }
}

.table__actions {
  &:extend(.summary-actions);

  > * {
    &:extend(.summary-actions-child);
  }
}

@media print {
  .modal {
    position: absolute;
    left: 0;
    right: 0;
    width: 80%;
    margin: 0 auto;
  }
}