.border() {
  border-bottom: 0.03rem solid rgba(0, 0, 0, 0.1);
}

.label() {
  color: var(--text-color-light);
  font-size: var(--font-size-xs);
  font-weight: 600;
  text-transform: uppercase;
}

@cs-colors: poor, bad, average, good, excellent, outstanding;
