@import '~@nextbank/bootstrap/less/variables';

health-check-list {
  padding-top: 2rem;
  padding-bottom: 2rem;

  h2 {
    align-items: center;
    display: flex;

    .status--label {
      margin-left: 2rem;
    }
  }

  .hc-item {
    border-bottom: 1px solid var(--light-line-color);
    margin-top: 3rem;

    &.regular {
      padding: 0;

      &.fail, &.ok {
        border-width: 0;
      }
    }

    &-wrapper {
      margin-bottom: 2rem;
      margin-left: 0;
      margin-right: 0;
      margin-top: 2rem;

      .status {
        &.fail {
          color: var(--danger-color);
        }

        &.ok {
          color: var(--success-color);
        }
      }
    }

    &-table {
      color: var(--text-color-light);
      width: 100%;
      table-layout: fixed;
      text-align: left;
      border-collapse: collapse;
      background-color: @white;

      tr {
        &:not(:last-child) {
          border-bottom: 1px solid var(--light-line-color);
        }
      }

      td, th {
        padding: 10px;
      }
    }
  }
}
