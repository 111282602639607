pledge-deposit-deviation-interval-fee-board {

  .table th, .table td {
    text-align: center;
  }

  .table .fee {
    display: flex;
    justify-content: center;
  }

  input, select {
    width: auto;
  }

  /*aligh glue-container's input in center of td*/

  div.input-append {
    justify-content: center;
  }

  p.title {
    text-transform: uppercase;
    font-weight: var(--font-weight-bold);
    border-bottom: 1px solid lightgray;
  }

  .form-section-field-title {
    width: auto;
  }

  .custom-fee-board__default-rate {
    display: block;
    width: 300px;
  }

}
