grouped-role-write-access-list {
  display: block;
}
grouped-role-write-access-list .btn-group.block {
  display: block;
}
grouped-role-write-access-list .rule td:first-child {
  width: 20%;
  word-break: break-word;
}
grouped-role-write-access-list .rule td:nth-child(3) {
  width: 15%;
}
grouped-role-write-access-list .rule td:last-child {
  width: 10%;
}
grouped-role-write-access-list .predicates {
  display: flex;
}
grouped-role-write-access-list .predicates .predicate {
  max-width: 20rem;
}
grouped-role-write-access-list .flags input[type=checkbox] {
  margin: 5px;
}
grouped-role-write-access-list .role-write-access_buttons {
  align-items: center;
  display: flex;
}
grouped-role-write-access-list .btn-group {
  height: fit-content;
  margin: 5px;
}
