double-select-condition {
  display: block;
  white-space: nowrap;
}
double-select-condition .condition {
  display: flex;
  align-items: center;
  width: fit-content;
}
double-select-condition .condition glue-container {
  margin-left: 10px;
}
double-select-condition .condition glue-container input {
  max-width: 100px;
}
double-select-condition .condition-container {
  display: flex;
  flex-direction: column;
}
double-select-condition .condition-container multiselect {
  margin-top: 5px;
}
