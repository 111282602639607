#loan-account-information {
  .table {
    td.values {

      .mis-tree {
        display: flex;
        align-items: flex-start;
        flex-wrap: wrap;

        thead {
          display: none;
        }

        tr.level-1 td {
          border: none;
        }

        td:last-child {
          display: none;
        }
      }
    }
  }
}