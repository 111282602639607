@import 'src/style/mixins';

.container--with-mini-menu {
  display: flex;
}

.mini-menu {
  background: var(--white);
  box-sizing: border-box;
  margin-left: 4rem;
  flex-basis: 370px;
  flex-shrink: 0;
  flex-grow: 1;
  padding-left: var(--menu-side-padding);
  padding-right: var(--menu-side-padding);

  .operation-row {
    display: flex;
    flex-wrap: wrap;

    > * {
      margin: var(--small-gap) !important;
    }

    .btn,
    .btn-group,
    button {
      font-size: var(--font-size-s) !important;
      min-width: 15rem;
      min-height: 3.6rem;
    }

    .btn,
    button {
      padding-left: 1rem;
      padding-right: 1rem;
      justify-content: flex-start;
    }

    .btn-group {
      button {
        margin: 0;
      }
    }
  }
}