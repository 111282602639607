gl-transaction-create {
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 10px);
}
@media print {
  gl-transaction-create breadcrumb,
  gl-transaction-create gl-header,
  gl-transaction-create .transaction-create-form {
    display: none;
  }
}
gl-transaction-create .cursor-default {
  cursor: default;
}
gl-transaction-create .transaction-create-type-container {
  align-items: center;
  padding: 20px 0 20px 40px;
}
gl-transaction-create .transaction-create-type-label {
  font-weight: var(--font-weight-semibold);
  margin: 0 8px 0 0;
}
gl-transaction-create .transaction-create-type-select {
  margin: 0;
}
gl-transaction-create .transaction-create-date-container {
  align-items: center;
  padding: 20px 0 20px 40px;
}
gl-transaction-create .transaction-create-date-label {
  font-weight: var(--font-weight-semibold);
  margin: 0 8px 0 0;
}
gl-transaction-create .transaction-create-date-select {
  margin: 0;
}
gl-transaction-create .transaction-create-table input {
  margin: 0;
}
gl-transaction-create .transaction-create-table .no-column-header {
  width: 40px;
}
gl-transaction-create .transaction-create-footer td {
  outline: none;
}
gl-transaction-create .transaction-create-footer .transaction-remarks-column {
  padding-right: 0;
}
gl-transaction-create .transaction-create-footer .total-label {
  font-weight: var(--font-weight-semibold);
  padding-right: 8px;
  margin: 0;
}
gl-transaction-create .transaction-create-submit-buttons {
  align-self: flex-end;
}
