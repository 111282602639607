#config_users_div button {
  margin-left: 0;
}

#config_users_div .modal-inner {
  padding: 30px 50px 20px 50px;
}

#config_users_div #ca_btn_new_user {
  margin-left: 20px;
}

.users-list-table {
  td, th {
    padding-left: 0;
  }
  .users-list__number-column {
    width: 40px;
  }
  .active {
    color: var(--success-color);
  }
  .blocked {
    color: var(--danger-color);
  }
}

.user-details {
  .buttons {
    padding-right: 40px;
    text-align: right;
  }

  #expired-err {
    color: var(--danger-color);
    font-weight: var(--font-weight-semibold);
  }

.user-audit-table {
    padding-top: 20px;
  }
}
