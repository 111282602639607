@mixin hoverable-row {
  position: relative;

  &::before {
    background: var(--primary-color);
    border-bottom-right-radius: 0.1rem;
    border-top-right-radius: 0.1rem;
    content: '';
    height: 0;
    left: 0;
    opacity: 0;
    position: absolute;
    top: 20%;
    transition: var(--default-transition);
    width: 0.3rem;
  }

  &:hover {
    @include hovered-row-styles;
  }
}

@mixin hovered-row-styles {
  &::before {
    opacity: 1;
    height: 60%;
  }
}

@mixin box-shadow {
  box-shadow: 0 0 0.4rem 0.1rem var(--box-shadow-color);
}