customer-loan-release {


    table {
      margin-top: 10px;

      input {
        margin: 0;
      }

      .micr {
        width: 25%;
      }

      .selectize-control.single {
        max-width: 25%;
      }

    }

    .remarks {
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      max-width: 450px;

      .caption {
        font-weight: var(--font-weight-bold);
        margin-right: 20px;
      }

      input {
        width: 100%;
        margin: 0;
      }
    }

  .restructured-loans input {
    width: 100%;
    display: block;
    margin-bottom: 0.5rem;
  }

}