@arrow-width: 6px;
@separator-color: rgba(153, 153, 153, 0.35);
@breadcrumb-background-color: rgb(255, 255, 255);
@breadcrumb-hover-color: var(--primary-color);

breadcrumb {
  align-items: stretch;
  display: flex;

  .breadcrumb-icon {
    align-items: center;
    cursor: pointer;
    display: flex;
    justify-content: center;

    > * {
      font-size: var(--font-size-xs);
    }

    svg {
      height: 16px;
      width: 16px;
    }
  }

  .breadcrumb {
    align-items: center;
    background: none;
    display: flex;
    flex-grow: 1;
    margin-bottom: 2rem;
    margin-left: 0;

    .breadcrumb-item {
      text-transform: uppercase;

      .separator {
        padding-left: 5px;
      }

      .trim {
        display: inline-block;
        max-width: 550px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        padding-top: 5px;
      }
    }
  }

  .popover {
    max-width: 400px;
  }

  .popover.left {
    // initially the middle of popover is aligned with the middle of info text -
    // we need to move it 50% downwards, then the top of popover is aligned with the middle of info text
    // so we additionally need to move popover by the half of font size,
    // the width of an arrow, plus 1px for better visual appearance

    // x is simpler as we only need to get rid of the default popover right arrow
    // and add 4x letters' width to match popover right border with info text right border
    transform: translateY(calc(~"50% + 0.5em + @{arrow-width} + 1px")) translateX(calc(~"@{arrow-width} + 4ch")) ;
  }
}

.breadcrumb__info {
  display: flex;
  margin: 0;

  .error {
    color: var(--danger-color);
  }
}

.breadcrumb__logout-section, .breadcrumb__home-section {
  box-sizing: border-box;
  cursor: pointer;
  width: 60px;
  height: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.breadcrumb__command-section {
  border-left: 1px solid lightgray;
  border-right: 1px solid lightgray;
  padding: 0 10px;
  cursor: default;
  max-width: 300px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .cmd-label {
    font-weight: var(--font-weight-semibold);
  }

  i {
    cursor: pointer;
    margin-left: 12px;
  }
}

i.breadcrumb__logout, i.breadcrumb__home {
  transition: .2s ease;

  &:hover {
    color: @breadcrumb-hover-color;
  }
}

.breadcrumb__info-details {
  line-height: 20px;
  color: #999;
}


.breadcrumb__info-details--more {
  position: relative;
}

.breadcrumb__info-details--more-container {
  position: relative;
}

.breadcrumb__more-popup-container {
  font-weight: var(--font-weight-normal);

  .breadcrumb__more-popup {
    &-item {
      align-items: center;
      display: flex;
      padding: 5px 0;
    }

    &-icon {
      height: 20px;
      margin-right: 5px;
      width: 20px;
    }

    &-label {
      padding-right: 5px;
      flex-shrink: 0;
    }

    &-value {
      flex-shrink: 0;
    }
  }

}

a.breadcrumb__info-details {
  cursor: pointer;
  text-decoration: underline;

  &:focus {
    color: @breadcrumb-hover-color;
    outline: none;
  }
}

.breadcrumb__home {
  &:hover {
    transition: color 0.2s ease;
    color: @blue;
  }
}

.breadcrumb__info-container {
  display: flex;
  flex-direction: column;
  padding: 0 10px;
}
