customer-loan-create-lai-details table {
  margin-bottom: 0;
}
customer-loan-create-lai-details table select,
customer-loan-create-lai-details table input,
customer-loan-create-lai-details table textarea {
  margin-bottom: 0;
}
customer-loan-create-lai-details table td.label {
  width: 280px;
}
customer-loan-create-lai-details table td.values {
  display: flex;
  align-items: center;
  max-width: var(--input-max-width);
}
