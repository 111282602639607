deposit-manual-rollover .range-input {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
deposit-manual-rollover .range-input .min-max {
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: 150px;
}
deposit-manual-rollover .range-input .value {
  flex-basis: 150px;
  flex-shrink: 0;
  flex-grow: 2;
  margin: 0 5px;
}
deposit-manual-rollover .input-prepend.input-append .add-on:last-child {
  margin-left: 0.4rem;
}
deposit-manual-rollover .misc-transaction__table td:last-child,
deposit-manual-rollover .misc-transaction__table th:last-child {
  width: 25%;
  padding-right: 2%;
}
deposit-manual-rollover .help-container {
  align-items: center;
  display: flex;
}
