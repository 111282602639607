customer-account-details-print td:first-child {
  font-weight: var(--font-weight-semibold);
}
customer-account-details-print td.separator {
  background: transparent!important;
}
@media print {
  customer-account-details-print {
    font-size: var(--font-size-xl);
  }
  customer-account-details-print print-header {
    display: block;
    margin-bottom: 12px;
  }
  customer-account-details-print print-header td {
    padding: 12px 0;
  }
  customer-account-details-print .no-print {
    display: none;
  }
}
