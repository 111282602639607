gl-transaction-create {
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 10px);

  @media print  {
    breadcrumb, gl-header, .transaction-create-form {
      display: none;
    }
  }

  .cursor-default {
    cursor: default;
  }

  .transaction-create-type {

    &-container {
      align-items: center;
      padding: 20px 0 20px 40px;
    }

    &-label {
      font-weight: var(--font-weight-semibold);
      margin: 0 8px 0 0;
    }

    &-select {
      margin: 0;
    }

  }

  .transaction-create-date {

    &-container {
      align-items: center;
      padding: 20px 0 20px 40px;
    }

    &-label {
      font-weight: var(--font-weight-semibold);
      margin: 0 8px 0 0;
    }

    &-select {
      margin: 0;
    }

  }

  .transaction-create-table {
    input {
      margin: 0;
    }

    .no-column-header {
      width: 40px;
    }
  }

  .transaction-create-footer {
    td {
      outline: none;
    }

    .transaction-remarks-column {
      padding-right: 0;
    }

    .total-label {
      font-weight: var(--font-weight-semibold);
      padding-right: 8px;
      margin: 0;
    }
  }

  .transaction-create-submit-buttons {
    align-self: flex-end;
  }
}
