tooltip {
  position: relative;
}
tooltip:hover tooltip-body {
  display: block;
  opacity: 1;
  transform: translate(-50%, 5px);
  visibility: visible;
}
tooltip-body {
  background-color: white;
  border: 1px solid var(--light-line-color);
  box-shadow: 0 6px 16px rgba(0, 0, 0, 0.2);
  box-sizing: border-box;
  color: var(--text-color);
  opacity: 0;
  padding: 20px 30px;
  position: absolute;
  top: 100%;
  left: 50%;
  transition: 0.2s ease-in-out;
  transform: translate(-50%, 0);
  visibility: hidden;
  white-space: nowrap;
  z-index: var(--z-index-dropdowns);
}
tooltip-body::after,
tooltip-body::before {
  content: '';
  display: block;
  position: absolute;
  left: 50%;
  top: 0;
  transform: translateX(-50%);
  width: 0;
  height: 0;
  border-style: solid;
}
tooltip-body::after {
  top: -18px;
  border-color: transparent transparent white transparent;
  border-width: 10px;
}
tooltip-body::before {
  top: -22px;
  border-color: transparent transparent var(--light-line-color) transparent;
  border-width: 11px;
}
