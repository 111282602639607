dynamic-report-mapping-manual {
  display: block;

  manual table {
    display: block;
    overflow: auto;

    tbody {
      width: 100%;
    }

    td:nth-child(1) {
      font-weight: var(--font-weight-semibold);
    }


    td.manual__label-column {
      width: 20%;
    }

    td.manual__value-column {
      width: 80%;
    }
  }
}
