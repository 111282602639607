compliance-list-view .compliance-list__table {
  box-sizing: border-box;
  margin: 20px;
  width: calc(100% - 40px);
}
compliance-list-view .compliance-list__table td:first-child,
compliance-list-view .compliance-list__table th:first-child {
  width: 30px;
}
compliance-list-view .compliance-list__table td:last-child,
compliance-list-view .compliance-list__table th:last-child {
  width: 80px;
}
compliance-list-view .compliance-list__open-button {
  width: 80px;
  text-transform: uppercase;
}
