@right-width: 355px;
@right-height: 135px;

// TODO: create a class replacing the 4 ids below

#customer-deposit-accounts, #customer-term-deposits, #customer-loans, #customer-credit-line,
  .products {

    .text-alert {
      border-color: #eed3d7;
      color: var(--danger-color);
      font-weight: var(--font-weight-semibold);
    }

    .accounts {
      display: flex;
      margin-top: 10px;

      .right-details {
        .tab-content {
          .tab-pane {
            width: @right-width;
            min-height: @right-height;
          }

          #operations {
            flex-wrap: wrap;

            .message {
              text-align: center;
              margin-top: 35px;
              height: 100%;
              color: var(--danger-color);
            }
          }

          #owners {
            a.link {
              cursor: pointer;
            }

            .signature {
              padding-left: 5px;
            }

            td {
              text-align: center;
            }

          }

          .details-pane {
            padding-top: 10px;
            .signature {
              padding-left: 5px;
            }

            td {
              text-align: center;
            }

          }

          .operation-row {
            flex-wrap: wrap;

            .btn-group+.btn-group {
              margin-left: 0;
            }

            button {
              margin: 2px 0 2px 2px;
              width: 80px;
              height: 60px;
              font-weight: var(--font-weight-semibold);
              font-size: var(--font-size-s);
              line-height: 120%;
              padding: 0;
            }
          }

          .details-pane {
            padding-top: 10px;

            .detail-rows {

              .info-detail {

                a {
                  cursor: pointer;
                }

                .key {
                  font-weight: var(--font-weight-semibold);
                  margin-right: 5px;
                }
                .value {
                  &[data-status=ACTIVE] {
                    color: var(--success-color);
                    font-weight: var(--font-weight-semibold);
                  }
                  &[data-status=CLOSED] {
                    color: var(--danger-color);
                    font-weight: var(--font-weight-semibold);
                  }
                }
              }
            }
          }
      }

      .table {
        .currency {
          &--positive {
            color: var(--success-color);
          }

          &--negative {
            color: var(--danger-color);
          }
        }
      }

    }

    .history {
      table {
        margin-bottom: 0;

        td.type-CREDIT,
        td.status-PROCESSED {
          color: var(--success-color);
        }
        td.status-PENDING {
          color: orange;
        }

        td.type-DEBIT,
        td.status-REJECTED,
        td.status-ERROR {
          color: var(--danger-color);
        }
      }
    }

    .passbook {

      .passbook-bar {
        display: flex;
        padding: 8px;
        align-items: center;
        font-weight: var(--font-weight-semibold);

        .title {
          padding-right: 5px;
        }

        .right {
          display: flex;
          margin-left: auto;
        }
      }

      table {

        th input {
          margin-bottom: 0;
          width: 60px;
        }

        td.printed-true {
          color: var(--success-color);
        }
        td.printed-false {
          color: orange;
        }
        td.text-center {
          text-align: center;
        }

      }
    }

    .products-menu {
      margin-top: 20px;
    }
  }
}
