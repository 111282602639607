resolve-processing-issues {
  .grid {
    align-items: stretch;
    display: grid;
    grid-template-columns: 30px 100px 100px 100px 200px 100px minmax(200px, 1fr) 150px;

    &-header {
      display: contents;
      font-weight: var(--font-weight-semibold);
    }

    &-row {
      display: contents;

      &:hover > * {
        background-color: var(--hover-background-color) !important;
        transition: 0.2s ease;
      }

      &:not(.grid-header) > * {
        background: white;
      }

      & > * {
        align-items: center;
        box-sizing: border-box;
        display: flex;
        overflow: hidden;
        padding: 5px;
        text-overflow: ellipsis;
        word-break: break-all;
      }
    }
  }
}