agent-customer-profile-body .profile-body .tab-menu ul.nav {
  margin-bottom: 0;
}
agent-customer-profile-body .profile-body .tab-content .form-header {
  color: darkgray;
  text-align: right;
  border-bottom: solid 1px lightgray;
  margin: 20px 20px 0 20px;
  padding-right: 10px;
}
agent-customer-profile-body .profile-body hr {
  margin: 0;
  border: none;
  border-top: solid 1px lightgray;
}
agent-customer-profile-body .profile-body form {
  margin-bottom: 0;
  padding-top: 0;
}
agent-customer-profile-body #cpr_tab_documents .doc-form {
  min-height: 200px;
}
agent-customer-profile-body #cpr_tab_income hr {
  margin-top: 2px;
  margin-bottom: 30px;
}
agent-customer-profile-body #cpr_tab_income .template {
  display: none;
}
agent-customer-profile-body div[ng-transclude]:empty {
  display: none;
}
