.report-filters {
  .filter-value:not(.ng-invalid) {
    box-shadow: 0 0 6px rgba(0, 0, 0, 0);
    transition: box-shadow 0.5s ease-in-out;
  }

  .autofilled-with-default {
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.5);

  }

  .labelled-field.filter {
    white-space: nowrap;
  }
  .labelled-field.filter.day {
    min-width: fit-content;
  }

}