#file-upload .file-upload__list-item {
  align-items: center;
  display: flex;
  list-style: none;
}
#file-upload .file-upload__image {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 1.5rem;
  margin-top: 0;
  position: relative;
  text-align: center;
  transition: var(--default-transition);
  padding-bottom: 2rem;
  padding-top: 2rem;
}
#file-upload .file-upload__thumbnail {
  max-width: 90px;
  max-height: 100%;
}
#file-upload .file-upload__thumbnail--zoomed-in {
  cursor: zoom-out;
  max-height: none;
  max-width: none;
  z-index: var(--z-index-dropdowns);
}
#file-upload .file-upload__thumbnail--zoomed-out {
  cursor: zoom-in;
}
#file-upload .file-upload__thumbnail--download i {
  color: var(--text-color);
  cursor: pointer;
}
#file-upload .file-upload__caption {
  font-size: var(--font-size-s);
  margin-top: 0.5rem;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  overflow: hidden;
  text-align: center;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%;
}
#file-upload .file-upload__progressbar-value {
  background: var(--primary-color);
  height: 5px;
  max-width: 100%;
}
#file-upload .file-upload__input-container {
  display: flex;
  align-items: flex-end;
}
#file-upload .file-upload__input-container .btn {
  margin-left: var(--element-side-gap) !important;
}
#file-upload-new .file-upload-new__container {
  margin-bottom: 0;
}
#file-upload-new .file-upload-new__container .file-upload-new__drop-zone {
  align-items: center;
  border: 2px dashed var(--box-shadow-color);
  border-radius: var(--default-border-radius);
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  min-width: 100px;
  transition: var(--default-transition);
}
#file-upload-new .file-upload-new__container .file-upload-new__drop-zone.dragover {
  background: var(--input-background-color);
}
#file-upload-new .file-upload-new__webcam-container {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
#file-upload-webcam .modal {
  left: 15%;
  margin: 0;
  width: 70%;
}
#file-upload-webcam .file-upload-webcam__container {
  align-items: center;
  display: flex;
  flex-direction: column;
  max-height: 100vh;
}
#file-upload-webcam .file-upload-webcam__container video {
  min-height: 80vh;
  min-width: 80vw;
}
#file-upload-webcam .file-upload-webcam__button {
  position: absolute;
  bottom: 40px;
}
