#ata-relations {
  .ata-listing {
    margin-left: 0;
  }

  .ata-relation-boxes {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: space-around;

    .ata-relation-box {
      width: 100%;
      padding: 0 10px 10px;
    }
  }
}
