transactions-category-list {
  .category-list__header {
    .input-prepend {
      margin: 0;
    }

    align-items: center;
    display: flex;
    justify-content: space-between;
    padding: 15px 0;
  }

  .category-list__action {
    width: 100px;
  }
}