glue-container {
  .input-append--percentage,
  .input-append,
  .input-prepend {
    display: flex;
    align-content: flex-start;
    margin: 0;
    position: relative;

    .glue-prepended,
    .glue-appended {
      min-width: inherit;
      color: var(--text-color-light);
      font-weight: var(--font-weight-semibold);
      border-left: none;
      padding: 0;
    }

    .glue-appended {
      margin-left: 0.8rem;
    }

    .glue-prepended {
      margin-right: 0.8rem;
    }
  }

  .input-append--percentage {
    // inputs have a max width of 500, which causes percentage to appear outside of the box
    // to fight that, we need to limit partent input width to the same size
    max-width: 500px;

    &:before {
      align-items: center;
      content: "%";
      display: flex;
      right: 0.5rem;
      color: var(--text-color-light);
      font-size: var(--font-size-s);
      font-weight: var(--font-weight-semibold);
      height: var(--input-height);
      position: absolute;
    }
  }
}
