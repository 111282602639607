single-select-and-text-condition {
  display: block;
  white-space: nowrap;

  .condition {
    display: flex;
    align-items: center;
    width: fit-content;

    glue-container {
      margin-left: 10px;

      input {
        max-width: 100px;
      }
    }
  }

  .condition-container {
    display: flex;
    flex-direction: column;

    input {
      margin-top: 5px !important;
    }
  }
}