@import '~@nextbank/bootstrap/less/variables';

close-counters-health-check {
  .hc-item-cc {
    &-header, &-row {
      background-color: @white;
      border-bottom: 1px solid @grayLighter;

      &-wrapper {
        display: flex;
        flex-wrap: wrap;
        padding: 10px;

        > div {
          flex: 1px;

          &:first-child {
            max-width: 35px;
          }
        }
      }

      &-action {
        text-decoration: underline;
        transition: 0.2s ease;
      }

      &-action:hover {
        color: var(--primary-color);
        cursor: pointer;
      }

      &-table {
        border-top: 1px solid @grayLighter;
        padding: 10px;
        background: @grayLighter;
      }
    }
  }
}