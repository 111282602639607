holiday-table {
  table {
    th:last-child {
      text-align: right;
      padding-right: 35px;
    }
  }

  .holiday-list__ {
    &add-new-column {
      text-align: right !important;
    }

    &input {
      margin-bottom: 0;
      width: calc(100% - 5px);
    }
  }

  button {
    margin-right: 5px;
  }
}