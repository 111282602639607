customer-loan-charges-override {
  .alert-container {
    padding-top: 20px;
  }

  // move checkbox closer to the label
  .override-option, .override-checkbox, .show-zero-checkbox {
    display: flex;
  }

  .override-option {
    justify-content: space-between;
  }
}
