loan-interest-rate-board {

  .form-section {
    align-items: center;
    display: grid;
    grid-gap: 10px;
    grid-template-columns: minmax(min-content, 150px) minmax(300px, min-content);

    & > div {
      display: contents;
    }
  }
}
