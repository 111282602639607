customer-loans-transfer-payment {

  .account-select {
    margin-top: 10px;
  }

  @media print {
    .no-print {
      display: none;
    }
  }
}