@import 'src/style/mixins';

.steps-indicator {
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 100%;

  .steps {
    align-items: center;
    display: flex;
  }

  .step {
    align-items: center;
    color: var(--text-color-light);
    font-size: var(--font-size-l);
    font-weight: var(--font-weight-semibold);
    display: flex;
    height: 3rem;
    padding-right: 4rem;
    position: relative;
    transition: var(--default-transition);

    &.active {
      color: var(--text-color);
    }

    &::after {
      background: url(icons/icon-chevron-down.svg) no-repeat center center;
      background-size: 2rem 2rem;
      content: "";
      height: 3rem;
      position: absolute;
      right: 0.5rem;
      top: 0.1rem;
      transform: rotate(-90deg);
      transition: var(--default-transition);
      width: 3rem;
    }

    &:last-of-type {
      &::after {
        display: none;
      }
    }
  }
}
