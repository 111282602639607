
.container {
  margin-bottom: var(--small-gap);
  text-transform: uppercase;
  padding-bottom: 0.5rem;
  padding-top: 0.5rem;
}

.item {
  color: var(--text-color-light);
  font-size: var(--font-size-s);
  font-weight: var(--font-weight-semibold);
}