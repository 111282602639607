dynamic-report-mapping-manual {
  display: block;
}
dynamic-report-mapping-manual manual table {
  display: block;
  overflow: auto;
}
dynamic-report-mapping-manual manual table tbody {
  width: 100%;
}
dynamic-report-mapping-manual manual table td:nth-child(1) {
  font-weight: var(--font-weight-semibold);
}
dynamic-report-mapping-manual manual table td.manual__label-column {
  width: 20%;
}
dynamic-report-mapping-manual manual table td.manual__value-column {
  width: 80%;
}
