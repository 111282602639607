inline-panel {
  border-radius: var(--default-border-radius);
  display: block;
  font-weight: var(--font-weight-normal);

  .inline-panel-hide {
    background: #fff;
    border: 0;
    box-shadow: none;
    font-size: var(--font-size-l) !important;
    min-width: 4rem;
    position: absolute;
    right: 0;
    top: 1.5rem;
    width: 3rem;
  }

  .transclude {
    max-width: 100% !important;

    .btn {
      margin-bottom: 1rem;
      margin-top: 1rem;
    }
  }

  .inline-panel-wrapper {
    background-color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: var(--box-padding);
    position: relative;

    &--flex-row {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
    }
  }

  .inline-panel {
    border-bottom: 1px solid var(--light-line-color);

    &__list {
      opacity: 1;
      overflow: hidden;
      transition: max-height 0.3s ease;
    }

    &__list-loading {
      opacity: 0;
      max-height: 0;
    }

    &__container {
      align-items: center;
      display: flex;
      margin-bottom: 1rem;

      &--row {
        margin-bottom: 0;
        padding-right: var(--box-padding);
      }
    }

    &__label {
      color: var(--text-color-light);
      cursor: default;
      font-weight: var(--font-weight-semibold);
      margin: 0;
    }

    &__value {
      margin: 0;
      font-weight: var(--font-weight-semibold);
      padding-left: 1rem;
    }

    &__footer {
      display: flex;
      margin-top: 1rem;

      .btn {
        margin-right: 1rem;
      }
    }
  }
}
