create-cashiers-check .selectize-control {
  width: 400px;
}
create-cashiers-check .create-cashiers-check__units-table {
  table-layout: fixed;
}
create-cashiers-check .create-cashiers-check__units-table td:first-child,
create-cashiers-check .create-cashiers-check__units-table th:first-child {
  width: 30px;
}
create-cashiers-check .create-cashiers-check__units-table td:nth-child(2),
create-cashiers-check .create-cashiers-check__units-table th:nth-child(2) {
  width: 20%;
}
create-cashiers-check .create-cashiers-check__units-table td:nth-child(3),
create-cashiers-check .create-cashiers-check__units-table th:nth-child(3) {
  width: 40%;
}
create-cashiers-check .create-cashiers-check__units-table td:last-child,
create-cashiers-check .create-cashiers-check__units-table th:last-child {
  width: 100px;
}
create-cashiers-check .create-cashiers-check__units-table glue-container .add-on {
  box-sizing: border-box;
  height: 30px;
}
create-cashiers-check input[type="text"].add-account__remarks-input {
  margin: 0;
}
