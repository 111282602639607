prints-list .active {
  color: var(--success-color);
}
prints-list th:last-of-type {
  text-align: right;
}
prints-list .prints-list__actions {
  align-items: center;
  display: flex;
  justify-content: flex-end;
  margin: 0 -2.5px;
}
prints-list .prints-list__actions > * {
  margin: 0 2.5px;
}
