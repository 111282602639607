batch-pdc-payment .btn {
  min-width: 100px;
}
batch-pdc-payment .actions__buttons > :not(:last-child) {
  margin-right: 20px;
}
batch-pdc-payment .actions__label {
  display: inline;
}
batch-pdc-payment .actions__input {
  margin-bottom: 0 !important;
}
batch-pdc-payment .payment-result {
  margin: 20px 0;
}
batch-pdc-payment .payment-result__table-header {
  margin-bottom: 0;
}
batch-pdc-payment .modal {
  left: 0;
  right: 0;
  width: 50%;
  margin: 0 auto;
}
