@import "~@nextbank/ui-components/dist/variables";

.row {
  display: flex;
  gap: $gapM;
  align-items: center;
  padding: $gapXS 0;

  .select {
    min-width: 15rem;
  }
}