@import 'mixins';

.alert {
  @include box-shadow;
  align-items: center;
  background-color: var(--success-background-color);
  border: 1px solid transparent;
  border-radius: var(--default-border-radius);
  color: var(--success-color);
  display: flex;
  font-size: var(--font-size-s);
  font-weight: var(--font-weight-semibold);
  margin-left: var(--small-gap);
  margin-right: var(--small-gap);
  margin-top: var(--small-gap);
  padding-bottom: var(--small-gap);
  padding-left: var(--box-padding);
  padding-right: var(--box-padding);
  padding-top: var(--small-gap);
  text-align: left;

  &::before {
    background: url(icons/icon-alert-information.svg) no-repeat left;
    box-sizing: border-box;
    content: "";
    height: 2rem;
    min-width: 4rem;
    display: inline-block;
    width: 4rem;
  }

  &--danger {
    background: var(--danger-background-color);
    color: var(--danger-color);

    &::before {
      background: url(icons/icon-alert-warning.svg) no-repeat left;
    }
  }

  p {
    margin-bottom: 0;
  }

  .btn {
    margin-left: auto;
  }

  .close {
    margin-left: auto;
    top: 0;
    margin-right: var(--box-padding);
  }
}

.alerts {
  .alert {
    box-shadow: none;
    margin-left: 0;
    margin-right: 0;
    margin-top: 0;
  }
}

.modal-body {
  .alert {
    box-shadow: none;
    margin-bottom: var(--small-gap);
    margin-left: 0;
    margin-right: 0;
    margin-top: 3rem;
  }
}