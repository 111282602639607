batch-memo gl-category-select {
  width: 100%;
}
batch-memo .nav-tabs .disabled {
  color: darkgray;
}
batch-memo .tab-content {
  overflow: visible;
}
batch-memo .tab-content .buttons {
  width: 100%;
}
batch-memo .tag-type-input__label {
  align-items: center;
  display: flex;
}
