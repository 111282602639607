@import '~@nextbank/ui-components/dist/variables';

.target {
  display: grid;
  grid-template-columns: 50% 50%;
  width: 100%;

  .details {
    display: grid;
    grid-template-columns: repeat(2, 20rem) 30rem 20rem;
  }

  .label {
    font-weight: var(--font-weight-bold);
    font-size: var(--font-size-s);
    color: var(--text-color-light);
    cursor: default;
    padding-left: 1rem;
  }

  .value {
    font-size: var(--font-size-s);
  }

  .action {
    align-items: center;
    display: flex;
    justify-content: flex-end;
  }
}