product-list {
  .product-list {
    &__table {

      td {
        vertical-align: middle;
      }
    }

    &__row {
      background-color: white;
      border-top: 1px solid var(--light-line-color);
    }

    &__column-edit {
      box-sizing: border-box;
      width: 100px;
    }
  }
}