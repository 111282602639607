customer-loan-co-makers {
  table {

    th {
      border-top: none!important;
    }

    td.label {
      width: 280px;
    }

    td.values {
      display: flex;
      align-items: center;
    }
  }
}