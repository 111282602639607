customer-risk-profile-page {

  .category-name {
    max-width: 20em;
    word-wrap: break-word;
  }

  .right-field-title {
    margin-left: 5px;
  }

  .watchlist-button {
    margin-left: 10px;
  }
}
