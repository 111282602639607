transaction-history, loan-transaction-history {

  .horizontal {
    display: flex;
    padding: 5px;
  }

  .status-REVERTED, .status-REJECTED, .status-ERROR {
    color: var(--danger-color);
  }

  .status-PROCESSED {
    color: var(--success-color);
  }

  .status-PENDING {
    color: orange;
  }
}