@import '~@nextbank/ui-components/dist/variables';

.file-upload {
  max-width: $inputMaxWidth;
  width: 100%;
}

.box-container {
  display: flex !important;
  border: 2px dashed var(--box-shadow-color);
  border-radius: var($baseGap);
  box-sizing: border-box;
  font-size: var(--font-size-xs);
  min-height: $gapXL;
  justify-content: center;
  padding-left: $gapS;
  padding-right: $gapS;
  min-width: $gapXL;
  max-width: 26.5rem;
  width: 100%;
}

.thumbnail-inner {
  display: block;
  min-width: 0;
  overflow: hidden;
}

.thumbnail-image {
  display: block;
  max-width: 90px;
}

.preview-outer {
  max-height: 50vh;
  max-width: 80vw;
}

.zoomed-image {
  margin: 0 auto;
  width: auto;
  max-height: 50vh;
  max-width: 80vw;
}

