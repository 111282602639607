customer-risk-profile-page .category-name {
  max-width: 20em;
  word-wrap: break-word;
}
customer-risk-profile-page .right-field-title {
  margin-left: 5px;
}
customer-risk-profile-page .watchlist-button {
  margin-left: 10px;
}
