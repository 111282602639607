watchlist-details-view .alert {
  margin: 15px;
}
watchlist-details-view .alert__label {
  width: 140px;
  clear: both;
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
}
watchlist-details-view .alert__description {
  margin-left: 15px;
}
watchlist-details-view .file-upload {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-evenly;
  align-items: center;
}
watchlist-details-view form {
  margin: 0;
}
watchlist-details-view div.form-section {
  box-sizing: border-box;
  width: 500px;
  float: left;
  padding: 0 40px 0 20px;
}
watchlist-details-view .buttons {
  float: right;
  margin: 20px;
}
watchlist-details-view .watchlist-entry__table {
  box-sizing: border-box;
  margin: 20px;
  width: calc(100% - 40px);
}
watchlist-details-view .watchlist-entry__table input {
  margin: 0;
}
watchlist-details-view .watchlist-entry__table td input.watchlist-entry__name,
watchlist-details-view .watchlist-entry__table th input.watchlist-entry__name {
  text-transform: uppercase;
}
watchlist-details-view .watchlist-entry__table td:last-child,
watchlist-details-view .watchlist-entry__table th:last-child {
  width: 220px;
}
watchlist-details-view .watchlist-entry__button {
  width: 90px;
}
