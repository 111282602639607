.main-view credit-scoring-modal {
  @import './credit-scoring-modal.mixins.less';
  --poor-color: var(--danger-color);
  --poor-background-color: var(--danger-background-color);
  --bad-color: var(--bloody-color);
  --bad-background-color: var(--bloody-background-color);
  --average-color: var(--warning-color);
  --average-background-color: var(--warning-background-color);
  --excellent-color: var(--success-color);
  --excellent-background-color: var(--success-background-color);
  --outstanding-color: var(--primary-color);
  --outstanding-background-color: var(--hover-background-color);

  .border() {
    border-bottom: 0.03rem solid rgba(0, 0, 0, 0.1);
  }

  .modal {
    @modalWidth: 70%;
    background-color: var(--body-color);
    margin-left: -@modalWidth / 2;
    width: @modalWidth;

    .modal-header, .modal-body, .modal-footer {
      background: inherit;
      box-shadow: none;
    }

  }

  @media (max-width: 767px) {
    .modal {
      width: calc(100% - 40px);
      margin-left: 0;
    }
  }

  .grid {
    display: grid;
    grid-template-columns: minmax(min-content, 1fr) minmax(400px, 3fr);
    grid-column-gap: var(--box-padding);
    grid-row-gap: var(--box-padding);
    margin: var(--box-padding) 0;
  }

  cart {
    background: var(--white);
    display: block;
    padding: var(--box-padding);
    border-radius: var(--xsmall-gap);
  }

  .cart--large {
    margin: var(--box-padding) 0;

    &:last-child {
      margin-bottom: 0;
    }
  }

  cart-body {
    align-items: center;
    display: flex;
    flex-direction: column;
    width: 100%;

    & > * {
      margin-bottom: var(--small-gap);

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  cart-body-title {
    align-self: flex-start;
    font-size: var(--font-size-m);
  }

  cart-header {
    display: block;
    padding-bottom: var(--small-gap);
    margin-bottom: var(--small-gap);

    .border();
  }

  score-progress {
    display: flex;
    width: 100%;
    margin-bottom: 3rem;

    & > * {
      border-radius: var(--default-border-radius);
      flex-basis: 0;
      flex-grow: 1;
      font-size: var(--font-size-xs);
      line-height: 3.5rem;
      height: var(--xsmall-gap);
      margin-right: var(--xsmall-gap);
      position: relative;

      &:last-child {
        margin-right: 0;
      }

      svg {
        position: absolute;
        top: 50%;
        transform: translateY(-50%) translateX(-50%);
      }
    }
  }

  each(@cs-colors, {
    score-progress-@{value} {
      background: ~"var(--@{value}-color)" !important;

      svg {
        stroke: ~"var(--@{value}-color)" !important;
      }
    }
  });

  score-progress-bad {
    flex-grow: 5;
  }

  .text-subtle {
    font-size: var(--font-size-xs);
  }

  .text--headline {
    font-size: var(--font-size-xl);
    padding: var(--box-padding);

    each(@cs-colors, {
      .color-@{value} {
        color: ~"var(--@{value}-color)" !important;
      }
    });
  }

  .header--two-columns {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .columns--space-between {
    width: 100%;

    & > * {
      align-items: center;
      display: flex;
      justify-content: space-between;
      font-size: var(--font-size-s);
      padding: var(--small-gap) 0;
      border-bottom: 0.03rem solid rgba(0, 0, 0, 0.1);

      *:first-of-type {
        .label();
      }
    }
  }

  .chart-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
    margin: 0 calc((var(--small-gap))* -1);

    & > * {
      margin: var(--small-gap) var(--small-gap);
    }
  }

  amortization-preview {
    width: 100%;
  }

  @media print {
    * {
      -webkit-print-color-adjust: exact;
      print-color-adjust: exact;
    }

    .modal {
      background: none;
      border: none;
      box-shadow: none;
      margin: 0;
      width: 100%;

      --cart-border-width: 1px;
    }

    .modal-header {
      padding-left: 0;
    }

    .modal-body {
      padding: var(--cart-border-width);
      max-height: 100%;
      overflow: hidden;
    }

    cart {
      position: relative;

      &:after {
        border-style: solid;
        border-width: var(--cart-border-width);
        border-color: var(--disabled-button-background-color);
        border-radius: inherit;;
        bottom: calc(var(--cart-border-width) + 1px);
        content: "";
        left: calc(var(--cart-border-width) + 1px);
        position: absolute;
        top: calc(var(--cart-border-width) + 1px);
        right: calc(var(--cart-border-width) + 1px);
      }
    }

    .cart--large, .grid {
      page-break-inside: avoid;
    }
  }
}

@media print {
  body.credit-scoring-modal  {
    background: none;
    visibility: hidden;

    credit-scoring-modal {
      visibility: visible;
    }
  }
}
