.bootstrap-legacy-styles {
  @import (less) '../../public/bootstrap/bootstrap-combined.no-icons.min.css';
  @import (less) 'lib/font-awesome.min.css';
  @import (less) 'lib/jquery.gritter.css';
  @import (less) '~selectize/dist/css/selectize.default.css';
  @import (less) '~daterangepicker/daterangepicker-bs2.css';
  @import (less) '../../../node_modules/ng-table/bundles/ng-table.css';
  @import 'lib/ng-table';
  @import 'lib/dragula';
  @import '~@nextbank/bootstrap/less/variables';
  @import 'common/main';
  @import 'administration/administration-branches';
  @import 'administration/branch-details';
  @import 'administration/casa/interest-board';
  @import 'administration/deposit-products';
  @import 'administration/depository-accounts';
  @import 'administration/general-ledger/transaction-mapping';
  @import 'administration/gl-mappings';
  @import 'administration/holidays';
  @import 'administration/loan-products';
  @import 'administration/metals';
  @import 'administration/mfa-configuration';
  @import 'administration/products-table';
  @import 'administration/compliance-config';
  @import 'administration/security/roles';
  @import 'administration/term-deposit-section';
  @import 'administration/transactions/transactions-details';
  @import 'administration/transactions/transactions-list';
  @import 'administration/users';
  @import 'common/account-picker';
  @import 'common/branches-list';
  @import 'common/breadcrumb/breadcrumb';
  @import 'common/check-form';
  @import 'common/customer-picker';
  @import 'common/denomination';
  @import 'common/form-section';
  @import 'common/glue-container';
  @import 'common/inline-panel';
  @import 'common/internet-check';
  @import 'common/loader';
  @import 'common/menu';
  @import 'common/modal-info';
  @import 'common/multiselect';
  @import 'common/popup';
  @import 'common/print/modal-print-preview';
  @import 'common/print/print-header';
  @import 'common/print';
  @import 'common/selectize';
  @import 'common/sub-header';
  @import 'common/validator';
  @import 'common/working-day-check';
  @import 'customer/common-product-operation';
  @import 'customer/common-products';
  @import 'customer/deposits/accounts/activate-account';
  @import 'customer/deposits/accounts/cash-deposit';
  @import 'customer/deposits/accounts/close-account';
  @import 'customer/deposits/accounts/deposit-check';
  @import 'customer/deposits/accounts/edit-account-ata';
  @import 'customer/deposits/accounts/new-account-list';
  @import 'customer/deposits/cash-amount-picker';
  @import 'customer/deposits/term/new-term-deposit';
  @import 'customer/deposits/term/term-deposits';
  @import 'customer/external-accounts';
  @import 'customer/group-loans/group-loan-disbursment';
  @import 'customer/group-loans/group-members';
  @import 'customer/group-loans/profile/sub-components/group-officers';
  @import 'customer/header';
  @import 'customer/loans/common/amortization-payment';
  @import 'customer/loans/common/transaction-history';
  @import 'customer/loans/customer-loans-create-details';
  @import 'customer/loans/customer-loans-release-cashiers-check';
  @import 'customer/loans/customer-loans';
  @import 'customer/loans/new-loan/edit-loan-amortization';
  @import 'customer/loans/new-loan/loan-preview';
  @import 'customer/loans/new-loan/new-loan-lai';
  @import 'customer/loans/new-loan/new-loan-parameters';
  @import 'customer/loans/payment/customer-loans-cash-payment';
  @import 'customer/loans/payment/customer-loans-check-payment';
  @import 'customer/loans/payment/customer-loans-transfer-payment';
  @import 'customer/loans/pre-terminate/loan-pre-terminate';
  @import 'customer/loans/waive-penalties/waive-amortization';
  @import 'dashboard/actions';
  @import 'dashboard/appraisal-details-panel';
  @import 'dashboard/history';
  @import 'dashboard/lookup_customer';
  @import 'dashboard/miscellaneous-transactions';
  @import 'gl/gl_account_details';
  @import 'gl/gl_account_list';
  @import 'gl/gl_header';
  @import 'gl/gl_transaction_create';
  @import 'gl/gl_transaction_details';
  @import 'gl/gl_transaction_list';
  @import 'inventory/checks-list';
  @import 'report/report-details';
  @import 'report/report-list';
  @import 'report/report-modal';
}

@media print {
  .main-message-container.no-print {
    display: none;
  }
}
