multiselect {
  border-radius: var(--default-border-radius);
  display: inline-flex;
  height: var(--input-height);
  width: var(--input-width-m);

  .multiselect {
    border-radius: var(--default-border-radius);
    box-sizing: border-box;
    cursor: pointer;
    width: 100%;
    min-height: var(--input-height);

    &.inactive {
      background-color: inherit;
      cursor: not-allowed;
    }

    &__chosen-option {
      align-items: center;
      display: flex;
      border-radius: var(--default-border-radius);
      box-sizing: border-box;
      width: 100%;

      &:after {
        color: var(--text-color-light);
        content: "\25BC";
        margin-left: auto;
      }

      &.invalid {
        border: 2px solid var(--danger-color);
      }
    }

    label {
      align-items: center;
      display: flex;

      input {
        height: auto;
        margin: 0;
      }
    }

    &__options {
      max-height: 300px;
      overflow-x: hidden;
      overflow-y: auto;
    }

    &__options-wrapper {
      background: var(--input-background-color);
      box-sizing: border-box;
      margin: 0;
      position: absolute;
      z-index: var(--z-index-dropdowns);

      ul {
        margin: 0;
      }

      li {
        display: flex;
        align-items: center;
        padding-left: 0.8rem;
        padding-right: var(--element-side-gap);
      }
    }

    .multiselect__item {
      list-style: none;
    }

    .multiselect__select-all {
      &:extend(.multiselect__item);
    }
  }

}