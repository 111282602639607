@import '~@nextbank/ui-components/dist/variables';
@import '~@nextbank/ui-components/dist/mixins';

.treeWrapper {
  @include font-size-s;

  color: $textColor;
  line-height: $gapL;

  padding-top: 1.1rem;
  padding-bottom: 0;
  padding-left: ($gapM - 0.7rem);
  padding-right: 0;
}