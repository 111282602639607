predicate .predicate {
  max-width: 400px;
  background: var(--input-background-color);
  padding: 5px;
  margin: 5px;
  border-radius: var(--default-border-radius);
}
predicate .predicate .close {
  padding-left: 8px;
  align-self: center;
  font-size: var(--font-size-l);
}
predicate .predicate .close:after {
  content: '×';
}
