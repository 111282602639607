general-health-check .hc-item-wrapper-header {
  box-sizing: border-box;
  width: 100%;
  margin-top: 1rem;
}
general-health-check .hc-item-wrapper-header div {
  margin-bottom: 1rem;
}
general-health-check .hc-item-wrapper-body {
  margin-bottom: 1rem;
  margin-top: 1rem;
}
general-health-check .hc-item-wrapper-check {
  background-color: var(--white);
  border-bottom: 1px solid var(--light-line-color);
  margin-bottom: 3rem;
  padding-bottom: 3rem;
}
general-health-check .hc-item-wrapper-check:last-child {
  border-bottom: none;
  padding-bottom: 0;
}
