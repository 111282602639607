number-pattern {
  display: block;
  padding: 5px;
}
number-pattern.ng-invalid.ng-invalid-required {
  border: 2px solid var(--danger-color);
}
number-pattern.ng-invalid.ng-invalid-parse .number-pattern__input,
number-pattern.ng-invalid.ng-invalid-length .number-pattern__input {
  border: 2px solid var(--danger-color);
  outline: none;
}
number-pattern .number-pattern__input-label {
  margin: 0;
  padding-bottom: 10px;
}
number-pattern .number-pattern__input-error {
  padding-bottom: 10px;
}
number-pattern .number-pattern__input {
  margin: 0 !important;
}
number-pattern .number-pattern__manual {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  margin: -5px;
}
number-pattern .number-pattern__manual > * {
  margin: 5px;
  flex-grow: 1;
}
number-pattern .number-pattern__item-button {
  align-items: center;
  background: none;
  border: 1px solid #999;
  border-radius: var(--default-border-radius);
  display: flex;
  text-align: left;
  min-width: 100%;
}
number-pattern .number-pattern__item-button[disabled] {
  border: 1px solid #999 !important;
  cursor: not-allowed;
  opacity: 0.5;
}
number-pattern .number-pattern__item-button > * {
  margin: 5px;
}
number-pattern .number-pattern__item-button:focus-within {
  border: 1px solid #333;
  box-shadow: 0 0 2px #333;
  outline: none;
}
