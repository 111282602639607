risk-category-details-view {
  @import '~@nextbank/bootstrap/less/variables';

  .sub-header {
    white-space: normal;
    word-wrap: break-word;
  }

  form {
    margin: 0;
  }

  div.form-section {
    box-sizing: border-box;
    width: 500px;
    float: left;
    padding: 0 40px 0 20px;
  }

  .file-upload {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-evenly;
    align-items: center;
  }

  .buttons {
    float: right;
    margin: 20px;
  }

  .download-btn {
    width: 150px;
  }

  .risk-category-entry {

    &__table {
      box-sizing: border-box;
      margin: 20px;
      width: calc(100% - 40px);

      input, select {
        margin: 0;
      }

      input.input--small-number {
        width: 100px;
      }

      input.input--description {
        width: 300px;
      }

      select.select--risk-level {
        width: 120px;
      }

      .no-column-header {
        width: 40px;
      }

      th.th--description {
        width: 300px;
      }

      th.th--conditions {
        width: 50%;
      }

      td {
        .conditions {
          display: flex;
        }
      }

      td, th {
        &:last-child {
          width: 90px;
        }
      }
    }

    &__button {
      width: 90px;
    }
  }
}