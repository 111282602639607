loan-ropa-transfer {
  table {
    margin-top: 10px;

    input {
      margin: 0;
    }
  }

  .actions {
    button {
      margin-left: 5px;
    }
  }
}