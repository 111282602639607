@import '~@nextbank/bootstrap/less/variables';

center-group-picker {
  .search-btn {
    margin-left: 5px;
  }

  .content {
    border: 1px solid @grayLight;
    padding: 0 10px 0 10px;
    background-color: white;
    margin-top: 5px;
    overflow-y: auto;
    overflow-x: hidden;

    div.alert {
      max-width: 100%;
    }
  }

  .no-results {
    color: gray;
    text-shadow: 1px 1px 0 @grayLight;
    font-size: 15px;
    margin: 10px 15px;
  }
}