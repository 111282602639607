@import (less) '~codemirror/lib/codemirror.css';
@import (less) '~codemirror/addon/hint/show-hint.css';

sql-editor {
  display: flex;
  justify-content: center;
  align-items: center;

  > textarea {
    display: none;
  }

  .CodeMirror {
    border: 1px solid var(--light-line-color);
    flex: 1;
    height: 100%;
    outline: none !important;
    border-radius: var(--default-border-radius) !important;
  }
}