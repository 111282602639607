/* the norm */
#gritter-notice-wrapper {
    position: fixed;
    top: 20px;
    right: 20px;
    width: 301px;
    z-index: var(--z-index-dropdowns);
}

#gritter-notice-wrapper.top-left {
    left: 20px;
    right: auto;
}

#gritter-notice-wrapper.bottom-right {
    top: auto;
    left: auto;
    bottom: 20px;
    right: 20px;
}

#gritter-notice-wrapper.bottom-left {
    top: auto;
    right: auto;
    bottom: 20px;
    left: 20px;
}

.gritter-item-wrapper {
    position: relative;
    margin: 0 0 10px 0;
    background: url('../../../public/img/ie-spacer.gif'); /* ie7/8 fix */
}

.gritter-top {
    background: url(../../../public/img/gritter.png) no-repeat left -30px;
    height: 10px;
}

.hover .gritter-top {
    background-position: right -30px;
}

.gritter-bottom {
    background: url(../../../public/img/gritter.png) no-repeat left bottom;
    height: 8px;
    margin: 0;
}

.hover .gritter-bottom {
    background-position: bottom right;
}

.gritter-item {
    display: block;
    background: url(../../../public/img/gritter.png) no-repeat left -40px;
    color: #eee;
    padding: 2px 11px 8px 11px;
    font-size: var(--font-size-s);
    font-family: verdana;
}

.hover .gritter-item {
    background-position: right -40px;
}

.gritter-item p {
    padding: 0;
    margin: 0;
    word-wrap: break-word;
}

.gritter-close {
    display: none;
    position: absolute;
    top: 5px;
    left: 3px;
    background: url(../../../public/img/gritter.png) no-repeat left top;
    cursor: pointer;
    width: 30px;
    height: 30px;
}

.gritter-title {
    font-size: var(--font-size-m);
    font-weight: var(--font-weight-semibold);
    padding: 0 0 7px 0;
    display: block;
    text-shadow: 1px 1px 0 #000; /* Not supported by IE :( */
}

.gritter-image {
    width: 48px;
    height: 48px;
    float: left;
}

.gritter-with-image,
.gritter-without-image {
    padding: 0;
}

.gritter-with-image {
    width: 220px;
    float: right;
}

/* for the light (white) version of the gritter notice */
.gritter-light .gritter-item,
.gritter-light .gritter-bottom,
.gritter-light .gritter-top,
.gritter-light .gritter-close {
    background-image: url(../../../public/img/gritter-light.png);
    color: #222;
}

.gritter-light .gritter-title {
    text-shadow: none;
}
