.breadcrumbs {
  align-items: center;
  display: flex;
  height: var(--small-button-height);
  margin-top: var(--small-gap);
  padding-left: var(--small-gap);
  padding-right: var(--small-gap);
  text-transform: uppercase;
  transition: var(--default-transition);

  &--hidden {
    opacity: 0;
  }

  &__item {
    color: var(--text-color-light);
    font-size: var(--font-size-s);
    font-weight: var(--font-weight-semibold);

    &--trimmed {
      overflow: hidden;
      max-width: 30rem;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    &::before {
      background: url(icons/icon-chevron-right.svg) no-repeat center;
      content: "";
      height: 0.8rem;
      display: inline-block;
      width: 2.5rem;
    }

    &:first-of-type {
      &::before {
        display: none;
      }
    }
  }
}