.file-upload {
  &__list {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    list-style: none;
    margin: 0;
  }

  &__image {
    align-items: center;
    display: flex !important;
    border: 2px dashed var(--box-shadow-color);
    border-radius: var(--default-border-radius);
    box-sizing: border-box;
    font-size: var(--font-size-xs);
    min-height: 8rem;
    justify-content: center;
    margin-bottom: var(--element-side-gap);
    margin-left: 0;
    margin-right: var(--element-side-gap);
    padding-left: 1rem;
    padding-right: 1rem;
    min-width: 8rem;

    i {
      font-size: 2.4rem;
      height: 1.8rem;
      margin: 0.4rem;
      width: 1.8rem;
    }
  }

  .labelled-field {
    margin-right: 0;
  }

  button {
    font-size: var(--font-size-s);
    margin-left: var(--element-side-gap);
    min-height: var(--big-button-height) !important;
  }

  .file-upload-new__container {
    align-items: center;
    display: flex;
    margin-bottom: var(--element-side-gap);

    ol {
      margin: 0;
    }

    .file-upload-new__drop-zone {
      align-items: center;
      border: 2px dashed var(--box-shadow-color);
      border-radius: var(--default-border-radius);
      color: var(--text-color);
      box-sizing: border-box;
      display: flex;
      justify-content: center;
      padding: 5px;
      min-height: var(--big-button-height);
      min-width: 100px;
    }
  }
}

td {
  .file-upload {
    width: var(--input-max-width);
  }
}
