close-counters-health-check .hc-item-cc-header,
close-counters-health-check .hc-item-cc-row {
  background-color: #fff;
  border-bottom: 1px solid #eee;
}
close-counters-health-check .hc-item-cc-header-wrapper,
close-counters-health-check .hc-item-cc-row-wrapper {
  display: flex;
  flex-wrap: wrap;
  padding: 10px;
}
close-counters-health-check .hc-item-cc-header-wrapper > div,
close-counters-health-check .hc-item-cc-row-wrapper > div {
  flex: 1px;
}
close-counters-health-check .hc-item-cc-header-wrapper > div:first-child,
close-counters-health-check .hc-item-cc-row-wrapper > div:first-child {
  max-width: 35px;
}
close-counters-health-check .hc-item-cc-header-action,
close-counters-health-check .hc-item-cc-row-action {
  text-decoration: underline;
  transition: 0.2s ease;
}
close-counters-health-check .hc-item-cc-header-action:hover,
close-counters-health-check .hc-item-cc-row-action:hover {
  color: var(--primary-color);
  cursor: pointer;
}
close-counters-health-check .hc-item-cc-header-table,
close-counters-health-check .hc-item-cc-row-table {
  border-top: 1px solid #eee;
  padding: 10px;
  background: #eee;
}
