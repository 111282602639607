.loader-wrapper {
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
  right:0;
  text-align: center;
  z-index: var(--z-index-popup);
}

.loader {
  margin-top: 250px;
  background: white;
  display: inline-block;
}

.loader-bg {
  background: black;
  opacity: 0.2;
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  right: 0;
  z-index: calc(var(--z-index-popup) - 1);
}

/* position the text relative to the spinner */
#loader_text {
  float: right;
  margin-top: -10px;
  margin-bottom: -10px;
  margin-left: 50px;
}