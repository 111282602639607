.cash-amount-picker {
  .cash-amount-picker-list {
    overflow: hidden;
    margin: 0;
    margin-bottom: 3rem;

    li {
      list-style: none;
      float: left;
      margin-right: 2rem;
      margin-bottom: 3rem;
      transition: var(--default-transition);
    }

    button {
      width: 15rem;
      padding: 2rem;
    }

    .li-other {
      margin-bottom: 0;
    }

    // terrible hackery!
    cash.other-input input {
      position: absolute;
      width: 15rem;
      text-align: center;
      color: white;
      background: transparent;
      border: none;
      outline: none;
      box-shadow: none;
      margin-top: -5rem;
    }

    input.other-input::-webkit-outer-spin-button,
    input.other-input::-webkit-inner-spin-button {
      -webkit-appearance: none;
    }

    .li-other .selected-other {
      font-size: 0;
    }
  }
}