.pdf-view {
  background: rgba(0, 0, 0, 0.5);
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--z-index-always-on-top);
}
.pdf-view__container {
  margin: 0 auto;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: zoom-out;
}
.pdf-view__document {
  width: 70%;
  height: 70%;
}
