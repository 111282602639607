batch-pdc-payment {
  .btn {
    min-width: 100px;
  }

  .actions {

    &__buttons {
      & > :not(:last-child) {
        margin-right: 20px;
      }
    }

    &__label {
      display: inline;
    }

    &__input {
      margin-bottom: 0 !important;
    }

  }

  .payment-result {
    margin: 20px 0;

    &__table-header {
      margin-bottom: 0;
    }
  }

  .modal {
    left: 0;
    right: 0;
    width: 50%;
    margin: 0 auto;
  }

}