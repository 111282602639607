gl-transaction-interbranch-create {

  @media print  {
    breadcrumb, gl-header, form {
      display: none;
    }
  }

  .transaction-create-table {
    input, select {
      margin: 0;
    }

    .no-column-header {
      min-width: 10px;
    }

    .home-column-header {
      width: 15px;
    }

    .branch-column-header {
      width: 25%;
    }

    .account-column-header {
      width: 25%;
    }

    .smaller-column-header {
      width: 15%;
    }
  }
}
