term-deposit-section {

  .term-deposit-section__override-container {
    display: flex;
    justify-content: center;

    glue-container {
      flex-grow: 1;
    }
  }
}

// TODO: .term-deposit-section must be global since it is used by other componets, eg.
// customer-profile-*, administartion/users-details
.term-deposit-section form {
  margin: 0;
}

.form-column {
  box-sizing: border-box;
  width: 500px;
  float: left;
}
