@import './bootstrap-legacy-overrides/styles';

@import 'alerts';
@import 'breadcrumbs';
@import 'menu';
@import 'variables';
@import '~@nextbank/ui-components/dist/variables';

html {
  font-family: var(--font-family);
  font-size: $baseFontSize;  // as reference for rem units (now 1rem = 10px)
}

body {
  background: var(--body-color);
  color: var(--text-color);
  font-weight: var(--font-weight-normal);
  margin: 0;
  overflow-y: scroll;

  @media print {
    & {
      background: white;
    }
  }
}

.main-container {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  padding-left: var(--menu-width);
  padding-top: var(--top-bar-height);
  min-height: 100vh;

  @media print {
    padding-left: 0;
    padding-top: 2rem;
    min-height: auto;
  }
}

.main-view {
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 100%;

  & > * {
    display: flex;
    flex: 1;
    flex-direction: column;
    padding-bottom: var(--box-padding);
  }
}

#modal-container {
  position: relative;
}

strong {
  font-weight: var(--font-weight-bold);
  display: inline;
}


h1 {
  font-size: var(--font-size-xl);
  font-weight: var(--font-weight-semibold);
  line-height: var(--font-size-xl);
}

h2 {
  font-size: var(--font-size-l);
  font-weight: var(--font-weight-semibold);
  line-height: var(--font-size-l);
}

h1,
h2 {
  margin: 0;
  font-weight: var(--font-weight-semibold);
  text-rendering: optimizelegibility;
}
