customer-loan-charges-override .alert-container {
  padding-top: 20px;
}
customer-loan-charges-override .override-option,
customer-loan-charges-override .override-checkbox,
customer-loan-charges-override .show-zero-checkbox {
  display: flex;
}
customer-loan-charges-override .override-option {
  justify-content: space-between;
}
