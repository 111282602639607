customer-credit-line-create table select,
customer-credit-line-create table input {
  margin-bottom: 0;
}
customer-credit-line-create table td.values {
  display: flex;
}
customer-credit-line-create .buttons {
  float: right;
  margin-right: 40px;
}
