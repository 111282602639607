interest-board {
  p.title {
    text-transform: uppercase;
    font-weight: var(--font-weight-semibold);
    border-bottom: 1px solid lightgray;
  }

  .form-section {
    width: 400px;
  }
}
