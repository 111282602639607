@import "~@nextbank/bootstrap/less/variables";

logged-in-users {
  .nav-tabs {
    .disabled {
      color: darkgray;
    }
  }

  .table-content {
    overflow: visible;

  }

  .logged-in-icon {
    color: var(--success-color);
  }

  .logged-out-icon {
    color: var(--danger-color);
  }
}