
#customer-loans-edit {
    .entry-form {
      display: flex;
      flex-wrap: wrap;
    }

    .product-info-section {
      display: flex;
      padding: 20px 0;

      .product-attribute {
        flex-grow: 1;

        .key {
          font-weight: var(--font-weight-semibold);
        }
      }
    }

    table {
      margin-bottom: 0;

      select, input, textarea {
        margin-bottom: 0;
      }

      td.label {
        width: 280px;
      }

      th.numbers {
        width: 10px;
      }

      td.values {
        display: flex;
        align-items: center;
      }

      .row-info-section {
        input {
          width: 80px;
        }

        .add-on {
          width: 30px;
        }
      }

      .cycle {
        margin-left: 20px;

      }
    }



  .override-charges {
    margin-top: 20px;
  }
}
