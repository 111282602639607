@import 'src/style/mixins';

.modal-body,
.modal-footer,
.modal-header {
  background: var(--white);
  padding-left: var(--box-padding);
  padding-right: var(--box-padding);

  .table {
    margin-bottom: 2rem;
  }

  .report-table-wrapper {
    margin-bottom: 0;
    margin-top: 0;

    @media print {
      margin-top: 2rem;
    }
  }
}

.modal-footer {
  border: 0;
  display: flex;
  justify-content: flex-end;
  padding: var(--box-padding);
}

.modal-header {
  border: 0;
  padding: var(--box-padding);
}