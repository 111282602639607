@import '~@nextbank/ui-components/dist/variables';
@import '~@nextbank/ui-components/dist/mixins';

.icon {
  align-items: center;
  display: flex;
  justify-content: center;
  margin-right: 7px;
  transition: 0.3s ease;
  transform-origin: center;
}

.open {
  transform: rotate(180deg);
}

.wrapper {
  max-width: $inputMaxWidth;
  width: 100%;
  position: relative;

  &:not(.error):focus-within {
    :global(.MuiInputBase-root) {
      border-color: $primaryColor;
    }
  }
}

.inputWrapper {
  :global(.MuiFormControl-root) {
    cursor: pointer;
    user-select: none;

    :global(.MuiInputBase-root) {
      cursor: inherit;

      & input {
        caret-color: transparent;
        cursor: inherit;
        pointer-events: none;
      }
    }
  }
}

.fullWidth {
  width: 100%;
}

.treeWrapper {
  background: $inputBgColor;
  border: 2px solid transparent;
  border-top: 1px solid $lineColor;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  box-shadow: 0 0.5rem 1.5rem 0 $lightShadowColor;
  max-height: 40rem;
  overflow: auto;
  position: absolute;
  top: $basicElementHeight;
  width: 100%;
  z-index: 10;
}

.disabled {
  :global(.MuiInputBase-root) {
    border-color: $switchBgColor !important;
  }

  .treeWrapper {
    background: white !important;
    border-color: $switchBgColor;
  }
}