.alert {
  :global(.MuiAlert-icon),
  :global(.MuiAlert-message) {
    padding: 0;
  }

  :global(.MuiAlert-message) {
    align-items: center;
    display: flex;
    line-height: normal;
  }

  &:global(.MuiPaper-root) {
    padding: var(--small-gap);
    padding-left: calc(3 * var(--xsmall-gap));
  }

  &:global(.MuiAlert-standardInfo) {
    color: var(--success-color);
    background-color: var(--success-background-color);

    :global(svg) {
      color: var(--success-color);
      height: 2rem;
      padding-bottom: 0;
      padding-top: 0;
      width: 2rem;
    }
  }
}
