.group-loans-create-members .label-checkbox {
  display: inline-block;
  padding: 5px;
}
.group-loans-create-members .buttons {
  display: flex;
  justify-content: flex-end;
  margin: 0 -5px;
  margin-bottom: 20px;
}
.group-loans-create-members .buttons > * {
  margin: 0 5px;
}
.group-loans-create-members .member-configured {
  border-bottom: 3px solid #62c462;
}
