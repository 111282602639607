center-group-picker .search-btn {
  margin-left: 5px;
}
center-group-picker .content {
  border: 1px solid #999;
  padding: 0 10px 0 10px;
  background-color: white;
  margin-top: 5px;
  overflow-y: auto;
  overflow-x: hidden;
}
center-group-picker .content div.alert {
  max-width: 100%;
}
center-group-picker .no-results {
  color: gray;
  text-shadow: 1px 1px 0 #999;
  font-size: 15px;
  margin: 10px 15px;
}
