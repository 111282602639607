dictionary-condition {
  display: block;
  white-space: nowrap;
}
dictionary-condition .condition {
  display: flex;
  align-items: center;
  width: fit-content;
}
dictionary-condition .condition glue-container {
  margin-left: 10px;
}
dictionary-condition .condition glue-container input {
  max-width: 100px;
}
dictionary-condition .condition-container {
  display: flex;
  flex-direction: column;
}
