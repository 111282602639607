areas {
  .tree {
    background: white;
  }

  ul {
    list-style-type: circle;

    &.root {
      margin: 0;
    }

    .list-row {
      margin-bottom: 1rem;
      display: flex;
      justify-content: space-between;
      align-items: center;

      &.area {
        font-weight: var(--font-weight-semibold);
        text-transform: uppercase;
      }
    }
  }

  .unassigned {
    li {
      font-size: var(--font-size-s);
      margin-bottom: 1rem;
    }
  }
}