health-check-list {
  padding-top: 2rem;
  padding-bottom: 2rem;
}
health-check-list h2 {
  align-items: center;
  display: flex;
}
health-check-list h2 .status--label {
  margin-left: 2rem;
}
health-check-list .hc-item {
  border-bottom: 1px solid var(--light-line-color);
  margin-top: 3rem;
}
health-check-list .hc-item.regular {
  padding: 0;
}
health-check-list .hc-item.regular.fail,
health-check-list .hc-item.regular.ok {
  border-width: 0;
}
health-check-list .hc-item-wrapper {
  margin-bottom: 2rem;
  margin-left: 0;
  margin-right: 0;
  margin-top: 2rem;
}
health-check-list .hc-item-wrapper .status.fail {
  color: var(--danger-color);
}
health-check-list .hc-item-wrapper .status.ok {
  color: var(--success-color);
}
health-check-list .hc-item-table {
  color: var(--text-color-light);
  width: 100%;
  table-layout: fixed;
  text-align: left;
  border-collapse: collapse;
  background-color: #fff;
}
health-check-list .hc-item-table tr:not(:last-child) {
  border-bottom: 1px solid var(--light-line-color);
}
health-check-list .hc-item-table td,
health-check-list .hc-item-table th {
  padding: 10px;
}
