@import '~@nextbank/ui-components/dist/variables';
@import '~@nextbank/ui-components/dist/mixins';

.label {
  @include font-size-s;
  
  color: $textColorLight !important;
  left: $gapM - 0.2rem;
  line-height: $gapL;
  pointer-events: none;
  position: absolute;
  top: 0;
  transform: scale(0.8) !important;
  transform-origin: left;
}

.error {
  color: $errorColor;
}