/* stretch menu to 100% width */
.nav-tabs {
  display: flex;
  list-style: none;
  margin: 0;
  margin-bottom: 3rem;

  .icon-yellow,
  .icon-warning-sign {
    margin-right: 1rem;
    color: var(--danger-color);
  }

  li {
    border-bottom: solid 1px var(--light-line-color);
    padding-left: 1rem;
    padding-right: 1rem;

    &:first-child {
      padding-left: 0;
    }

    &:last-child {
      padding-right: 0;
    }

    &.disabled {
      cursor: not-allowed;
      pointer-events: none;
    }
  }

  a {
    align-items: center;
    background: transparent;
    border-radius: 0;
    box-sizing: border-box;
    color: var(--text-color-light);
    cursor: pointer;
    display: inline-flex;
    font-size: var(--font-size-l);
    font-weight: var(--font-weight-semibold);
    outline: none;
    height: 100%;
    padding-bottom: 1.1rem;
    padding-left: 1rem;
    padding-right: 1rem;
    position: relative;
    text-align: center;
    transition: var(--default-transition);

    &::before {
      background: var(--primary-color);
      border-top-left-radius: 0.2rem;
      border-top-right-radius: 0.2rem;
      bottom: 0;
      content: '';
      height: 0.3rem;
      left: 0;
      margin-left: auto;
      margin-right: auto;
      opacity: 0;
      position: absolute;
      right: 0;
      transition: var(--default-transition);
      width: 0;
    }

    &:hover {
      color: var(--text-color);
    }

    .badge {
      margin-left: 1rem;
    }
  }

  .active {
    a {
      color: var(--text-color);

      &::before {
        opacity: 1;
        width: 2rem;
      }
    }
  }

  .disabled {
    a {
      color: var(--disabled-text-color);
    }
  }

}
