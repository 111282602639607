#inventory-checks-list {
  .actions {
    display: flex;
    float: left;
    justify-content: flex-start;
  }

  .tab-content {
    overflow: visible;

  }
}