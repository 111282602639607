area-details input[type=checkbox] {
  margin: 0 5px;
}
area-details .form-section {
  width: 500px;
}
area-details .form-section ul {
  background: white;
  list-style: none;
  margin: 0;
}
area-details .form-section ul li {
  padding: 8px;
  border-bottom: 1px solid var(--light-line-color);
}
area-details .form-section ul li:hover {
  background-color: var(--hover-background-color);
}
area-details table {
  margin-top: 5px;
}
area-details table .area-office-column {
  text-align: center;
}
area-details table tr .inactive {
  color: var(--text-color-light);
}
