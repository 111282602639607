amortization-custom-fees-inline-panel {

  inline-panel {
    .icon-plus {
      padding: 0 0 0 5px;
      cursor: pointer;
    }

    .icon-remove {
      padding: 0 0 0 5px;
      cursor: pointer;
    }
  }

  .custom-fee-input {
    width: 200px;
  }

  .custom-fee-button {
    align-self: center;
  }
}