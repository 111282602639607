customer-loan-create-parameters .policy-number-length-error {
  padding-top: 8px;
}
customer-loan-create-parameters .entry-form {
  display: flex;
  flex-wrap: wrap;
}
customer-loan-create-parameters table {
  margin-bottom: 0;
}
customer-loan-create-parameters table select,
customer-loan-create-parameters table input,
customer-loan-create-parameters table textarea {
  margin-bottom: 0;
}
customer-loan-create-parameters table td.label {
  width: 280px;
}
customer-loan-create-parameters table td.values {
  display: flex;
  align-items: center;
  max-width: var(--input-max-width);
}
