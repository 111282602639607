dynamic-list .dynamic-list__table td {
  vertical-align: middle;
}
dynamic-list .dynamic-list__table-error thead {
  background-color: #f2dede;
  border: 1px solid #eed3d7;
}
dynamic-list .dynamic-list__row {
  background-color: white;
}
dynamic-list .dynamic-list__column-edit {
  box-sizing: border-box;
  width: 100px;
}
