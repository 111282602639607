
report-view {
  display: block;

  @media print {
    padding: 0 var(--box-padding);
  }

  .report-description {
    display: none;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 1.6rem;
    grid-row-gap: 0.8rem;
    
    @media print {
      display: grid;
    }

    .report-description-item {
      display: flex;

      & > {
        white-space: normal;
        word-break: break-all;
        overflow: hidden;
      }

      .label {
        flex: 0 0 auto;
      }
    }
  }

  .report-description {
    width: initial;

    tr td {
      width: initial;
      white-space: pre-wrap;
    }

    tr:first-child td {
      border-top: none;
    }
  }

  .table--report {
    td {
      border: 1px solid var(--light-line-color);

      &:after {
        display: none;
      }
    }
  }

  .table {
    th {
      border-top: none;
      text-align: left;
    }

    tr.report-summary td {
      text-align: left;
      border: none;
    }

    th.align-right, tr.report-summary td.align-right {
      text-align: right;
    }

    td.no-wrap {
      white-space: nowrap;
    }

    .report-separator td {
      border-color: transparent;
    }
  }

  .cursor-default {
    cursor: default;
  }

  pre {
    font-family: var(--font-family);
    background-color: transparent;
    display: block;
    padding: 0;
    margin: 0;
    word-break: normal;
    word-wrap: break-word;
    white-space: pre-wrap;
    border: none;
    text-align: left;

    &.align-right {
      text-align: right;
    }
  }

  .key-value {
    &-container {
      align-items: center;
      display: flex;
      padding: 8px 0;
    }

    &-label {
      cursor: default;
      font-weight: var(--font-weight-semibold);
      margin: 0;

      &:after {
        content: ':';
        display: inline-block;
      }
    }

    &-value {
      margin: 0;
      padding-left: 10px;
    }
  }

  .report-table-wrapper {
    border: 1px solid var(--light-line-color);
    border-radius: var(--default-border-radius);
    width: 100%;
    max-width: 100%;
    margin-top: 2rem;
    margin-bottom: 3rem;
    overflow: auto;

    @media print {
      border: 0;
      width: auto;
      max-width: none;
      overflow: visible;
      max-height: none;

      .table {
        max-width: 100%;
        width: 100%;

        tbody {
          tr {
            page-break-inside: avoid;
          }
        }
      }
    }

    .table {
      margin-bottom: 0;
      margin-left: -1px;
      margin-right: -1px;
      width: 100%;
    }
  }

}
