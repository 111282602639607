loan-product-letter table th:last-child {
  text-align: right;
  padding-right: 35px;
}
loan-product-letter .letter-list__add-new-column {
  text-align: right !important;
}
loan-product-letter button {
  margin-right: 5px;
}
