letter-type-list table th:last-child {
  text-align: right;
  padding-right: 35px;
}
letter-type-list .type-list__add-new-column {
  text-align: right !important;
}
letter-type-list button {
  margin-right: 5px;
}
