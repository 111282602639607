holiday-table table th:last-child {
  text-align: right;
  padding-right: 35px;
}
holiday-table .holiday-list__add-new-column {
  text-align: right !important;
}
holiday-table .holiday-list__input {
  margin-bottom: 0;
  width: calc(100% - 5px);
}
holiday-table button {
  margin-right: 5px;
}
