.branches-list {
  td, th {
    padding-left: 0;
  }

  .branches-list__number-column {
    width: 40px;
  }
}

.branches-list__open-link-column {
  width: 100px;

  .open-link {
    width: 100%;
    box-sizing: border-box;
  }
}
