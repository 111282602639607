loan-edit-pdc {

  table td:first-child {
    white-space: nowrap;
  }

  table td:nth-child(2) {
    display: flex;
    align-items: center;
    width: 400px;
    white-space: nowrap;
  }

  table td:last-child {
    width: 100%;
  }

  td > input, select, .selectize-control {
    width: 100%;
  }

}