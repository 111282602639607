boolean-condition {
  display: block;
  white-space: nowrap;
}
boolean-condition .condition {
  display: flex;
  align-items: center;
  width: fit-content;
}
boolean-condition .condition-container {
  display: flex;
  flex-direction: column;
}
