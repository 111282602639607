roles-config {

  .disabled {
    pointer-events: none;
    cursor: default;
  }

  roles-list {
    span {
      padding-left: 25px;
    }

    table {
      th:last-child {
        text-align: right;
        padding-right: 35px;
      }
    }
  }




}
