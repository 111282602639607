batch-memo {
  @import '~@nextbank/bootstrap/less/variables';

  gl-category-select {
    width: 100%;
  }

  .nav-tabs {
    .disabled {
      color: darkgray;
    }
  }

  .tab-content {
    overflow: visible;

    .buttons {
      width: 100%;
    }
  }

  .tag-type-input {
    &__label {
      align-items: center;
      display: flex;
    }
  }

}
