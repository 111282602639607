telephone {
  display: flex;
  align-items: center;
}
telephone .telephone-input {
  position: relative;
  min-width: 22rem;
  width: 100%;
}
telephone .telephone-input__input {
  padding-right: 3rem;
}
telephone .telephone-input__label {
  align-items: center;
  display: flex;
  right: 1rem;
  color: var(--text-color-light);
  font-size: var(--font-size-s);
  font-weight: var(--font-weight-semibold);
  height: var(--input-height);
  position: absolute;
  top: 0;
}
