#customer-term-deposits {

  .print-pledge-btn {
    margin: 5px;
  }

}

#customer-deposit-accounts-cash-action {


    .cash-amount-picker {
      margin-top: 20px;
    }

    .buttons {
      float: right;
      margin-top: 40px;
      margin-right: 40px;
    }

}