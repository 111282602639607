customer-account-details-print {

    td {
      &:first-child {
        font-weight: var(--font-weight-semibold);
      }

      &.separator {
        background: transparent!important;
      }
    }

  @media print {
    font-size: var(--font-size-xl);

    print-header {
      display: block;
      margin-bottom: 12px;
      td {
        padding: 12px 0;
      }
    }

    .no-print{
      display: none;
    }
  }

}