risk-category-details-view .sub-header {
  white-space: normal;
  word-wrap: break-word;
}
risk-category-details-view form {
  margin: 0;
}
risk-category-details-view div.form-section {
  box-sizing: border-box;
  width: 500px;
  float: left;
  padding: 0 40px 0 20px;
}
risk-category-details-view .file-upload {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-evenly;
  align-items: center;
}
risk-category-details-view .buttons {
  float: right;
  margin: 20px;
}
risk-category-details-view .download-btn {
  width: 150px;
}
risk-category-details-view .risk-category-entry__table {
  box-sizing: border-box;
  margin: 20px;
  width: calc(100% - 40px);
}
risk-category-details-view .risk-category-entry__table input,
risk-category-details-view .risk-category-entry__table select {
  margin: 0;
}
risk-category-details-view .risk-category-entry__table input.input--small-number {
  width: 100px;
}
risk-category-details-view .risk-category-entry__table input.input--description {
  width: 300px;
}
risk-category-details-view .risk-category-entry__table select.select--risk-level {
  width: 120px;
}
risk-category-details-view .risk-category-entry__table .no-column-header {
  width: 40px;
}
risk-category-details-view .risk-category-entry__table th.th--description {
  width: 300px;
}
risk-category-details-view .risk-category-entry__table th.th--conditions {
  width: 50%;
}
risk-category-details-view .risk-category-entry__table td .conditions {
  display: flex;
}
risk-category-details-view .risk-category-entry__table td:last-child,
risk-category-details-view .risk-category-entry__table th:last-child {
  width: 90px;
}
risk-category-details-view .risk-category-entry__button {
  width: 90px;
}
