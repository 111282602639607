prints-details #prints-details__pdfWrapper {
  background: white;
  height: 0;
  overflow: hidden;
  padding-bottom: 56%;
  position: relative;
  width: 100%;
}
prints-details #prints-details__pdfWrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
prints-details .prints-details__iframe-hidden {
  visibility: hidden;
}
prints-details .print-details__control-input {
  width: 60px;
}
prints-details .print-details__control-number {
  width: 20px;
}
