.customer-picker {
  .picker-form {
    display: flex;

    input {
      // override bootstrap
      margin-bottom: 0;
      margin-right: var(--element-side-gap);
    }
  }

  .picker-content {
    border: 1px solid var(--light-line-color);
    padding-left: 2rem;
    padding-right: 2rem;
    position: absolute;
    background-color: white;

    max-height: 305px;
    overflow-y: auto;
    z-index: var(--z-index-dropdowns);

    table {
      margin-bottom: 0;

      a.btn {
        margin-left: 20px;
      }

      td {
        &:first-child {
          &::before {
            display: none !important;
          }
        }
      }
    }
  }

  .no-results {
    color: var(--text-color-light);
    font-size: var(--font-size-m);
    margin: 10px 15px;
  }
}