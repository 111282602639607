
  .gl-mappings-copy-container {
    float: right;
    .input-prepend {
      margin: 0 5px 0 0;
      label {
        margin: 0;
      }
    }
  }

  .gl-mappings__table {

    .no-column-header {
      width: 15px;
    }

    input[type='checkbox'] {
      width: 16px;
      height: 16px;
    }

    .centered-cell {
      text-align: center;
      vertical-align: middle;
    }

    .col-name {
      width: 30%
    }

    .col-delegate {
      width: 70px;
    }

    .col-account {
      width: 350px;
    }

    .col-delegate-tag {
      width: 350px
    }

    .disabled-select {
      margin: 0;
    }
  }

  .missing-tags-message {
    padding: 15px 10px;
  }
