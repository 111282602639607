@import '~@nextbank/bootstrap/less/variables';

center-picker {
  input.lookup-input {
    margin-bottom: 5px;
    width: 100%;
    max-width: 200px;
  }

  .search-btn {
    margin-left: 5px;
  }

  .content {
    border: 1px solid @grayLight;
    padding: 10px;
    max-height: 305px;
    min-width: 700px;
    overflow-y: auto;
    overflow-x: hidden;

    table {
      a.btn {
        margin-left: 20px;
      }
    }
  }

  .no-results {
    min-width: 400px;
    color: gray;
    text-shadow: 1px 1px 0 @grayLight;
    font-size: 15px;
    margin: 10px 15px;
  }
}