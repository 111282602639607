ent {
  display: block;
}
ent.ng-invalid input {
  border: 2px solid var(--danger-color);
}
ent .ent {
  margin: 0;
  padding: 0;
}
ent .ent__input {
  align-items: center;
  display: flex;
}
ent .ent__input .btn {
  height: var(--input-height);
  margin-left: 2rem;
}
ent .ent__tree-search {
  align-items: center;
  display: flex;
  padding: 10px 0;
  padding-left: 5px;
  margin: 0 -5px;
}
ent .ent__tree-search > * {
  margin: 0 5px !important;
}
ent .ent__tree-element a {
  color: unset;
  font-family: inherit;
  text-decoration: none !important;
}
ent .ent__tree-element.editable .btn {
  background: var(--white);
  font-size: var(--font-size-s);
  min-height: var(--small-button-height) !important;
  min-width: 3rem !important;
}
ent .ent__tree-element .btn.icon:before {
  height: 1rem;
  width: 1rem;
}
