@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 300;
  src: url("../dist/Nunito-Regular_48e271e3.eot");
  src: url("../dist/Nunito-Regular_48e271e3.eot?#iefix") format("embedded-opentype"), url("../dist/Nunito-Regular_d5db7a3a.woff") format("woff"), url("../dist/Nunito-Regular_b534f771.ttf") format("truetype");
}
@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: normal;
  src: url("../dist/Nunito-SemiBold_d12bd1b5.eot");
  src: url("../dist/Nunito-SemiBold_d12bd1b5.eot?#iefix") format("embedded-opentype"), url("../dist/Nunito-SemiBold_2e91e6a0.woff") format("woff"), url("../dist/Nunito-SemiBold_99875ad5.ttf") format("truetype");
}
@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: bold;
  src: url("../dist/Nunito-Bold_1c4228dd.eot");
  src: url("../dist/Nunito-Bold_1c4228dd.eot?#iefix") format("embedded-opentype"), url("../dist/Nunito-Bold_b455fa08.woff") format("woff"), url("../dist/Nunito-Bold_f9df2a4d.ttf") format("truetype");
}
.MuiPickersDatePickerRoot-toolbar {
  font-size: 1.6rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  height: 3rem;
}
.MuiPickersToolbarText-toolbarTxt,
.MuiPickersToolbarButton-toolbarBtn,
.MuiPickersToolbarText-toolbarBtnSelected {
  font-size: 1.4rem;
}
.MuiPickersYear-root {
  font-size: 1.4rem;
  transition: 0.2s ease;
  height: 3rem;
}
.MuiPickersYear-root:hover {
  background: #f5f5f5;
}
.MuiPickersYear-yearSelected {
  font-size: 1.4rem;
  margin-top: 0;
  margin-bottom: 0;
  background: #ebebeb;
}
.MuiPickersYear-yearSelected:hover {
  background: #ebebeb;
}
.MuiTouchRipple-child {
  background: #a8bdff;
}
html {
  font-size: 10px;
}
body {
  margin: 0;
  overflow-y: scroll;
  min-width: 768px;
  padding: 0;
}
body * {
  font-family: "Nunito", "Open Sans", sans-serif;
  font-weight: normal;
}
.MuiScopedCssBaseline-root {
  font-size: 1.4rem;
  color: #3f3e3e;
}
.MuiScopedCssBaseline-root a {
  text-decoration: none;
}
.MuiScopedCssBaseline-root h1 {
  font-size: 3.2rem;
  margin-bottom: 1.5rem;
  margin-top: 0;
}
.NxLoader__contentSpinner--3HTjoTeEKa8aKFmJFWvYtF {
  height: 6.4rem;
  width: 6.4rem;
  margin-bottom: 3rem;
  margin-left: auto;
  margin-right: auto;
  margin-top: 3rem;
  position: relative;
}
.NxLoader__contentSpinner--3HTjoTeEKa8aKFmJFWvYtF .NxLoader__fragment--3w6wgJcH6sLq6eEOCLdRZT {
  height: 0.5rem;
  width: 0.5rem;
  animation: NxLoader__circlesResizing--Si6uaoLf6EU2uoKWoj_Uu 1.2s linear infinite;
  background: #fff;
  border-radius: 50%;
  position: absolute;
}
@keyframes NxLoader__circlesResizing--Si6uaoLf6EU2uoKWoj_Uu {
  20%,
  80% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.5);
  }
}
.NxLoader__contentSpinner--3HTjoTeEKa8aKFmJFWvYtF .NxLoader__fragment_primary--3IUW3y6cRh27897xRNsSWe {
  background: #4d7cfe;
}
.NxLoader__contentSpinner--3HTjoTeEKa8aKFmJFWvYtF .NxLoader__fragment_textColor--3rNjhiY4IjmerlttrBjLkX {
  background: #778699;
}
.NxLoader__contentSpinner--3HTjoTeEKa8aKFmJFWvYtF .NxLoader__fragment--3w6wgJcH6sLq6eEOCLdRZT:nth-child(1) {
  animation-delay: calc(1 * -0.1s);
  left: 5.3rem;
  top: 2.9rem;
}
.NxLoader__contentSpinner--3HTjoTeEKa8aKFmJFWvYtF .NxLoader__fragment--3w6wgJcH6sLq6eEOCLdRZT:nth-child(2) {
  animation-delay: calc(2 * -0.1s);
  left: 5rem;
  top: 1.8rem;
}
.NxLoader__contentSpinner--3HTjoTeEKa8aKFmJFWvYtF .NxLoader__fragment--3w6wgJcH6sLq6eEOCLdRZT:nth-child(3) {
  animation-delay: calc(3 * -0.1s);
  left: 4.1rem;
  top: 0.9rem;
}
.NxLoader__contentSpinner--3HTjoTeEKa8aKFmJFWvYtF .NxLoader__fragment--3w6wgJcH6sLq6eEOCLdRZT:nth-child(4) {
  animation-delay: calc(4 * -0.1s);
  left: 2.9rem;
  top: 0.6rem;
}
.NxLoader__contentSpinner--3HTjoTeEKa8aKFmJFWvYtF .NxLoader__fragment--3w6wgJcH6sLq6eEOCLdRZT:nth-child(5) {
  animation-delay: calc(5 * -0.1s);
  left: 1.8rem;
  top: 0.9rem;
}
.NxLoader__contentSpinner--3HTjoTeEKa8aKFmJFWvYtF .NxLoader__fragment--3w6wgJcH6sLq6eEOCLdRZT:nth-child(6) {
  animation-delay: calc(6 * -0.1s);
  left: 0.9rem;
  top: 1.8rem;
}
.NxLoader__contentSpinner--3HTjoTeEKa8aKFmJFWvYtF .NxLoader__fragment--3w6wgJcH6sLq6eEOCLdRZT:nth-child(7) {
  animation-delay: calc(7 * -0.1s);
  left: 0.6rem;
  top: 2.9rem;
}
.NxLoader__contentSpinner--3HTjoTeEKa8aKFmJFWvYtF .NxLoader__fragment--3w6wgJcH6sLq6eEOCLdRZT:nth-child(8) {
  animation-delay: calc(8 * -0.1s);
  left: 0.9rem;
  top: 4.1rem;
}
.NxLoader__contentSpinner--3HTjoTeEKa8aKFmJFWvYtF .NxLoader__fragment--3w6wgJcH6sLq6eEOCLdRZT:nth-child(9) {
  animation-delay: calc(9 * -0.1s);
  left: 1.8rem;
  top: 5rem;
}
.NxLoader__contentSpinner--3HTjoTeEKa8aKFmJFWvYtF .NxLoader__fragment--3w6wgJcH6sLq6eEOCLdRZT:nth-child(10) {
  animation-delay: calc(10 * -0.1s);
  left: 2.9rem;
  top: 5.3rem;
}
.NxLoader__contentSpinner--3HTjoTeEKa8aKFmJFWvYtF .NxLoader__fragment--3w6wgJcH6sLq6eEOCLdRZT:nth-child(11) {
  animation-delay: calc(11 * -0.1s);
  left: 4.1rem;
  top: 5rem;
}
.NxLoader__contentSpinner--3HTjoTeEKa8aKFmJFWvYtF .NxLoader__fragment--3w6wgJcH6sLq6eEOCLdRZT:nth-child(12) {
  animation-delay: calc(12 * -0.1s);
  left: 5rem;
  top: 4.1rem;
}
.NxLoader__buttonSpinner--2Rd9_tzFLNYpKRyOtXTg3T {
  align-items: center;
  border-radius: 0.4rem;
  box-sizing: border-box;
  cursor: default;
  display: flex;
  height: calc(100% + 2px);
  left: -1px;
  pointer-events: none;
  position: absolute;
  top: -1px;
  width: calc(100% + 2px);
}
.NxLoader__buttonSpinner--2Rd9_tzFLNYpKRyOtXTg3T .NxLoader__fragment--3w6wgJcH6sLq6eEOCLdRZT {
  animation: NxLoader__ringSpinning--6e3lcNxweHTGXyW9OXVEG 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #fff transparent transparent;
  border-radius: 50%;
  border-style: solid;
  border-width: 2px;
  box-sizing: border-box;
  height: 1.6rem;
  left: 0;
  margin-left: auto;
  margin-right: auto;
  position: absolute;
  right: 0;
  width: 1.6rem;
}
@keyframes NxLoader__ringSpinning--6e3lcNxweHTGXyW9OXVEG {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.NxLoader__buttonSpinner--2Rd9_tzFLNYpKRyOtXTg3T .NxLoader__fragment_primary--3IUW3y6cRh27897xRNsSWe {
  border-color: #4d7cfe transparent transparent;
}
.NxLoader__buttonSpinner--2Rd9_tzFLNYpKRyOtXTg3T .NxLoader__fragment_textColor--3rNjhiY4IjmerlttrBjLkX {
  border-color: #778699 transparent transparent;
}
.NxLoader__buttonSpinner--2Rd9_tzFLNYpKRyOtXTg3T .NxLoader__fragment--3w6wgJcH6sLq6eEOCLdRZT:nth-child(1) {
  animation-delay: calc(1 * -0.15s);
}
.NxLoader__buttonSpinner--2Rd9_tzFLNYpKRyOtXTg3T .NxLoader__fragment--3w6wgJcH6sLq6eEOCLdRZT:nth-child(2) {
  animation-delay: calc(2 * -0.15s);
}
.NxLoader__buttonSpinner--2Rd9_tzFLNYpKRyOtXTg3T .NxLoader__fragment--3w6wgJcH6sLq6eEOCLdRZT:nth-child(3) {
  animation-delay: calc(3 * -0.15s);
}
.NxButton__icon--3UNQiVEOhOLE86N-hrGpvg {
  margin-left: -0.5rem;
  margin-right: 0.5rem;
}
.NxButton__icon_end--hk1oKxhNoO72lawqv5ur9 {
  margin-left: 0.5rem;
  margin-right: -0.5rem;
}
.NxButton__icon--3UNQiVEOhOLE86N-hrGpvg g,
.NxButton__icon--3UNQiVEOhOLE86N-hrGpvg path {
  transition: 0.2s ease;
}
.NxButton__closeIcon_white--HN-rfrujQT1cnFxrZjLCj path {
  fill: #fff;
}
.NxButton__button--2PAhKSYZYSso5q3qVGoDuS {
  transition: 0.2s ease;
  border-color: #4d7cfe;
  border-width: 2px !important;
  height: 3.8rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}
.NxButton__button_disabled--3wzzVsTAkPohVURQ-hTv-L .NxButton__undoIcon--1rrM3f52XRYJcZoqT2eJgm path,
.NxButton__button_disabled--3wzzVsTAkPohVURQ-hTv-L .NxButton__addIcon--2Xsf7l7V3vQ5EX0XrCXbul path,
.NxButton__button_disabled--3wzzVsTAkPohVURQ-hTv-L .NxButton__closeIcon--1Wqk3KGn9q-E3HgtPMUHxP path {
  fill: rgba(0, 0, 0, 0.26);
}
.NxButton__button_disabled--3wzzVsTAkPohVURQ-hTv-L .NxButton__saveIcon--SGFgsxPTKi9Auz2lQ9j13 path,
.NxButton__button_disabled--3wzzVsTAkPohVURQ-hTv-L .NxButton__deleteIcon--NnCT-OakeikkFtoiLFj24 path {
  stroke: rgba(0, 0, 0, 0.26);
}
.NxButton__button_disabled--3wzzVsTAkPohVURQ-hTv-L .NxButton__arrowIcon--3FHBQ6EArt_kBAJvVWzMNy g {
  fill: rgba(0, 0, 0, 0.26);
}
.NxButton__button--2PAhKSYZYSso5q3qVGoDuS:hover {
  border-width: 2px !important;
}
.NxButton__label--1Zw_qeM9s1ROxGpnlrj5W5 {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.NxButton__hidden--2lrPH-O72FdckEAIC6XV2z {
  opacity: 0;
}
/* stylelint-disable no-descending-specificity */
.NxGenericTextField__fieldWrapper--AEaLXihD7IoJJ_o-ZR02C {
  display: flex;
  flex-direction: column;
  position: relative;
}
.NxGenericTextField__field--1e66yP7jOR79AiIbAVz-fH {
  transition: 0.2s ease;
  max-width: 37.5rem;
  width: 100%;
}
.NxGenericTextField__input--aJUmqxMx9s9oOQCRdeEEY {
  font-size: 1.2rem;
  border: 0;
  border-radius: 0.4rem;
  box-sizing: border-box;
  color: #3f3e3e;
  min-height: 3.4rem;
  line-height: 2rem;
  padding-bottom: 0.4rem;
  padding-left: 1.3rem;
  padding-right: 1.3rem;
  padding-top: 1.5rem;
  transition: border-color 0.2s ease, background-color 0.2s ease;
}
.NxGenericTextField__input_textarea--3lVPQVx89HeVIPXAzj22n8 {
  padding-bottom: 0;
  padding-left: 0.1rem;
  padding-right: 0.1rem;
  padding-top: 0;
  margin-top: 1.4rem;
  min-height: 1.8rem;
}
.NxGenericTextField__input--aJUmqxMx9s9oOQCRdeEEY::-webkit-outer-spin-button,
.NxGenericTextField__input--aJUmqxMx9s9oOQCRdeEEY::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.NxGenericTextField__input--aJUmqxMx9s9oOQCRdeEEY:-webkit-autofill,
.NxGenericTextField__input--aJUmqxMx9s9oOQCRdeEEY:-webkit-autofill:hover,
.NxGenericTextField__input--aJUmqxMx9s9oOQCRdeEEY:-webkit-autofill:focus,
.NxGenericTextField__input--aJUmqxMx9s9oOQCRdeEEY:-webkit-autofill:active {
  -webkit-transition: 0.2s ease;
  transition: 0.2s ease;
  -webkit-box-shadow: 0 0 0 3rem #eef4fa inset !important;
}
.NxGenericTextField__input--aJUmqxMx9s9oOQCRdeEEY:-webkit-autofill:disabled,
.NxGenericTextField__input--aJUmqxMx9s9oOQCRdeEEY:-webkit-autofill:hover:disabled,
.NxGenericTextField__input--aJUmqxMx9s9oOQCRdeEEY:-webkit-autofill:focus:disabled,
.NxGenericTextField__input--aJUmqxMx9s9oOQCRdeEEY:-webkit-autofill:active:disabled {
  -webkit-box-shadow: 0 0 0 3rem #fff inset !important;
}
.NxGenericTextField__input--aJUmqxMx9s9oOQCRdeEEY[type=number] {
  -moz-appearance: textfield;
}
.NxGenericTextField__inputWrapper--2lNoyIaN1Q6V0KzkxEgutt {
  background: #eef4fa !important;
  border-radius: 0.4rem;
  border: 2px solid transparent;
  min-height: 3.8rem;
  margin-top: 0 !important;
  transition: border-color 0.2s ease, background-color 0.2s ease;
}
.NxGenericTextField__inputWrapper--2lNoyIaN1Q6V0KzkxEgutt::after,
.NxGenericTextField__inputWrapper--2lNoyIaN1Q6V0KzkxEgutt::before {
  display: none;
}
.NxGenericTextField__inputWrapper_disabled--1oydySVotWGzUH0CpYVUh9 {
  background: none !important;
  border-color: #e8eff7;
}
.NxGenericTextField__inputWrapper_textarea--2fcU5hbHucNlGDY-tzUtGr {
  padding-bottom: 0;
  padding-top: 0;
}
.NxGenericTextField__inputWrapper_focused--3GlTgg_lC6f7GLkurPEgwU {
  border-color: #4d7cfe;
}
.NxGenericTextField__inputWrapper_error--3I7zCUIirakzr0RRi_i42R {
  border-color: #f84c1e;
}
.NxGenericTextField__inputWrapper_end--3xGfBolh8N5V6ZIvCwDzq1 {
  padding-right: 0;
}
.NxGenericTextField__error--32QtxzQcQ8wBW6YRI6BNh_ {
  font-size: 1.2rem;
  color: #f84c1e;
  margin-top: 0.1rem;
}
.NxGenericTextField__label--2UQyFj953mIJGPDurbEvEL {
  font-size: 1.2rem;
  color: #778699 !important;
  pointer-events: none;
  transform: translate(1.5rem, 1.3rem) scale(1);
  z-index: 2;
}
.NxGenericTextField__label_shrink--2VQou_nAs8voSNY0Z7oLs1 {
  transform: translate(1.5rem, 0.6rem) scale(0.8) !important;
}
.NxCheckbox__wrapper--2mTBXwgEFnZ8bO9vO5DHuq {
  position: relative;
}
.NxCheckbox__checkbox--xWB-72Tz8p6P2A7Qdd_BW {
  color: #dce1e7;
  padding: 1rem;
}
.NxCheckbox__checkbox--xWB-72Tz8p6P2A7Qdd_BW svg {
  height: 2.4rem;
  width: 2.4rem;
}
.NxCheckbox__row--1o7OAxzn6Jc9WSFHFHkyUs {
  transition: 0.2s ease;
  align-items: center;
  display: flex;
  margin-left: -1rem;
}
.NxCheckbox__row_translucent---KI4jlldE-0Bvqmndl_BA {
  opacity: 0.5;
}
.NxCheckbox__requiredMarkError--r6TE1VM8809dI7DZw3hMx {
  color: #f84c1e;
}
.NxDatePicker__datePicker--1Is6no42BJ5tSmnI87bktR {
  margin-top: 3.8rem;
}
.NxDatePicker__iconWrapper--1ue1ZTLG1GKGIdQIY_4Kl3 {
  height: 3.8rem;
  max-height: 3.8rem;
  position: absolute;
  right: 1rem;
  top: 0;
}
.NxDatePicker__iconWrapper--1ue1ZTLG1GKGIdQIY_4Kl3 button {
  padding: 0.7rem;
}
.NxDatePicker__error--1MLFrNaPv1Y88sPzg9-9eN {
  font-size: 1.2rem;
  color: #f84c1e;
  margin-top: 0.5rem;
}
.NxIconButton__icon--1LdyIyXa6QkJWizUgcqw4H {
  padding: 0;
}
.InputEndAdornment__inputAdornment--2vGb6d_LQf9QGPH9L7vEQI {
  align-items: center;
  display: flex;
  height: 3.4rem;
  margin-left: 0;
  margin-right: 1rem;
  max-height: 3.4rem;
}
.InputEndAdornment__arrowsWrapper--21TgRfw3d7bWpSZJ6AQnj4 {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.InputEndAdornment__arrow--g-wp9x_nCHhcgPwAdNY3S {
  padding: 0.3rem;
  transition: 0.3s ease;
}
.InputEndAdornment__arrow_limitReached--28dQbKH2jVDspBXLHM4FG- {
  cursor: default;
  opacity: 0.3;
}
.InputEndAdornment__arrow_hidden--2VjgbpJbiaNPq2jUAq8HyM {
  opacity: 0;
}
.InputEndAdornment__endAdornment--29HmWKwzZ8leCm332dWmKA {
  align-items: center;
  color: #778699;
  display: flex;
  border-left: 1px solid #d7e1ea;
  height: 3.4rem;
  justify-content: center;
  min-width: 3.6rem;
  padding-left: 1rem;
}
.NxMultiSelect__select--3fj-2vGQGmtwwuEK8Cx6bO {
  height: 3.4rem;
  min-height: 0;
}
.NxMultiSelect__select_filled--21wCpM7aVp4-0LuA0bbme_ {
  background: transparent !important;
  padding-top: 1.3rem;
}
.NxMultiSelect__icon--bmDfFmkQKRy_I70F--QtU {
  transition: 0.3s ease;
}
.NxMultiSelect__menu--2k32cxRImOEWFgeo3CA_4x {
  background: #eef4fa;
  padding: 0;
}
.NxMultiSelect__menuWrapper--3tmU9XVFwzGmn1_rvgIOLf {
  border-top: 1px solid #d7e1ea;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  box-shadow: 0 0.5rem 1.5rem 0 rgba(74, 80, 91, 0.12);
  margin-top: 0.2rem;
}
.NxMultiSelect__item--1lx7pxEuhZM0NbKJyZ00EP {
  transition: 0.2s ease;
  font-size: 1.2rem;
  padding-bottom: 0;
  padding-left: 0;
  padding-top: 0;
}
.NxMultiSelect__item_selected--2fkqAD2IK9yeI0jvA4BNb6 {
  background: transparent !important;
}
.NxMultiSelect__label--2FycXKINg7t9D6vUvKxvR6 {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 37.5rem;
}
.PasswordVisibilityButton__icon--V7338N6SAHQKI4guMq1sx {
  padding: 0.5rem;
}
.NxPasswordInput__iconWrapper--2pGLsNeu3SPLSe0bunV9GN {
  height: 3.8rem;
  max-height: 3.8rem;
  transform: translateX(-1rem);
}
.NxPopup__popup--1wxB7PUSiN9YApCSN7zZaO {
  min-width: 32rem;
  padding: 3rem;
}
.NxPopup__header--1TYE1F7eZTbOhqhtvtKoFC {
  font-size: 2.4rem;
  margin-bottom: 1.5rem;
  text-align: center;
}
.NxPopup__description--2uK0TlKUvqbURRPsu9_Yh0 {
  font-size: 1.2rem;
  color: #778699;
  margin-left: auto;
  margin-right: auto;
  max-width: 25rem;
  text-align: center;
}
.NxPopup__row--_Uk7F7zX4_jFHQ9rN-0Sq {
  display: flex;
  margin-top: 7.5rem;
}
.NxPopup__row_single--MVKetu6yz4UUJk_Iug_Dt {
  justify-content: center;
}
.NxPopup__row_multiple--2luiu92bWbttDypUaF77er {
  justify-content: space-between;
}
.NxRadio__radio--UR_-Y5177E0aMmz9P7XFD {
  margin-left: -0.5rem;
  margin-right: 0.5rem;
  padding: 0.5rem;
}
.NxRadio__radio--UR_-Y5177E0aMmz9P7XFD svg {
  height: 1.8rem;
  width: 1.8rem;
}
.NxRadio__radio--UR_-Y5177E0aMmz9P7XFD svg:first-child {
  fill: #dce1e7;
}
.NxRadio__label--1Zx0a68L-y4OHaCqlRpS8N {
  font-size: 1.4rem;
  margin-left: 0;
}
.NxRadio__row--2Z7E_ZCvyRkqc5Rzy6s7w8 {
  flex-direction: row;
}
.NxRow__container--303qXv6FgRK70wKKeJkbXQ {
  display: flex;
}
.NxRow__container--303qXv6FgRK70wKKeJkbXQ.NxRow__xs--1xP1pPwUnFaC0QCTBvrqOd > * {
  margin-right: 0.5rem;
}
.NxRow__container--303qXv6FgRK70wKKeJkbXQ.NxRow__s--3zvtY3wz33qxdMpJ58kY6u > * {
  margin-right: 1rem;
}
.NxRow__container--303qXv6FgRK70wKKeJkbXQ.NxRow__m--LJLcmMuR3XthhxoMukR5R > * {
  margin-right: 1.5rem;
}
.NxRow__container--303qXv6FgRK70wKKeJkbXQ.NxRow__l--39LBVhCSwA_tnIhyzqy90P > * {
  margin-right: 2rem;
}
.NxRow__container--303qXv6FgRK70wKKeJkbXQ.NxRow__xl--38bxp2GbqHSOdGfMDDGZgB > * {
  margin-right: 3rem;
}
.NxRow__container--303qXv6FgRK70wKKeJkbXQ > * {
  margin-right: 2rem;
}
.NxRow__container--303qXv6FgRK70wKKeJkbXQ > *:last-child {
  margin-right: 0;
}
.NxRow__flex-start--39w3h4hd3uMVV8fdROu0cw {
  justify-content: flex-start;
}
.NxRow__center--3Y8-qskLmTFoOWk2ZJbd6p {
  justify-content: center;
}
.NxRow__flex-end--Cn1Te21Ud7Mp9NW3HjV0Y {
  justify-content: flex-end;
}
.NxSelect__select--MWwYlM_CKKhom8kMF9J1W {
  height: 3.4rem;
  min-height: 0;
}
.NxSelect__select_filled--1GhU8x_XqbfgzAxSOGFScu {
  background: transparent !important;
  padding-top: 1.3rem;
}
.NxSelect__icon--39y2d2VoSS4ntwTW6qhKYh {
  transition: 0.3s ease;
}
.NxSelect__menu--3hIp18gPJ_NZfiyGe5jv2h {
  background: #eef4fa;
  padding: 0;
}
.NxSelect__menuWrapper--39pSoGKpBeI3ZwjjUPl7jS {
  border-top: 1px solid #d7e1ea;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  box-shadow: 0 0.5rem 1.5rem 0 rgba(74, 80, 91, 0.12);
  margin-top: 0.2rem;
}
.NxSelect__item--e-9HL9Qz0itNw19dN7_39 {
  transition: 0.2s ease;
}
.NxSwitch__switch--2Xa9WJyWrZ64aR5NpWuWVD {
  height: 2.2rem;
  margin-right: 1rem;
  padding: 0;
  width: 4.2rem;
}
.NxSwitch__switchBase--22LELYG2imOIMeKsrLk5PP {
  left: 0.4rem;
  padding: 0;
  top: 0.4rem;
}
.NxSwitch__thumb--uWOHLiKH-jwX7zwMZumdi {
  height: 1.4rem;
  width: 1.4rem;
  background: #fff;
  box-shadow: none;
}
.NxSwitch__track--2LYVmSzv2yKuWpEy7WPcXE {
  background: #e8eff7;
  border-radius: 0.6rem;
  opacity: 1 !important;
}
.NxSwitch__input--MHYnNe6mqP7nwdkK2Jq_M {
  height: 2.2rem;
  width: 2.2rem;
  left: -0.4rem;
  top: -0.4rem;
}
.NxStack__container--53VLNXrr-8o-TsA_D2Vd6.NxStack__xs--1tclElgCBIREkw_G-JNR9W > * {
  margin-bottom: 0.5rem;
}
.NxStack__container--53VLNXrr-8o-TsA_D2Vd6.NxStack__s--kMq78bBFIuazC83PaQWz4 > * {
  margin-bottom: 1rem;
}
.NxStack__container--53VLNXrr-8o-TsA_D2Vd6.NxStack__m--1MtfASE-6Vwt1C6XdoZv0- > * {
  margin-bottom: 1.5rem;
}
.NxStack__container--53VLNXrr-8o-TsA_D2Vd6.NxStack__l--3qoTYBjE6SgihOcSradqN6 > * {
  margin-bottom: 2rem;
}
.NxStack__container--53VLNXrr-8o-TsA_D2Vd6.NxStack__xl--2a0oEgHgROd5Sg1X5VOGt8 > * {
  margin-bottom: 3rem;
}
.NxStack__container--53VLNXrr-8o-TsA_D2Vd6.NxStack__xs--1tclElgCBIREkw_G-JNR9W > * {
  margin-bottom: 0.5rem;
}
.NxStack__container--53VLNXrr-8o-TsA_D2Vd6 > *:last-child {
  margin-bottom: 0;
}
.NxTabs__tabs--bw0gJqnqeUiBBYSIRxRV6 {
  min-height: 3.7rem;
}
.NxTabs__tab--1GZo4h2kwXE4CCSVbboyjA {
  font-size: 1.4rem;
  border-bottom: 1px solid #d7e1ea;
  color: #778699;
  min-height: 3.7rem;
  min-width: auto;
  opacity: 1;
  padding-left: 0;
  padding-right: 2.5rem;
}
.NxTabs__tab_selected--1WfqFT4nhvUwjNvB6LAAG {
  color: #3f3e3e;
}
.NxTabs__label--1e9LAVQ3zCfd4adsora1ok {
  align-items: center;
  display: flex;
}
.NxTabs__iconWrapper--11_k4f7vGoqeh31aaHrh4m {
  align-items: center;
  box-sizing: border-box;
  display: flex;
  margin-right: 0.1rem;
  margin-top: -0.5rem;
  width: 2.5rem;
}
.NxTabs__indicator--SHxdbFdb4hlbdplFBBDvH {
  background: transparent;
  display: flex;
  justify-content: center;
}
.NxTabs__indicatorThumb--1jkyQa-erRApEBToz8OTy9 {
  background: #4d7cfe;
  border-radius: 0.2rem;
  height: 0.4rem;
  max-width: 2rem;
  width: 100%;
}
.NxTabs__tabPanel--1abZrzD7FkGx3h2A2E_eCq {
  margin-top: 3.5rem;
}
.NxTable__root--1PNz8BrdCT07RvgrOCK8CR .MuiTableRow-root {
  font-size: 1.2rem;
}
.NxTable__root--1PNz8BrdCT07RvgrOCK8CR .MuiTableCell-head {
  font-size: 1.2rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  border-bottom: unset;
  z-index: unset;
}
.NxTable__root--1PNz8BrdCT07RvgrOCK8CR .MuiTableCell-root {
  padding: 1rem;
}
.NxTable__root--1PNz8BrdCT07RvgrOCK8CR .MuiTableCell-root:first-of-type {
  padding-left: 1.5rem;
}
.NxTable__root--1PNz8BrdCT07RvgrOCK8CR .MuiTableCell-root:last-of-type {
  padding-right: 1.5rem !important;
}
.NxTable__root--1PNz8BrdCT07RvgrOCK8CR .MuiTableCell-root .MuiTableCell-body {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}
.NxTable__root--1PNz8BrdCT07RvgrOCK8CR .MuiTableFooter-root .MuiTableCell-root {
  border-bottom: 0;
}
.NxTable__root--1PNz8BrdCT07RvgrOCK8CR .MuiTableFooter-root .MuiTablePagination-root {
  padding: 0 0.5rem;
}
.NxTable__root--1PNz8BrdCT07RvgrOCK8CR .MuiTableFooter-root .MuiTablePagination-root .MuiIconButton-root {
  padding: 0.5rem;
}
.NxTable__root--1PNz8BrdCT07RvgrOCK8CR .MuiTableFooter-root .MuiTablePagination-root .MuiTypography-caption {
  padding: 0 0.5rem;
  font-size: 1.2rem;
}
.NxTable__root--1PNz8BrdCT07RvgrOCK8CR .MuiTableFooter-root .MuiTablePagination-root .MuiTablePagination-selectRoot {
  padding: 0 0.5rem;
}
.NxTable__root--1PNz8BrdCT07RvgrOCK8CR .MuiTableFooter-root .MuiTablePagination-root .MuiTablePagination-selectRoot .MuiSelect-select.MuiSelect-select {
  padding-right: 1.5rem;
}
.NxTable__root--1PNz8BrdCT07RvgrOCK8CR .MuiTableFooter-root .MuiTablePagination-root .MuiTablePagination-selectRoot .MuiSelect-icon {
  font-size: 1.6rem;
  right: 0.5rem;
  top: calc(50% - 1em/2);
}
.NxTable__root--1PNz8BrdCT07RvgrOCK8CR .MuiTableBody-root .MuiTableRow-root .MuiTableCell-root:first-of-type {
  position: relative;
}
.NxTable__root--1PNz8BrdCT07RvgrOCK8CR .MuiTableBody-root .MuiTableRow-root .MuiTableCell-root:first-of-type::before {
  background: #4d7cfe;
  border-bottom-right-radius: 0.1rem;
  border-top-right-radius: 0.1rem;
  content: '';
  display: block;
  height: 0;
  left: 0;
  opacity: 0;
  position: absolute;
  width: 0.3rem;
  top: 20%;
  transition: all 300ms ease 0s;
}
.NxTable__root--1PNz8BrdCT07RvgrOCK8CR .MuiTableBody-root .MuiTableRow-root:hover {
  background: #e5ecff;
}
.NxTable__root--1PNz8BrdCT07RvgrOCK8CR .MuiTableBody-root .MuiTableRow-root:hover .MuiTableCell-root:first-of-type {
  position: relative;
}
.NxTable__root--1PNz8BrdCT07RvgrOCK8CR .MuiTableBody-root .MuiTableRow-root:hover .MuiTableCell-root:first-of-type::before {
  height: 60%;
  opacity: 1;
}
.NxTable__root--1PNz8BrdCT07RvgrOCK8CR [class*=Component-horizontalScrollContainer] ::-webkit-scrollbar {
  all: unset;
}
.NxTable__actionCell_body--3ixApP-5b137bkMfUsBRIM .MuiTableCell-head:nth-last-child(1) {
  text-align: right !important;
}
.NxTable__actionCell_header--3sETZ9E39fHP-RhVzbN8zk .MuiTableCell-body:nth-last-child(1) > div {
  justify-content: flex-end;
}
.NxTableActions__container--7JLxyKSBxC2mXjfFJwtp9 {
  padding: 0.5rem 0;
}
/* stylelint-disable scss/at-rule-no-unknown */
/* stylelint-enable */
