agents-details {
  .profile-body .tab-menu ul.nav {
    margin-bottom: 0;
  }

  .profile-body .tab-content {

    .form-header {
      color: darkgray;
      text-align: right;
      border-bottom: solid 1px lightgray;
      margin: 20px 20px 0 20px;
      padding-right: 10px;
    }
  }

  .profile-body hr {
    margin: 0;
    border: none;
    border-top: solid 1px lightgray;
  }

  .profile-body form {
    margin-bottom: 0;
    padding-top: 0;
  }

  #agent_documents .doc-form {
    min-height: 200px;
  }

  #agent_income hr {
    margin-top: 2px;
    margin-bottom: 30px;
  }


  #agent_income .template {
    display: none;
  }

  div[ng-transclude]:empty {
    display: none;
  }

  .alert.alert-info {
    padding: 10px;
    margin-bottom: 0;
  }

  .user-details {
    .buttons {
      padding-right: 20px;
    }
  }
}
