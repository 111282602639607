@import (less) '~inspire-tree-dom/dist/inspire-tree-light.min.css';

ent {
  border-radius: var(--default-border-radius);
}

ent-horde {
  display: block;
  background: white;
  margin: 0;

  .ent-horde__chosen-option {
    background: var(--input-background-color);
    box-sizing: border-box;
    color: var(--text-color-light);
    cursor: pointer;
    display: block;
    font-size: var(--font-size-m);
    height: var(--input-height);
    width: 100%;
  }

  .ent-horde__tree-wrapper {
    position: relative;
  }

  .ent-horde__tree {
    &-element.inspire-tree {
      background: white;
      border: 1px solid #ccc;
      box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
      left: 0;
      position: absolute;
      top: 0;
      z-index: var(--z-index-dropdowns);

      > ol {
        max-height: 600px;
        overflow: auto;
        padding: 5px 10px;
      }

      a {
        color: unset;
        cursor: pointer;
        font-size: var(--font-size-m);
        text-decoration: none !important;

        &.title.load-more {
          color: var(--text-color);
          font-family: inherit;
          font-weight: var(--font-weight-semibold);
        }
      }

      input[type="checkbox"] {
        top: 3px;
      }

      .wholerow {
        background-color: transparent !important;
        border: none !important;
      }

      ol {
        transform: translateZ(0);
      }
    }
  }
}
