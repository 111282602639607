credit-scoring-modal-customer-details {
  width: 100%;

  @avatarSize: 3;

  avatar {
    display: flex;
    justify-content: center;
    padding-bottom: var(--small-gap);
  }

  avatar-icon {
    display: block;
    position: relative;

    svg {
      height: calc(@avatarSize * var(--box-padding));
      width: calc(@avatarSize * var(--box-padding));

      circle {
        fill: var(--primary-color);
      }
    }
  }

  avatar-initials {
    color: var(--white);
    font-size: 2rem;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
  }

  .text {
    font-size: 2rem;
  }
}
