ent {
  .invalid-state() {
    border: 2px solid var(--danger-color);
  }

  display: block;

  &.ng-invalid {
    input {
      .invalid-state();
    }
  }

  .ent {
    margin: 0;
    padding: 0;

    &__input {
      align-items: center;
      display: flex;

      .btn {
        height: var(--input-height);
        margin-left: 2rem;
      }
    }

    &__tree {
      &-search {
        align-items: center;
        display: flex;
        padding: 10px 0;
        padding-left: 5px;
        margin: 0 -5px;

        & > * {
          margin: 0 5px !important;
        }
      }

      &-element {
        a {
          color: unset;
          font-family: inherit;
          text-decoration: none !important;
        }

        &.editable {
          .btn {
            background: var(--white);
            font-size: var(--font-size-s);
            min-height: var(--small-button-height) !important;
            min-width: 3rem !important;
          }
        }

        .btn.icon:before {
          height: 1rem;
          width: 1rem;
        }
      }
    }
  }

}