grouped-role-write-access-list {
  display: block;

  .btn-group.block {
    display: block;
  }

  .rule {

    td:first-child {
      width: 20%;
      word-break: break-word;
    }


    td:nth-child(3) {
      width: 15%
    }

    td:last-child {
      width: 10%;
    }

  }

  .predicates {
    display: flex;

    .predicate {
      max-width: 20rem;
    }
  }

  .flags {
    input[type=checkbox] {
      margin: 5px;
    }
  }

  .role-write-access_buttons {
    align-items: center;
    display: flex;
  }

  .btn-group {
    // add button
    height: fit-content;
    margin: 5px;
  }

}