@media print {
  customer-profile-body {
    display: block;

    // Remove border from all inputs when printing
    textarea, select, input[type="text"], input[type="password"], input[type="datetime"], input[type="datetime-local"], input[type="date"], input[type="month"], input[type="time"], input[type="week"], input[type="number"], input[type="email"], input[type="url"], input[type="search"], input[type="tel"], input[type="color"] {
      border: none;
    }

    // Hide lines at the end of page
    .profile-body .tab-content {
      border-bottom: none;
    }

    // Hide styling of appended fields (Feet, Inch etc.)
    span.add-on.glue-appended {
      border: none;
      background: transparent;
    }

    // Remove placeholders from print
    // For the date input a placeholder is date-format shown in a place of no value
    input[type="date"].nx-empty {
      color: transparent !important;
    }
    input::-webkit-input-placeholder { /* WebKit browsers */
      color: transparent;
    }
    input:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
      color: transparent;
    }
    input::-moz-placeholder { /* Mozilla Firefox 19+ */
      color: transparent;
    }
    input:-ms-input-placeholder { /* Internet Explorer 10+ */
      color: transparent;
    }
  }
}
