@import 'src/style/mixins';

.daterangepicker {
  background: var(--white) !important;
  min-width: var(--input-max-width) !important;

  .calendar {
    border-radius: var(--default-border-radius);
    margin: 1rem !important;

    &.right {
      margin-left: 0 !important;
    }
  }

  &_end_input,
  &_start_input {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;
    padding-left: 0 !important;
    width: 100%;
  }

  &_start_input {
    margin-top: 1rem;
  }

  &_end_input {
    margin-bottom: 1rem;
  }

  .input-mini {
    font-size: var(--font-size-xs);
    height: 2.2rem;
    margin-bottom: 0 !important;
    padding-bottom: 0.5rem;
    padding-top: 0.5rem;
    width: 100%;
  }

  td {
    &.active {
      background: var(--primary-color) !important;
      color: var(--white) !important;
    }
  }

  .ranges {
    margin-bottom: 1rem !important;
    margin-left: 1rem !important;
    margin-top: 1rem !important;

    li {
      align-items: center;
      border-radius: var(--default-border-radius);
      color: var(--primary-color) !important;
      display: flex;
      font-size: var(--font-size-xs) !important;
      font-weight: var(--font-weight-semibold);
      height: var(--element-side-gap);
      transition: var(--default-transition);

      &:hover,
      &.active {
        background: var(--primary-color) !important;
        color: var(--white) !important;
      }
    }
  }

  .btn {
    width: 100%;

    & + .btn {
      margin-left: 0 !important;
    }

    &-success {
      background: var(--primary-color);
    }
  }
}
