@import '~@nextbank/ui-components/dist/variables';

.item {
  border: 1px solid transparent;
  border-radius: $borderRadius;
  box-sizing: border-box;
  padding-bottom: var(--xsmall-gap);
  padding-top: var(--xsmall-gap);
  transition: background var(--default-transition), border-color var(--default-transition);
}

.isDragging {
  background: $white;
  border-color: $lineColor;
}

.handle {
  align-items: center;
  display: flex;
  justify-content: center;
  min-width: var(--large-gap);
}

.row {
  align-items: center;
  display: flex;
}
