#customer-loans-cash-payment {
  .well {
    margin-top: 20px;
  }

  .bold {
    font-weight: var(--font-weight-semibold);
  }

  .buttons {
    float: right;
  }
}