credit-scoring-modal-model-confusion-matrix {
  width: 100%;

  .model-confusion-container {
    display: grid;
    grid-template-areas: ". top top"
                          "ac tp fn"
                          "ac fp tn";

    grid-template-columns: 1fr 2fr 2fr;
    grid-template-rows: 1fr 2fr 2fr;
    grid-column-gap: var(--box-padding);
    grid-row-gap: var(--box-padding);
    text-align: center;

    & > div {
      border-radius: var(--default-border-radius);
      display: block;
      padding: var(--box-padding);
    }
  }

  .center {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
  }

  .prediction-value {
    font-size: 2.5rem;
  }

  .tile-description-top {
    grid-area: top;
  }

  .tile-description-left {
    grid-area: ac;
  }

  .tile-top-left {
    grid-area: tp;
  }

  .tile-top-right {
    grid-area: fn;
  }

  .tile-bottom-left {
    grid-area: fp;
  }

  .tile-bottom-right {
    grid-area: tn;
  }

  .background-positive {
    background: var(--good-background-color) !important;
  }

  .background-negative {
    background: var(--bloody-background-color) !important;
  }

  .background-description {
    background: var(--body-color) !important;
    text-align: center;
  }
}
