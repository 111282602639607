customer-credit-line-create {
  table {

    select, input {
      margin-bottom: 0;
    }

    td.values {
      display: flex;
    }
  }

  .buttons {
    float: right;
    margin-right: 40px;
  }
}
