.sub-header-tab {
  background-color: transparent
}

.sub-header__column-left {
  box-sizing: border-box;
  width: 50%;
  float: left;
}

.sub-header__column-right {
  box-sizing: border-box;
  width: 50%;
  float: left;
}

.sub-header__create-button {
  box-sizing: border-box;
  min-width: 100px;
}
