create-cashiers-check {
  .selectize-control {
    width: 400px;
  }

  .create-cashiers-check__units-table {
    table-layout: fixed;

    td, th {
      &:first-child {
        width: 30px;
      }

      &:nth-child(2) {
        width: 20%;
      }

      &:nth-child(3) {
        width: 40%;
      }

      &:last-child {
        width: 100px;
      }
    }

    glue-container {
      .add-on {
        box-sizing: border-box;
        height: 30px;
      }
    }
  }

  input[type="text"].add-account__remarks-input {
    margin: 0;
  }
}