loan-pre-terminate-by-gl {
  .pre-terminate {
    .pre-terminate-by-gl {
      &__additional-info {
        tr > td:first-child {
          padding: 8px 9px;
        }
      }
    }
  }
}