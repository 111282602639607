.misc-transaction__error-title {
  color: var(--danger-color);
}

.miscellaneous-transactions__table {
  box-sizing: border-box;

  td,th {
    &:first-child {
      width: 30px;
    }

    &:last-child {
      width: 80px;
    }
  }
}

.miscellaneous-transactions__open-button {
  width: 80px;
}

.misc-transaction-listing__table {
  box-sizing: border-box;
  width: 100%;

  td,th {
    &:first-child {
      box-sizing: border-box;
      width: 40px;
    }
    &:nth-child(2) {
      width: 50%;
    }
    &:last-child {
      padding-right: 2%;
      text-align: right;
    }
  }

  .centered {
    text-align: center;
  }

  .error {
    color: indianred;
  }
}

.misc-transaction__table {
  box-sizing: border-box;
  width: 100%;

  .validation-container {
    input {
      margin-bottom: 10px;
    }
  }
  .readonly-list {
    margin-bottom: 0;
  }

  &-checkbox {
    display: flex;
    align-items: center;
    padding-left: 15px;
    margin-top: 5px;

    input {
      vertical-align: bottom;
      position: relative;
      margin: 0;
      width: auto;
    }
  }
}

.misc-transaction__accounts-table {
  table-layout: fixed;

  td, th {
    &:first-child {
      width: 30px;
    }

    &:nth-child(2) {
      width: 55%;
    }

    &:last-child {
      width: 100px;
    }
  }

  glue-container {
    .add-on {
      box-sizing: border-box;
      height: 30px;
    }
  }

  &.table .add-account {
    td {
      padding-top: 30px;
    }
  }

  .add-account__remarks-input {
    width: ~"calc(100% - 100px)";
    margin-left: 35px;
  }

  .add-account__remarks-total {
    float: right;
    line-height: 30px;
  }

	.account-options {
		width: 98%;
	}

  .selectize-control.single {
    max-width: none;
  }

  .selectize-control {
    height: 30px;
  }
}
