selectable-list .selectable-list__header {
  color: var(--text-color-light);
  display: flex;
  font-weight: var(--font-weight-semibold);
  justify-content: space-between;
}
selectable-list .selectable-list__header input {
  margin: 0;
  margin-right: auto;
}
selectable-list table.table tr.checked > td {
  background-color: #dff0d8;
}
selectable-list table.table td.right {
  text-align: right;
}
selectable-list table.table td.center {
  text-align: center;
}
selectable-list table.table td.left {
  text-align: left;
}
selectable-list table.table td.text-center {
  text-align: center;
}
selectable-list div[ng-table-pagination] {
  text-align: right;
}
