#common-product-details() {
  form {
    margin: 0;
  }

  .form-section {
    box-sizing: border-box;
    width: 500px;
    float: left;
    padding: 0 40px 0 20px;
  }

  select.charge-type {
    margin-bottom: 0;
  }

  .buttons {
    float: right;
    margin: 20px;
  }
}

#loan-product-details {
  #common-product-details();
  #loan-account-information-defaults {
    table{
      margin-left: 30px;
      background-color: transparent;
      td.label {
        background-color: transparent;
      }
      td.values {
        background-color: transparent;
      }
    }
  }
}
// dragula styles needs to outside of the loan-product id scope

.dragula-loans-container {
  margin-left: 30%;
}

li.dragula-loans {
  border: 2px solid var(--light-line-color);
  border-radius: var(--default-border-radius);
  box-sizing: border-box;
  font-weight: var(--font-weight-semibold);
  padding: 1rem;
  margin-bottom: 0.8rem;
  margin-top: 0.8rem;
  max-width: var(--input-max-width);
  background: white;
  list-style-type: none;
  cursor: grab;
  transition: var(--default-transition);

  &.gu-transit {
    background: var(--hover-background-color);
    cursor: grabbing;
    opacity: 1;
  }
}

li.dragula-loans.ex-moved {
  // nothing
}
li.dragula-loans.ex-over {
  opacity: 0.5 !important;
}

loan-product-amortization-form {
  td {

    & > select {
      width: 130px;
      margin: auto;
    }

    .full-width {
      width: 100%;
    }
  }

  .first-column {
    width: 240px;
    padding-right: 12px;
    text-align: right;
  }
}
