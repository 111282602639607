#edit-loan-amortization {
  .input-cell {
    display: flex;
    align-items: center;
    justify-content: left;

    .out-of {
      margin: 0 6px;
    }
  }

  .allow-overflow {
    overflow: visible;
  }

  cash {
    min-width: max-content;
  }

  .amortization-table-container {
    width: inherit;
    overflow: auto;
    background-color: white;
    z-index: 1;
    position: relative;
    white-space: nowrap;
    padding-bottom: 50px;
  }

  .fixed-col {
    position: sticky;
    z-index: 2;
    background-color: white;
  }

  .line-number {
    width: 50px;
    min-width: 50px;
    max-width: 50px;
    left: 0;
  }

  .due-date {
    width: 100px;
    min-width: 100px;
    max-width: 100px;
    left: 50px;
  }

  .total {
    width: 150px;
    min-width: 150px;
    max-width: 150px;
    right: 0;
  }

  .custom-fee {
    left: 0;
    width: auto;
    height: auto;
    position: sticky;
    z-index: 3;
  }
  .amortization-custom-fees-wrapper {
    display: inline-block;
    position: sticky;
    left: 0;
    box-shadow: none;
  }
}
