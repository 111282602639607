@import '~@nextbank/bootstrap/less/variables';

general-health-check {
  .hc-item {
    &-wrapper {
      &-header {
        box-sizing: border-box;
        width: 100%;
        margin-top: 1rem;

        div {
          margin-bottom: 1rem;
        }
      }

      &-body {
        margin-bottom: 1rem;
        margin-top: 1rem;
      }

      &-check {
        background-color: var(--white);
        border-bottom: 1px solid var(--light-line-color);
        margin-bottom: 3rem;
        padding-bottom: 3rem;

        &:last-child {
          border-bottom: none;
          padding-bottom: 0;
        }
      }
    }
  }
}