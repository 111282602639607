@import '~@nextbank/ui-components/dist/variables';
@import '~@nextbank/ui-components/dist/mixins';


.container {
  border: 2px solid transparent;
  border-radius: $borderRadius;
  box-sizing: border-box;
  max-width: 90rem;
  min-height: $basicElementHeightMinusInputBorders;
  position: relative;

  &_error {
    border-color: $errorColor;
  }

  &_disabled {
    background: none !important;
    border-color: $switchBgColor;
  }
}

.error {
  @include font-size-s;
  color: $errorColor;
}