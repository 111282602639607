single-select-and-number-condition {
  display: block;
  white-space: nowrap;
}
single-select-and-number-condition .condition {
  display: flex;
  align-items: center;
  width: fit-content;
}
single-select-and-number-condition .condition glue-container {
  margin-left: 10px;
}
single-select-and-number-condition .condition glue-container input {
  max-width: 100px;
}
single-select-and-number-condition .condition-container {
  display: flex;
  flex-direction: column;
}
single-select-and-number-condition .condition-container input {
  margin-top: 5px !important;
}
