@import 'src/style/mixins';

.inspire-tree {
  font-size: var(--font-size-s) !important;
  font-weight: var(--font-weight-semibold) !important;

  .title {
    height: calc(var(--small-button-height) + 1px);
    line-height: var(--small-button-height);
  }

  .editable form {
    height: var(--small-button-height);
    line-height: var(--small-button-height);
    padding-top: 0;

    & input, & .btn-group {
      height: var(--small-button-height);
      line-height: var(--small-button-height);
    }
  }
}

.inspire-tree .wholerow:hover,
.inspire-tree .title-wrap:hover + .wholerow {
  background: var(--hover-background-color);
}

.inspire-tree .selected > .wholerow,
.inspire-tree .selected > .wholerow:hover,
.inspire-tree .selected > .title-wrap:hover + .wholerow {
  background: var(--hover-background-color)
}

.inspire-tree .matched > .wholerow,
.inspire-tree .matched > .wholerow:hover,
.inspire-tree .matched > .title-wrap:hover + .wholerow {
  background: var(--hover-background-color)
}