.main-view credit-scoring-modal-legend {
  @import './credit-scoring-modal.mixins.less';

  flex: 1 0 24rem;
  line-height: normal;
  border: none;

  .cs-row {
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding: var(--xsmall-gap) 0;
    margin-left: 0;
    .border();

    .cs-label {
      white-space: nowrap;
      padding-right: var(--small-gap);
      .label();

      *[class*='icon-'] {
        display: inline-block;
        padding-right: var(--small-gap);
        text-align: center;
        width: 1rem;
      }
    }

    .cs-value {
      border-radius: var(--default-border-radius);
      flex: 1 0 8rem;
      font-size: var(--font-size-xs);
      font-weight: bold;
      letter-spacing: 0.05em;
      padding: var(--xsmall-gap);
      text-transform: uppercase;
      text-align: center;
      max-width: 10rem;

      each(@cs-colors, {
        &.color-@{value} {
          color:  ~"var(--@{value}-color)" !important;
          background: ~"var(--@{value}-background-color)" !important;
        }
      });
    }
  }
}
