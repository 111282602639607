@import '~@nextbank/ui-components/dist/variables';

:root {
  --white: #{$white};
  --even-table-row-color: #f3f7fb;

  --text-color: #{$textColor};
  --text-color-light: #{$textColorLight};
  --disabled-text-color: #cecece;
  --disabled-button-background-color: #b1becf;

  --disabled-input-text-color: #{$textColorLight};

  --body-color: #e8eff7;
  --primary-color: #{$primaryColor};
  --hover-background-color: #e5ecff;
  --notification-background-color: #fafbfd;

  --bloody-color: #F41F1F;
  --bloody-background-color: #FDDDDD;
  --danger-color: #ff633f;
  --danger-background-color: #ffe8e2;
  --warning-color: #ff9a33;
  --warning-background-color: #FEEEE1;
  --success-color: #21afc0;
  --success-background-color: #def3f6;
  --good-color: #5BC0DE;
  --good-background-color: #EAF7FB;

  --box-shadow-color: #dfdfdf;

  --light-line-color: #e8eff7;
  --input-background-color: #eef4fa;
  --menu-background-color: #fff;

  --font-family: #{$mainFontFamily};
  --font-size-xl: #{map-get($fontSizes, xl)};
  --font-size-l: #{map-get($fontSizes, l)};
  --font-size-m: #{map-get($fontSizes, m)};
  --font-size-s: #{map-get($fontSizes, s)};
  --font-size-xs: #{map-get($fontSizes, xs)};

  --font-weight-normal: normal;
  --font-weight-semibold: 500;
  --font-weight-bold: bold;

  --menu-width: 20rem;
  --menu-row-height: 3rem;
  --menu-side-padding: 1.3rem;
  --input-height: 3.8rem;
  --input-max-width: 50rem;
  --input-width-l: 20rem;
  --input-width-m: 16rem;
  --input-width-s: 12rem;
  --big-button-height: 3.6rem;
  --small-button-height: 2.6rem;
  --element-side-gap: 1.5rem;

  --top-bar-height: 7rem;

  --box-padding: 2rem;
  --large-gap: 3rem;
  --small-gap: 1rem;
  --xsmall-gap: 0.5rem;

  --default-transition: 0.18s ease;
  --default-border-radius: 0.4rem;

  --z-index-negative: -1;
  --z-index-standard: 1;
  --z-index-prioritized: 2;
  --z-index-top-bar: 3;
  --z-index-dropdowns: 1000;
  --z-index-popup: 999999;
  --z-index-always-on-top: 1000000;
}
