modal-print-preview {

  input.ng-invalid:disabled {
    border: 2px solid var(--danger-color);
  }

  .modal {
    //override bootstrap .modal position:fixed to position:absolute
    //after that modal view scrolls with whole page and reaching off screen
    //buttons is possible
    position: absolute;
    left: 0;
    right: 0;
    width: 80%;
    margin: 0 auto;
  }

  .modal-body {
    max-height: 80%;
  }

  #pdf-wrapper {
    position: relative;
    height: 0;
    width: 100%;
    padding-bottom: 56%;
    overflow: hidden;
  }

  #pdf-wrapper iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  #autoprint {
    display: none;
  }

  .print-filters {
    padding: 10px 0;

    &-section {
      margin: 10px 0;
    }

    &-value {
      font-size: var(--font-size-l);
      background: #fff;
    }
  }

}
