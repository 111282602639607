@import 'src/style/mixins';

.customer-header {
  align-items: flex-start;
  font-size: var(--font-size-s);
  margin-top: 2rem;

  &__section {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    font-weight: var(--font-weight-semibold);
    padding-left: var(--element-side-gap);
    padding-right: var(--element-side-gap);

    .title {
      color: var(--text-color-light);
      font-size: var(--font-size-xs);
      margin-bottom: 0.4rem;
    }
  }
}

.fake-picture {
  background: url(icons/icon-profile.svg) no-repeat center;
  height: 4rem;
  background-size: 4rem 4rem;
  width: 4rem;
}