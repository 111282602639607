.customer-header {
  display: flex;

  .profile-image {
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      max-width: 60px;
      max-height: 60px;

      &:hover {
        opacity: 0.8;
      }
    }

    &.fake-picture {
      &.image-exists {
        position: relative;
        cursor: pointer;
        filter: opacity(0.3) drop-shadow(0 0 0 var(--success-color));
        transition: 0.2s ease-in-out;

        &:hover {
          filter: opacity(0.4) drop-shadow(0 0 0 var(--success-color));
        }
      }
    }
  }

  &__section {
    &--bigger {
      flex: 2;
    }

    &--smaller {
      flex: 1;
    }

    &__select {
      margin: 10px;
    }

    .value {
      &.trim {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
}
