gl-transaction-details {
  .transaction-details-table {

    td {
      padding-bottom: 0.8rem;
      padding-top: 0.8rem;
    }

    td.credit-amount {
      color: var(--success-color);
    }

    td.debit-amount {
      color: var(--danger-color);
    }

    td.account-code {
      display: flex;
      justify-content: space-between;
    }
  }
}