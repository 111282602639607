@media print {
  customer-profile-body {
    display: block;
  }
  customer-profile-body textarea,
  customer-profile-body select,
  customer-profile-body input[type="text"],
  customer-profile-body input[type="password"],
  customer-profile-body input[type="datetime"],
  customer-profile-body input[type="datetime-local"],
  customer-profile-body input[type="date"],
  customer-profile-body input[type="month"],
  customer-profile-body input[type="time"],
  customer-profile-body input[type="week"],
  customer-profile-body input[type="number"],
  customer-profile-body input[type="email"],
  customer-profile-body input[type="url"],
  customer-profile-body input[type="search"],
  customer-profile-body input[type="tel"],
  customer-profile-body input[type="color"] {
    border: none;
  }
  customer-profile-body .profile-body .tab-content {
    border-bottom: none;
  }
  customer-profile-body span.add-on.glue-appended {
    border: none;
    background: transparent;
  }
  customer-profile-body input[type="date"].nx-empty {
    color: transparent !important;
  }
  customer-profile-body input::-webkit-input-placeholder {
    /* WebKit browsers */
    color: transparent;
  }
  customer-profile-body input:-moz-placeholder {
    /* Mozilla Firefox 4 to 18 */
    color: transparent;
  }
  customer-profile-body input::-moz-placeholder {
    /* Mozilla Firefox 19+ */
    color: transparent;
  }
  customer-profile-body input:-ms-input-placeholder {
    /* Internet Explorer 10+ */
    color: transparent;
  }
}
