role-write-access-details input,
role-write-access-details select {
  margin-bottom: 0;
}
role-write-access-details .action-config td:first-child {
  width: 20%;
}
role-write-access-details .action-config td:last-child {
  width: 80%;
}
role-write-access-details .predicates,
role-write-access-details .predicates-group {
  display: flex;
}
role-write-access-details .predicates-row {
  transition: all 0.3s ease-in-out;
}
