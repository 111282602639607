.print-config-row {
  align-items: center;
  display: flex;
}
.print-config-row .btn-group {
  margin-bottom: 0;
  margin-right: 1rem;
}
.table tbody tr td.status-REVERTED,
.table tbody tr td.status-REJECTED,
.table tbody tr td.status-ERROR {
  color: var(--danger-color);
}
.table tbody tr td.status-PROCESSED {
  color: var(--success-color);
}
.table tbody tr td.status-PENDING {
  color: orange;
}
