@import 'src/style/mixins';

.box {
  @include box-shadow;
  background: var(--white);
  border-radius: var(--default-border-radius);
  box-sizing: border-box;
  flex: 1;
  margin-left: var(--small-gap);
  margin-right: var(--small-gap);
  margin-top: var(--small-gap);
  padding: var(--box-padding);

  .alert {
    box-shadow: none;
    margin-bottom: 4rem;
    margin-left: 0;
    margin-right: 0;
    margin-top: 0;
  }
}

.footer {
  padding-left: var(--small-gap);
  padding-right: var(--small-gap);
}

footer {
  .action-button {
    margin-left: var(--element-side-gap);
  }
}

.loader {
  @include box-shadow;
  border-radius: var(--default-border-radius);
  padding: 5rem;
  font-size: var(--font-size-s);
  font-weight: var(--font-weight-semibold);
}

.selectable-list__header {
  align-items: center;
  display: flex;
  font-size: var(--font-size-s);
  margin-bottom: 2rem;
  margin-top: 2rem;

  label,
  span {
    align-items: center;
    display: inline-flex;
  }

  label {
    margin-right: 1rem;
  }
}

.info {
  align-items: center;
  border: 2px solid var(--light-line-color);
  border-radius: var(--default-border-radius);
  display: flex;
  font-size: var(--font-size-m);
  font-weight: var(--font-weight-semibold);
  margin-bottom: 4rem;
  padding-bottom: var(--small-gap);
  padding-left: var(--box-padding);
  padding-right: var(--box-padding);
  padding-top: var(--small-gap);

  &.box {
    padding: var(--box-padding);
    margin-bottom: 0;
  }

  &--deposit {
    justify-content: flex-start;
  }

  &__column {
    display: flex;

    & + .info__column,
    & + .btn {
      margin-left: auto;
    }
  }

  .info-detail,
  &__field {
    display: flex;
    flex-direction: column;
    font-size: var(--font-size-s);
    margin-bottom: 0;
    margin-right: 10rem;
  }

  .key,
  &__field-label {
    color: var(--text-color-light);
    font-size: var(--font-size-xs);
    margin-bottom: 0.4rem;
  }
}

.currency {
  &--positive {
    color: var(--success-color);
  }

  &--negative {
    color: var(--danger-color);
  }
}

.empty-message {
  align-items: center;
  color: var(--text-color-light);
  display: flex;
  font-size: var(--font-size-s);
  font-weight: var(--font-weight-semibold);
  justify-content: center;
  margin-bottom: 6rem;
  margin-top: 6rem;
}

.charge {
  select {
    margin-bottom: 1rem;
  }
}

.product-info-section {
  font-size: var(--font-size-m);
  margin-top: 2rem;
  margin-bottom: 2rem;
  margin-left: var(--small-gap);

  .product-attribute {
    margin-bottom: 1rem;
  }
}

.status {
  &--warning,
  &--success,
  &--danger {
    align-items: center;
    border-radius: var(--default-border-radius);
    box-sizing: border-box;
    display: inline-flex;
    font-size: var(--font-size-s);
    font-weight: var(--font-weight-bold);
    height: var(--small-button-height);
    justify-content: center;
    min-width: 8.5rem;
    padding-left: 1.1rem;
    padding-right: 1.1rem;
    text-transform: capitalize;
  }

  &--warning {
    background: var(--warning-background-color);
    color: var(--warning-color);
  }

  &--success {
    background: var(--success-background-color);
    color: var(--success-color);
  }

  &--danger {
    background: var(--danger-background-color);
    color: var(--danger-color);
  }
}

.pagination {
  align-items: center;
  display: flex;
  font-weight: var(--font-weight-bold);
  margin-top: 4rem !important;
}

.ng-table-counts {
  display: flex !important;
  float: none !important;
  justify-content: flex-start !important;
  margin-top: 2rem;
  padding-right: var(--menu-side-padding);

  &.btn-group {
    .btn {
      border: 0;
      font-size: var(--font-size-s) !important;
      font-weight: var(--font-weight-bold) !important;
      color: var(--text-color-light);
      line-height: var(--font-size-s);
      min-height: auto !important;
      margin-right: 2rem !important;
      min-width: 0 !important;
      padding: 0 !important;
      transition: var(--default-transition);
    }

    .active {
      background: none !important;
      color: var(--primary-color);
    }
  }
}

.info-detail {
  font-size: var(--font-size-s);
  margin-bottom: 1rem;

  .key {
    color: var(--text-color-light);
    margin-right: 0.8rem;
  }
}

customer-profile-header {
  margin-bottom: 1rem;
}

.btn {
  & + refresh-button {
    display: flex;
    margin-left: var(--element-side-gap);
  }
}

.switch {
  display: flex;
  align-items: center;
  margin-right: var(--element-side-gap);
  margin-top: 0.2rem;

  label {
    margin-bottom: 0;
    margin-left: 0.5rem;
    user-select: none;
  }
}

.customer-option {
  padding-bottom: 1.2rem !important;
  padding-top: 1.2rem !important;

  &:first-child {
    width: 2%;
  }

  &:nth-child(2) {
    width: 18%;
  }

  &:nth-child(3) {
    width: 80%;
  }

  .btn {
    min-height: var(--input-height) !important;
  }
}