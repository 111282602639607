ledger-units .ledger-units-table {
  table-layout: fixed;
}
ledger-units .ledger-units-table td:first-child,
ledger-units .ledger-units-table th:first-child {
  width: 30px;
}
ledger-units .ledger-units-table td:nth-child(2),
ledger-units .ledger-units-table th:nth-child(2) {
  width: 55%;
}
ledger-units .ledger-units-table td:last-child,
ledger-units .ledger-units-table th:last-child {
  width: 100px;
}
ledger-units .ledger-units-table glue-container .add-on {
  box-sizing: border-box;
  height: 30px;
}
ledger-units .ledger-units-table .account-options {
  width: 98%;
}
ledger-units .ledger-units-table .selectize-control.single {
  max-width: none;
}
ledger-units .ledger-units-table .error {
  padding-top: 5px;
  color: var(--danger-color);
  float: right;
}
ledger-units .ledger-units-table .error-cell {
  color: var(--danger-color);
}
