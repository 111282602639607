loan-automatic-transfer table {
  margin-bottom: 0;
}
loan-automatic-transfer table select,
loan-automatic-transfer table input,
loan-automatic-transfer table textarea {
  margin-bottom: 0;
}
loan-automatic-transfer table td.label {
  width: 280px;
}
loan-automatic-transfer table td.values {
  display: flex;
  align-items: center;
  max-width: var(--input-max-width);
}
loan-automatic-transfer table .selectize-control.single {
  max-width: none;
  min-width: 300px;
}
