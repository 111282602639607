// disable the scrollbar on windows (the content is still scrollable via mouse wheel)
.sidenav::-webkit-scrollbar {
  display: none;
}

.sidenav {
  #customer_close_icon {
    cursor: pointer;
  }

  #customer_refresh_icon {
    margin-left: auto;
    cursor: pointer;
  }

  .invalid-profile {
    color: var(--danger-color);
    float: right;
    margin-left: auto;
    padding-right: 0.6rem;
  }

  .sidenav-actions-button {
    position: relative;
  }

  .sidenav-actions-counter {
    margin-left: 10px;
  }
}
