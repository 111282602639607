number-pattern {
  @import '~@nextbank/bootstrap/less/variables';
  display: block;
  padding: 5px;

  &.ng-invalid.ng-invalid-required {
    border: 2px solid var(--danger-color);
  }

  &.ng-invalid.ng-invalid-parse,
  &.ng-invalid.ng-invalid-length {
    .number-pattern__input {
      border: 2px solid var(--danger-color);
      outline: none;
    }
  }

  .number-pattern__input-label {
    margin: 0;

    padding-bottom: 10px;
  }

  .number-pattern__input-error {
    padding-bottom: 10px;
  }

  .number-pattern__input {
    margin: 0 !important;
  }

  .number-pattern__manual {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    margin: -5px;

    & > * {
      margin: 5px;
      flex-grow: 1;
    }
  }

  .number-pattern__item-button {
    align-items: center;
    background: none;
    border: 1px solid @grayLight;
    border-radius: var(--default-border-radius);
    display: flex;
    text-align: left;
    min-width: 100%;

    &[disabled] {
      border: 1px solid @grayLight !important;
      cursor: not-allowed;
      opacity: 0.5;
    }

    & > * {
      margin: 5px;
    }

    &:focus-within {
      border: 1px solid @grayDark;
      box-shadow: 0 0 2px @grayDark;
      outline: none;
    }
  }
}