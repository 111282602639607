prints-details-template-upload {
  .wrapper {
    display: flex;
    flex-direction: column;
    margin: -15px 0;

    & > * {
      margin: 15px 0;
    }
  }

  .inline-editor {
    box-sizing: border-box;
    height: 600px;
    margin: 0;
    overflow: auto;
    padding: 10px;
    width: 100%;
  }
}